import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Tag from '../Tag'
import Modal from '../Modal'
import GridItem from '../GridItem'
import Flexbox from '../Flexbox'
import API from '../../API'
import InputField from '../InputField'
import Button from '../Button'
import Icon from '../Icon'
import Toggle from '../Toggle'
import GridContainer from '../GridContainer'
import TiledInput from '../TiledInput'


import { formatPrice, formatPriceInputField, formatPhoneNumber, normalizeString, findMatchAcrossKeys, toTitleCase } from '../../utils'
import { default as statesArr } from '../../utils/states'

import Alert from '../../images/alert.svg'
import { useNavigate } from 'react-router-dom'
import AddLawyerModal from '../AddLawyerModal'
import Dropdown from '../Dropdown2'
import RadixDropdown from '../RadixDropdown'
import { Dropdown as ReactPrimeDropdown } from 'primereact/dropdown';

const P = styled.p`
font-size: 12px;
margin: 0;
`;

const MAX_DEC_PLACES = 2
// 
export const AddAttorneyModal = ({ isAdminAdd = true, hideLabel = false, lead, price, setShowAddAttModal, getPotentialMatches, newAttorney, setNewAttorneyField, setData }) => {
    // const [isAdminAdd, setIsAdminAdd] = useState(true)

    function setNewAttorneyAsSelected(fieldKey, update) {
        setData(prev => ({
            ...prev,
            selectedAttorney: {
                ...prev.selectedAttorney,

            }
        }))
    }

    return (<GridContainer background="#f0f0f0" padding="5px" width="100%" maxWidth="600px" margin="0 auto" itemsPerRow="3" gap="5px" gridTemp={{ rows: 'repeat(4,min-content)', columns: '1fr min-content min-content' }}>
        <h3 className="textLeft noMargin">{hideLabel ? "" : "Add Match"}</h3>
        <Button
            text="Cancel"
            height="50px"
            borderRadius="5px"
            padding="2px 10px"
            onClick={() => {
                setShowAddAttModal(false)
            }} />
        <Button
            text="Add"
            height="50px"
            borderRadius="5px"
            background="#009e9b"
            padding="2px 10px"
            onClick={async () => {
                await API.addGeoRegion({
                    ...newAttorney,
                    returnAttorneys: true,
                    attorneyFilters: {
                        isActive: isAdminAdd ? false : true,
                        isAdminAdd,
                        sort: "-budget",
                        "budget": { "$gt": 0 }
                    }
                })
                await getPotentialMatches()

                setShowAddAttModal(false)
            }} />
        <GridItem justify="start" display="flex" flexDirection="column" align="start" col="1 / -1" row="2">
            <AddAttorneyDropdown setNewAttorneyField={setNewAttorneyField} />
        </GridItem>
        <GridItem justify="start" display="flex" flexDirection="column" align="start" col="1" row="3">
            <RegionDropdown lead={lead} setNewAttorneyField={setNewAttorneyField} />
        </GridItem>
        <GridItem justify="start" display="flex" flexDirection="column" align="start" col="2 / span 2" row="3">
            <RegionPrice price={price} setNewAttorneyField={setNewAttorneyField} />
        </GridItem>
        <GridItem display="flex" flexDirection="column" align="start" border="1px solid #000" padding="1em" col="1 / span 3">
            <p style={{ margin: '0' }}>Temporarily Add Geo Region</p>
            <p style={{ fontSize: '13px', margin: '0' }}>This geo region will remain in the database, but it'll be marked as an admin addition to help differentiate it (you can find it in the db by filtering isAdminAdd: true). In addition, it'll be immediately marked as inactive (isActive: false), to prevent auto sending.<br /><br />If you want a new record that will continue to match automatically, the toggle should be off and the price should be greater than zero.</p>
            <Toggle
                defaultValue={isAdminAdd}
                _checked={isAdminAdd}
                _setChecked={(v) => {
                    setNewAttorneyField({ isAdminAdd: v })
                }}
                options={['Temp Add']}
                label='Temp Add' /></GridItem>
        {/* <GridItem justify="start" display="flex" flexDirection="column" align="start" col="3">
    <Toggle label="Save Region" showLabelText={true} />
</GridItem> */}
    </GridContainer>)
}
const LeadsModal = ({ lead, header, ...props }) => {
    const [showAddAttModal, setShowAddAttModal] = useState(false)
    const [newAttorney, setNewAttorney] = useState({
        regionType: '',
        regionValue: '',
        regionName: '',
        attorneyId: null,
        price: '',
        practiceAreaId: lead['practiceAreaId']
    })
    console.log("new attorney", newAttorney)
    const { price, regionType, regionName, attorneyId } = newAttorney
    const setNewAttorneyField = (fieldValueObject) => {
        if (typeof (fieldValueObject) === 'object' && fieldValueObject !== null)
            setNewAttorney(prev => ({
                ...prev,
                ...fieldValueObject
            }))
    }
    const { _id: leadId } = lead
    const getPotentialMatches = async () => {
        const res = await API.getPotentialMatches(leadId)
        const { attorneys, formattedLead } = res
        setLeadMod(prev => ({
            ...prev,
            matches: attorneys || [],
            initialLead: formattedLead,
            modifiedLead: formattedLead,
        }))

    }
    useEffect(() => {

        if (leadId) {
            getPotentialMatches()
        }
    }, [leadId])
    const navigate = useNavigate()
    const [leadMod, setLeadMod] = useState({
        initialLead: lead,
        modifiedLead: lead,
        matches: []
    })
    const { initialLead, initialLead: { city: currentCity, unidentifiedPlaces }, modifiedLead, modifiedLead: { city: newCity }, matches } = leadMod

    const submitChanges = async () => {
        try {
            const res = await API.updateClient({ id: lead._id, city: newCity.name, ste_name: newCity.ste_name })

        } catch (error) {
            console.log("error is", error)
        }
    }

    const validatorAddItem = (trimmedInput, activeSelections) => {

        if (!activeSelections.find(it => it.id === trimmedInput)) {
            //setCounties
            return true
        }
        return false
    }
    const stateIsSelected = (stateName) => {
        return currentCity.state.name === stateName
    }
    const isSelected = (val) => {
        if (typeof (val) === 'string')
            return false
        return currentCity.id === newCity.id && currentCity['state'].id === newCity['state'].id
    }
    const setItems = (item) => {
        if (!isSelected(item))
            setLeadMod(prev => ({
                ...prev,
                modifiedLead: {
                    ...prev.modifiedLead,
                    city: item
                }
            }))
    }

    const findSearchMatch = (value) => {
        return statesArr.filter(state => {
            return (!stateIsSelected(state.name) && findMatchAcrossKeys(state, value))
        })
    }
    const getSuggestions = async (_input, setInput) => {
        let cityRecords = await API.searchPlaces(_input, [lead.state])
        cityRecords = cityRecords.reduce(
            (arr, item) => {

                const { fields, fields: { coty_name, pla_name, pla_code, geo_point_2d, ste_name, ste_code, coty_code } } = item

                if (isSelected(fields))
                    return arr

                if (!pla_name.includes(toTitleCase(_input.split(',')[0].trim())))
                    return arr

                const formattedItem = {
                    type: 'place', //place
                    innerText: `${pla_name}, ${ste_name}`,
                    value: `${pla_name}, ${ste_name}`,
                    pla_code,
                    name: pla_name,
                    coty_code,
                    coty_name,
                    county: coty_name,
                    state: ste_name,
                    ste_name,
                    ste_code,
                    id: pla_code,
                    regionCode: pla_code,
                    regionType: 'City',
                    key: pla_code,
                    geo_point_2d,
                    background: 'var(--tagBgdCity)'
                }

                arr.push({
                    ...formattedItem,
                    onClick: () => {
                        if (!isSelected(formattedItem)) {
                            // addState(state)
                            setItems(formattedItem)
                            setInput('')
                        }
                    },
                    key: item.id
                })
                return arr
            }
            , [])
        return cityRecords
    }
    console.log("leadMod", leadMod)
    const rowProps = { className: "hoverOff", background: "#fff", textAlign: 'left', justify: 'start', userSelect: 'all' }
    return (
        <Modal {...props} disableCloseOnClickBgd={true} maxWidth="1100px" mobileWidth="100%">
            {
                (
                    // (currentCity && !currentCity.isValid) || 
                    (unidentifiedPlaces && unidentifiedPlaces.length > 0)) &&
                <>
                    <Flexbox gap="5px">
                        <Icon src={Alert} height="30px" width="20px" />
                        <h3 className="textLeft noMargin" style={{ color: 'var(--red)' }}>Invalid Location</h3>
                    </Flexbox>
                    {currentCity && <P >Couldn't find a matching city for <strong>'{lead.city}'</strong> in state <strong>{lead.state}</strong>.</P>}

                    <GridContainer width="100%" highlightOff="true" fontSize="12px" align="center" alignContent="start" justify="start" justifyItems="start"
                        border="1px solid #8f8f8f" gridTemp={{ columns: '1fr 4fr' }} itemPadding="20px 10px">
                        <GridItem {...rowProps} color="#fff" background='var(--red)' border="1px solid var(--red)" >Form Entry</GridItem>
                        <GridItem {...rowProps} border="1px solid var(--red)" userSelect="none">
                            <Flexbox gap="5px">
                                <Tag background="var(--tagBgdCity)" hideButton={true}>{lead.city ? lead.city : unidentifiedPlaces && unidentifiedPlaces.length ? unidentifiedPlaces[0] : ''}</Tag>
                            </Flexbox>
                        </GridItem>
                    </GridContainer>
                    <h3 className="textLeft noMargin">Edit Location</h3>
                    {/* <TiledInput
                        // onFocus={onFocus}
                        width="100%"
                        maxWidth="calc(90vw - 200px)"
                        className="geographicArea"
                        multiTermEnabled={false}
                        validatorAddItem={validatorAddItem}
                        placeHolder={"County, State"}
                        items={
                            !newCity || newCity === '' ? [] : [{
                                ...newCity,
                                type: 'place',
                                innerText: newCity.name,
                                value: newCity.name,
                                background: 'var(--tagBgdCity)'
                            }]}
                        setItems={setItems}
                        tagOnClick={
                            function (_tag) {
                                // if (type === 'view-attorney-details')
                                //     return
                                setItems('')
                            }}
                        // validatorRemoveItem={validatorRemoveItem}
                        formatSelection={(input) => input}
                        getSuggestions={getSuggestions} /> */}
                </>
            }

            <h3 className="textLeft noMargin">Lead</h3>
            <GridContainer width="100%" highlightOff="true" fontSize="12px" align="center" alignContent="start" justify="start" justifyItems="start"
                border="1px solid #8f8f8f" gridTemp={{ columns: '1fr' }} itemPadding="20px 10px">
                <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >Name</GridItem>
                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" > {lead.leadName}</GridItem>
                <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >Email</GridItem>
                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" > {lead.email}</GridItem>
                <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >Phone Number</GridItem>
                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" >{lead.phoneNumber}</GridItem>
                <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >Date Received</GridItem>
                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" > {new Date(lead.createdAt).toLocaleDateString("en-US")}</GridItem>
            </GridContainer>
            <GridContainer width="100%" gridTemp={{ rows: '1fr', columns: '1fr 1fr' }} align="end" background="transparent">
                <h3 className="textLeft noMargin">Case Details</h3>
                <GridItem alighSelf="end" className="header" justify="end">
                    <Button borderRadius="5px" height="40px" text="Copy To JSON" width="200px" onClick={() => {
                        let clipBoard = ''
                        if (navigator && navigator.clipboard && initialLead['caseDetails']) {
                            console.log(initialLead['caseDetails'])
                            clipBoard = initialLead['caseDetails'].reduce((a, b, index) => {
                                if (index > 0)
                                    a = a + `\nQ: ${b['header']}\nA: ${b['value']}`
                                else
                                    a = `Q: ${b['header']}\nA: ${b['value']}`
                                return a
                            }, '')
                            navigator.clipboard.writeText(clipBoard);
                        }
                    }} padding="20px 0" /></GridItem>
            </GridContainer>
            <GridContainer width="100%" highlightOff="true" fontSize="12px" align="center" alignContent="start" justify="start" justifyItems="start"
                border="1px solid #8f8f8f" gridTemp={{ columns: '1fr' }} itemPadding="20px 10px">
                {/* <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >Additional Details</GridItem>
                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" > {lead.additionalDetails}</GridItem> */}
                <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >Location</GridItem>
                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" userSelect="none">
                    <Flexbox gap="5px">
                        {lead.city &&
                            <>
                                <Tag background="var(--tagBgdCity)" hideButton={true}>{lead.city}</Tag>
                                {/* {
                                    lead.city.counties.map(coty => {
                                        return (<Tag background="var(--tagBgdCounty)" hideButton={true}>{coty.name}</Tag>)
                                    })
                                } */}
                                <Tag background="var(--tagBgdState)" hideButton={true}>{lead.state}</Tag>
                            </>}
                    </Flexbox>
                </GridItem>
                {
                    initialLead['caseDetails'] &&
                    initialLead['caseDetails'].map(item => {
                        const { value, header } = item
                        return (
                            <>
                                <GridItem {...rowProps} fontWeight="700" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >{header}</GridItem>
                                <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" > {value}</GridItem>
                            </>
                        )

                    })
                }
                {/* {
                    Object.keys(header).map(headerKey => {
                        const isGroup = header[headerKey]['fields'] !== undefined
                        if (isGroup)
                            return (<>
                                {
                                    Object.keys(header[headerKey]).map(it2 => {
                                        return (<></>)
                                    })
                                }

                            </>)
                        else {
                            const value = typeof (lead[headerKey]) === 'object' ? lead[headerKey].value || '-' : lead[headerKey]
                            return (
                                <>
                                    <GridItem {...rowProps} background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" >{header[headerKey].title}</GridItem>
                                    <GridItem {...rowProps} border="1px solid var(--dropdownHoverBackground)" > {value}</GridItem>
                                </>
                            )
                        }
                    })
                } */}
            </GridContainer>
            <GridContainer width="100%" gridTemp={{ rows: '1fr', columns: '1fr 1fr' }} align="end" background="transparent">
                {/* {showAddAttModal && < AddLawyerModal setShowModal={setShowAddAttModal}  {...(lead, matches)} />} */}
                <h3 className="textLeft noMargin">Matches</h3>
                <GridItem alighSelf="end" className="header" justify="end">
                    <Button borderRadius="5px" height="20px" padding="5px 10px" text="Add" width="50px" onClick={() => {
                        setShowAddAttModal(true)
                    }} /></GridItem>
            </GridContainer>

            <GridContainer whiteSpace="nowrap" width="100%" fontSize="12px" align="center" alignContent="start" justify="start" justifyItems="start"
                border="1px solid #8f8f8f"
                gridTemp={{ columns: 'repeat(2,1fr) repeat(3,min-content)' }}
                gridTempMobile={{ columns: 'repeat(2,1fr) repeat(2,min-content)' }}
                itemPadding="20px 10px" className="isTable" itemsPerRow="5" itemsPerRowMobile="4" nthHighlight=""
            >
                <GridItem display="flex" flexDirection="column" align="start">
                    <span>
                        Firm Name
                    </span>
                    <span>
                        Member Since
                    </span>
                </GridItem>
                <GridItem justify="start" flexDirection="column" align="start" className="hideOnMobile">
                    <span>Email</span>
                    <span>
                        CRM
                    </span>
                </GridItem>
                <GridItem display="flex" flexDirection="column" align="start">
                    <span style={{ fontWeight: '700' }}>
                        Price
                    </span>
                    <span>
                        Budget
                    </span>
                </GridItem>
                <GridItem display="flex" flexDirection="column" align="start">
                    <span style={{ fontWeight: '700' }}>
                        Total Spent
                    </span>
                    <span>
                        # Leads
                    </span>
                </GridItem>
                <GridItem display="flex" flexDirection="column" align="start">
                    <span style={{ fontWeight: '700' }}>
                        Last Lead
                    </span>
                    <span>
                        Region
                    </span>
                </GridItem>
                {
                    leadMod.matches.map(attorney => {
                        const { price, contactMethods, mostRecentMatch, createdAt, name, email, firmName, budget, totalSpent, nbLeads, phoneNumber, regionName, regionType } = attorney
                        let onClick = () => {
                            navigate(`/reformat/${lead['_id']}`,
                                { state: { formattedLead: initialLead, lead, matches: leadMod.matches, attorney } })
                        }
                        return (
                            <>
                                <GridItem display="flex" flexDirection="column" align="start"
                                    onClick={onClick}>
                                    <span>
                                        {firmName}
                                    </span>
                                    <span>
                                        {new Date(createdAt).toLocaleDateString("en-US")}
                                    </span>
                                </GridItem>
                                <GridItem display="flex" flexDirection="column" align="start" className="hideOnMobile"
                                    onClick={onClick}>
                                    <span>
                                        {contactMethods['emailList'] && contactMethods['emailList'].reduce((a, b, index) => {
                                            if (index > 0)
                                                a = a + ', ' + b
                                            else
                                                a = b
                                            return a
                                        }, '')}
                                    </span>
                                    <span>{contactMethods['crm'] && contactMethods['crm'].reduce((a, b, index) => {
                                        if (index > 0)
                                            a = a + ', ' + b['name']
                                        else
                                            a = b['name']
                                        return a
                                    }, '')}</span>
                                </GridItem>
                                {/* <GridItem justify="start"> </GridItem> */}
                                <GridItem justify="start" display="flex" flexDirection="column" align="start"
                                    onClick={onClick}>
                                    <span style={{ fontWeight: '700' }}>
                                        {price ? formatPrice(price) : '-'}
                                    </span>
                                    <span>
                                        {formatPrice(budget)}
                                    </span></GridItem>
                                <GridItem justify="start" display="flex" flexDirection="column" align="start"
                                    onClick={onClick}>
                                    <span style={{ fontWeight: '700' }}>{totalSpent && formatPrice(totalSpent)}</span>
                                    <span>
                                        {nbLeads}</span>
                                </GridItem>
                                <GridItem justify="start" display="flex" flexDirection="column" align="start"
                                    onClick={onClick}>
                                    <span style={{ fontWeight: '700' }}>{mostRecentMatch ? new Date(mostRecentMatch).toLocaleDateString("en-US") : '-'}</span>
                                    <span style={{ background: `var(--tagBgd${regionType})` }}>{regionName}</span>
                                </GridItem>
                            </>
                        )
                    })
                }

            </GridContainer>
            {showAddAttModal &&
                <AddAttorneyModal setShowAddAttModal={setShowAddAttModal} getPotentialMatches={getPotentialMatches} newAttorney={newAttorney} setNewAttorneyField={setNewAttorneyField} lead={lead} price={price} />}

            <GridContainer gridTemp={{ rows: '1fr', columns: '1fr 1fr' }} width="100%" gap="20px">

                <Button text="Save" onClick={submitChanges} width="100%" padding="20px 0" />
                <Button text="Reformat Lead" onClick={() => navigate(`/reformat/${lead['_id']}`,
                    { state: { formattedLead: initialLead, lead, matches: leadMod.matches } })} width="100%" padding="20px 0" />
            </GridContainer>
        </Modal >
    )
}

export const RegionPrice = ({ price, setNewAttorneyField }) => {
    return (<InputField name="price"
        padding="0 0 0 10px"
        width="100%"
        border="none"
        background="rgba(0,0,0,0.2)"
        dollarSignPos="2px"
        focusOutline="none"
        focusBorder="1px solid #00ff84"
        value={price}
        onChange={(event) => {
            let value = event.target.value !== undefined ? event.target.value : event.target.attributes.value.value;

            let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let decimal; //MAX_DEC_PLACES
            if (value.split('.').length > 1) {
                decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
                price = price + '.' + decimal;
            }
            console.log("price", price)
            setNewAttorneyField({ price: price })
            // setAttorneyModified(prev => ({ ...prev, budget: price }))

        }} />)
}

export const RegionDropdown = ({ lead, setNewAttorneyField }) => {
    const [fieldObj, setFieldObj] = useState({
        value: 'Region',
        options: [
            ...(lead.city ? [{ innerText: lead['city'], value: lead['city'], label: lead['city'], id: lead['cityId'], regionType: 'City' }] : []),
            { innerText: lead['state'], value: lead['state'], label: lead['state'], id: lead['stateId'], regionType: 'State' },
            ...(lead.counties ? lead.counties?.map(it => {
                let countyName = `${it['name']} County`
                return ({
                    innerText: countyName,
                    value: countyName,
                    label: countyName,
                    id: it['countyId'],
                    regionType: 'County'
                })
            }) : [])],
        showDropdown: false
    })
    const { showDropdown, options } = fieldObj
    const setShowDropdown = () => {
        setFieldObj(prev => ({
            ...prev,
            showDropdown: !prev.showDropdown
        }))
    }
    return (
        <ReactPrimeDropdown
            options={options}
            optionLabel="innerText"
            placeholder="Region"
            value={fieldObj.value}
            key={"regionDropdown"}
            id="regionDropdown"
            name="regionDropdown"
            onChange={(e) => {
                console.log("value is:", e.value)
                setFieldObj(prev => ({
                    ...prev,
                    value: e['value']
                }))
                let option = options.find(item => item.value === e.value)
                console.log("option", option)
                setNewAttorneyField({
                    regionType: option['regionType'],
                    regionId: option['id'],
                    regionValue: option['value'],
                    regionName: option['value'],
                })
            }}

        />)
    return (
        <RadixDropdown
            options={options}
            valueIsSelectedKey={"value"}
            value={fieldObj.value}
            color="#000"
            width="fit-content"
            stroke="var(--formBlue1)"
            labelFontSize=".8rem"
            key={"regionDropdown"}
            id="regionDropdown"
            name="regionDropdown"
            onValueChange={async (optionObj) => {

                setFieldObj(prev => ({
                    ...prev,
                    value: optionObj['value']
                }))
                setNewAttorneyField({
                    regionType: optionObj['regionType'],
                    regionId: optionObj['id'],
                    regionValue: optionObj['value'],
                    regionName: optionObj['value'],
                })
            }}
        />)
    return (
        <Dropdown
            dropdownRef={null}
            listContainerRef={null}
            headerFontSize="12px"
            headerHeight="49px"
            itemHeight="49px"
            // maxHeight={4 * 49 + 'px'}
            dropdownListMarginTop={"10px"}
            // {...fieldObj}
            showHeader={true}
            showArrow={true}
            name={'Suggested Tags'}
            marginBottom={'1rem'}
            border="none"
            headerBgd="#dbe7f2"
            handleMenuStateInternally={false}
            menuState={showDropdown}
            setMenuState={() => setShowDropdown(!showDropdown)}
            sendEvent={false}
            handleStateChange={(val) => {
                // let val = e.target.getAttribute('value')
                setFieldObj(prev => ({
                    ...prev,
                    value: val['value']
                }))
                setNewAttorneyField({
                    regionType: val['regionType'],
                    regionId: val['id'],
                    regionValue: val['value']
                })
                console.log("val", val)
                // setFormData(prev => ({
                //     ...prev,
                //     fields: {
                //         ...prev['fields'],
                //         [fieldObj['id']]: {
                //             ...prev['fields'][fieldObj['id']],
                //             value: val,
                //             showDropdown: false,
                //             isValid: fieldObj['isValid'] === undefined ? undefined : val !== "--"
                //         },
                //     }
                // }))
            }}
            currSelection={fieldObj['value'] ? fieldObj['value'] : null}
            valueX={fieldObj['value']}
            boxShadow="0 0 3px #000"
            id={'suggested-tags'}
            setValidContinue={() => { }}
            justifyContent="start"
            textAlign="left"
            // options={fieldObj['options']}
            options={options} />)
}

export const AddAttorneyDropdown = ({ setNewAttorneyField, }) => {
    const [fieldObj, setFieldObj] = useState({ value: 'Law Firm - Budget', options: [], showDropdown: false })
    const { showDropdown, options } = fieldObj
    const setShowDropdown = () => {
        setFieldObj(prev => ({
            ...prev,
            showDropdown: !prev.showDropdown
        }))
    }

    // const options = [{ innerText: 'Attorney Woo', value: 'AttorneyWoo', onClick: () => { } }]
    useEffect(() => {
        const getAttorneys = async () => {
            const res = await API.getAttorneys(null, null, {
                id: 'isActive', value: true
            }, [
                { 'sort': '-budget' },
                // { budget: { $gt: 0 } }
            ])
            const { attorneys } = res
            if (attorneys) {
                console.log("attorneys are: ", attorneys)
                setFieldObj(prev => ({
                    ...prev,
                    options: attorneys.map(it => {
                        const { firmName, budget } = it
                        const str = `${firmName}\n${budget}`
                        return ({ innerText: str, value: str, label: str, id: it['attorneyId'] })
                    })
                }))
            }
        }
        getAttorneys()
    }, [])
    console.log(fieldObj.value, options)
    return (
        <RadixDropdown
            options={options}
            valueIsSelectedKey={"value"}
            value={fieldObj.value}
            color="#000"
            width="fit-content"
            stroke="var(--formBlue1)"
            labelFontSize=".8rem"
            key={"attorneyDropdown"}
            id="attorneyDropdown"
            name="attorneyDropdown"
            onValueChange={async (optionObj) => {

                console.log("optionObj['value']", optionObj['value'])
                setFieldObj(prev => ({
                    ...prev,
                    value: optionObj['value']
                }))
                setNewAttorneyField({
                    // regionType: optionObj['regionType'],
                    // regionId: optionObj['id'],
                    // regionValue: optionObj['value'],
                    attorneyId: optionObj['id'],
                })
            }}
        />
    )
    return (
        <Dropdown
            dropdownRef={null}
            listContainerRef={null}

            width="fit-content"
            headerFontSize="12px"
            headerHeight="49px"
            itemHeight="49px"
            // maxHeight={4 * 49 + 'px'}
            dropdownListMarginTop={"10px"}
            // {...fieldObj}
            showHeader={true}
            showArrow={true}
            name={'Suggested Tags'}
            marginBottom={'1rem'}
            border="none"
            headerBgd="#dbe7f2"
            handleMenuStateInternally={false}
            menuState={showDropdown}
            setMenuState={() => setShowDropdown(!showDropdown)}
            sendEvent={false}
            handleStateChange={(val) => {
                // let val = e.target.getAttribute('value')
                setFieldObj(prev => ({
                    ...prev,
                    value: val['value']
                }))
                setNewAttorneyField({ attorneyId: val['id'] })
                // setFormData(prev => ({
                //     ...prev,
                //     fields: {
                //         ...prev['fields'],
                //         [fieldObj['id']]: {
                //             ...prev['fields'][fieldObj['id']],
                //             value: val,
                //             showDropdown: false,
                //             isValid: fieldObj['isValid'] === undefined ? undefined : val !== "--"
                //         },
                //     }
                // }))
            }}
            currSelection={fieldObj['value'] ? fieldObj['value'] : null}
            valueX={fieldObj['value']}
            boxShadow="0 0 3px #000"
            id={'suggested-tags'}
            setValidContinue={() => { }}
            justifyContent="start"
            textAlign="left"
            // options={fieldObj['options']}
            options={options} />)
}

export default LeadsModal