import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Flexbox from './Flexbox'
import SideBar from './SideBar'
import Regions from './Regions'
import AttorneysTable from './AttorneysTable'
import LeadsTable from './LeadsTable'
import ContactFormSubmissions from './ContactFormSubmissions'
import Disputes from './Disputes'
import DisputesTable from './DisputesTable'
import LeadDelivery from './LeadDelivery'
import LeadDeliveryTable from './LeadDeliveryTable'
import GeographicRegionsTable from './GeographicRegionTable'
import AutoSendConfigComponent from './AutoConfig'

const Home = ({ }) => {
    const location = useLocation()

    const [sidebar, setSidebar] = useState({
        'category-1': {
            id: 'category-1',
            type: 'attorney',
            innerText: 'Attorneys',
            path: 'attorneys',
            options: {
                'option-1': {
                    id: 'option-1',
                    filterAccessor: 'isNewRecord',
                    innerText: 'New',
                    path: 'new-attorneys',
                    name: 'New',
                    value: true,

                },
                'option-2': {
                    id: 'option-2',
                    filterAccessor: 'isActive',
                    innerText: 'Active',
                    path: 'active-attorneys',
                    name: 'Active',
                    value: true,
                },
                'option-3': {
                    id: 'option-3',
                    filterAccessor: 'isActive',
                    innerText: 'Inactive',
                    path: 'inactive-attorneys',
                    name: 'Inactive',
                    value: false,
                    // secondaryAccessors: [{ 'isNewRecord': false }]
                },

            },
            'option-order': ['option-2', 'option-1', 'option-3']
        },
        'category-2': {
            id: 'category-2',
            innerText: 'Regions',
            type: 'region',
            path: 'regions',
            options: {
                'option-1': {
                    id: 'option-1',
                    // filterAccessor: 'isActive',
                    innerText: 'States',
                    path: 'states',
                    name: 'States',
                    value: 'State',
                },
                'option-2': {
                    id: 'option-2',
                    // filterAccessor: 'isActive',
                    innerText: 'Counties',
                    path: 'counties',
                    name: 'Counties',
                    value: 'County',
                },

            },
            'option-order': ['option-1', 'option-2']
        },
        'category-3': {
            id: 'category-3',
            type: 'leads',
            innerText: 'Unmatched Leads',
            path: 'leads',
            options: {
                // 'option-1': {
                //     id: 'option-1',
                //     filterAccessor: 'isComplete',
                //     innerText: 'New',
                //     path: 'new-leads',
                //     name: 'New',
                //     value: false,

                // },
                // 'option-2': {
                //     id: 'option-2',
                //     filterAccessor: 'isComplete',
                //     innerText: 'Past',
                //     path: 'completed-leads',
                //     name: 'Past',
                //     value: true,
                // },
                'option-3': {
                    id: 'option-3',
                    filterAccessor: 'kind',
                    innerText: 'Set Calc Leads',
                    path: 'set-calc',
                    name: 'Set Calc Leads',
                    value: 'SettlementCalcLead',
                    secondaryAccessors: [{ 'isComplete': false },
                        // { 'hasLawyerAlready': "No" }
                    ]
                },
                'option-4': {
                    id: 'option-4',
                    filterAccessor: 'kind',
                    innerText: 'Accident Report',
                    path: 'accident-report',
                    name: 'Accident Report',
                    value: 'AccidentReport',
                    secondaryAccessors: [{ 'isComplete': false },
                        //  { 'hasLawyerAlready': "No" }
                    ]
                },

            },
            'option-order': [
                // 'option-1', 'option-2', 
                'option-3', 'option-4']
        },
        'category-4': {
            id: 'category-4',
            type: 'leads',
            innerText: 'Matched Leads',
            path: 'leads',
            options: {
                'option-1': {
                    id: 'option-1',
                    filterAccessor: 'isComplete',
                    innerText: 'Past',
                    path: 'completed-leads',
                    name: 'Past',
                    value: true,
                },
                'option-2': {
                    id: 'option-2',
                    filterAccessor: 'kind',
                    innerText: 'Set Calc Leads',
                    path: 'set-calc',
                    name: 'Set Calc Leads',
                    value: 'SettlementCalcLead',
                    secondaryAccessors: [{ 'isComplete': true }]
                },

            },
            'option-order': ['option-1', 'option-2']
        },
        'category-5': {
            id: 'category-5',
            type: 'contacts',
            innerText: 'Contact Form',
            path: 'contacts',
            options: {
                'option-1': {
                    id: 'option-1',
                    // filterAccessor: 'isComplete',
                    innerText: 'Contact Form',
                    path: 'new-contacts',
                    name: 'Contact Form',
                    value: false,

                },
            },
            'option-order': ['option-1']
        },
        'category-6': {
            id: 'category-6',
            type: 'disputes',
            innerText: 'Disputes',
            path: 'disputes',
            options: {
                'option-1': {
                    id: 'option-1',
                    filterAccessor: 'status',
                    innerText: 'Pending',
                    path: 'pending-disputes',
                    name: 'Pending',
                    value: "PENDING",
                },
                'option-2': {
                    id: 'option-2',
                    // filterAccessor: 'status',
                    innerText: 'Processed',
                    path: 'pending-disputes',
                    name: 'Pending',
                    secondaryAccessors: [{ 'status': { $ne: "PENDING" } }]
                    // value: "PENDING",
                },
            },
            'option-order': ['option-1', 'option-2']
        },
        'category-7': {
            id: 'category-7',
            type: 'lead-delivery',
            innerText: 'Lead Delivery',
            path: 'lead-delivery',
            options: {
                'option-1': {
                    id: 'option-1',
                    filterAccessor: 'status',
                    innerText: 'Lead Delivery',
                    path: '',
                    name: 'Lead Delivery',
                    value: "LeadDelivery",
                },
                // 'option-2': {
                //     id: 'option-2',
                //     // filterAccessor: 'status',
                //     innerText: 'Processed',
                //     path: 'pending-disputes',
                //     name: 'Pending',
                //     secondaryAccessors: [{ 'status': { $ne: "PENDING" } }]
                //     // value: "PENDING",
                // },
            },
            'option-order': ['option-1']
        },
        'category-8': {
            id: 'category-8',
            type: 'geographic-regions',
            innerText: 'Geographic Regions',
            path: 'geographic-regions',
            options: {
                'option-1': {
                    id: 'option-1',
                    filterAccessor: 'status',
                    innerText: 'Geographic Regions',
                    path: '',
                    name: 'Geographic Regions',
                    value: "GeographicRegions",
                },
                // 'option-2': {
                //     id: 'option-2',
                //     // filterAccessor: 'status',
                //     innerText: 'Processed',
                //     path: 'pending-disputes',
                //     name: 'Pending',
                //     secondaryAccessors: [{ 'status': { $ne: "PENDING" } }]
                //     // value: "PENDING",
                // },
            },
            'option-order': ['option-1']
        },
        'category-order': ['category-3', 'category-7', 'category-8', 'category-4', 'category-5', 'category-1', 'category-2', 'category-6',],
        get active() {
            {
                const _category = this['category-order'][0]
                const _id = this[this['category-order'][0]]['option-order'][0]

                return ({
                    category: _category,
                    id: _id,
                    option: this[_category].options[_id]
                    // id: this[this['category-order'][0]]['options'][this[this['category-order'][0]]['option-order']].id,
                })
            }
        },
    })

    const { active } = sidebar

    const optionIsActive = (category, option) => {
        return (active['id'] === option['id'] && active['category'] === category)
    }

    function onChange(category, optionId) {
        setSidebar(prev => ({
            ...prev,
            active: { category, id: optionId, option: prev[category].options[optionId] }
        }))
    }
    console.log("sidebar active", active)
    useEffect(() => {
        console.log("location", location)
        if (location.state && location.state.optionId && location.state.category) {
            const { state: { category, optionId } } = location
            onChange(category, optionId)
        } else {
            let { category, optionId } = getCatAndOptionFromPathName()
            if (category && optionId)
                onChange(category, optionId)
        }
    }, [location])

    const getCatAndOptionFromPathName = () => {
        const { pathname } = location
        let pathnameArr = pathname.replace('/', '').split('/')
        // .filter(it => it.length > 0)
        console.log("pathnameArr", pathnameArr)
        const category = sidebar['category-order'].find(cat => sidebar[cat].path === pathnameArr[0])
        let optionId
        console.log(category)
        if (pathnameArr.length > 1) {
            optionId = sidebar[category]['option-order'].find(op => (sidebar[category].options[op].path === pathnameArr[1]))
        }
        console.log("optionId", optionId)
        return ({ category, optionId })
    }

    return (
        <OuterContainer height="100vh">
            <SideBar options={sidebar} active={sidebar.active} optionIsActive={optionIsActive} onChange={onChange} />
            {
                sidebar[active.category].type === 'attorney' &&
                <AttorneysTable _active={active.option} />
            }
            {
                sidebar[active.category].type === 'disputes' &&
                <DisputesTable _active={active.option} />
            }
            {
                sidebar[active.category].type === 'leads' &&
                <LeadsTable _active={active.option} />
            }
            {
                sidebar[active.category].type === 'region' &&
                <Regions _active={active.option} />
            }
            {
                sidebar[active.category].type === 'contacts' &&
                <ContactFormSubmissions _active={active.option} />
            }
            {
                sidebar[active.category].type === 'lead-delivery' &&
                <>
                    <LeadDeliveryTable />
                    {/* <LeadDelivery _active={active.option} /> */}
                    {/* <AutoSendConfigComponent /> */}
                </>
            }
            {
                sidebar[active.category].type === 'geographic-regions' &&
                <GeographicRegionsTable _active={active.option} />
            }
        </OuterContainer>
    )
}

const OuterContainer = styled.div`
height: 100%;
min-height: 100vh;
position: relative;
display: flex;
flex-direction: row;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
flex-direction: column;
overflow-x: hidden;
}
`;

export default Home