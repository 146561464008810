import styled from 'styled-components'

export const Wrapper = styled.div`
width: 50px;
height: 30px;

position: relative;
input{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.uncheckedBgd ? props.uncheckedBgd : 'var(--dropdownHoverBackground)'};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background: ${props => props.uncheckedSliderBgd ? props.uncheckedSliderBgd  : props.bgd ? props.bgd : 'white'};
  box-shadow: ${props => props.uncheckedBoxShadow ? props.uncheckedBoxShadow : 'inset 0 0 2px 2px rgba(0,0,0,0.15), 0 0 2px 2px rgba(0,0,0,0.25)'};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 17px;
}

input:checked + .slider {
  background: ${props => props.checkedBgd ? props.checkedBgd : '#00ff00'};
}

input:focus + .slider {
  box-shadow: 0 0 1px ${props => props.checkedBgd ? props.checkedBgd : '#00ff00'};
}

input:checked + .slider:before {
  -webkit-transform: translateX(${50 - 22 - 8}px);
  -ms-transform: translateX(${50 - 22 - 8}px);
  transform: translateX(${50 - 22 - 8}px);
  background: ${props => props.checkedSliderBgd ? props.checkedSliderBgd : ''};
  box-shadow: ${props => props.checkedBoxShadow ? props.checkedBoxShadow : 'inset 0 0 2px 2px rgba(0,0,0,0.15), 0 0 2px 2px rgba(0,0,0,0.25)'};
}
`;
