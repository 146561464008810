import React from 'react'
import styled from 'styled-components'

import Flexbox from '../Flexbox';
const WheelWrapper = styled.div`
height: 25px;
width: 25px;
border: 2px solid rgba(0,0,0,0.1);
border-left: 2px solid var(--formBlue2);
border-radius: 50%;
position: relative;
animation: rotate 1.1s linear infinite;
/* :after{
    content: '';
    position: absolute;
    height: 25px;
    width: 25px;
    
    border-radius: 50%;

} */
@keyframes rotate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
`;

const LoadingWheel = () => {
    return (
        <Flexbox width="25px" height="25px" margin="auto">
            <WheelWrapper></WheelWrapper>
        </Flexbox>
    )
}

export default LoadingWheel