import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TiledInput from '../TiledInput'
import Modal from '../Modal'
import TableCell from '../TableCell'
import TableRow from '../TableRow'
import Table from '../Table'
import Flexbox from '../Flexbox'
import API from '../../API'
import InputField from '../InputField'
import Button from '../Button'
import Toggle from '../Toggle'
import TableCellWithInputField from '../TableCellWithInputField'
import { toTitleCase, formatPrice, formatPriceInputField, formatPhoneNumber, normalizeString, findMatchAcrossKeys } from '../../utils'
import { default as statesArr } from '../../utils/states'
import ActionMenu from '../ActionMenu'

const MAX_DEC_PLACES = 2


const Tag = styled.div`
padding: 3px;
border: 1px solid #545454;
background: ${({ background = '#fff' }) => background};
border-radius: 5px;
`;

const AttorneysModal = ({ attorney, ...props }) => {
    const selectedStates = []
    const [attorneyModified, setAttorneyModified] = useState({
        newActiveLeadRegions: [],
        currentActiveLeadRegions: [],
        states: [],
        statesActive: [],
        budget: '',
        pricingData: [],
        isNationwide: false,
        isActive: false,
        matchedLeads: [],
        matchedLeadsHeader: {}
    })

    const { matchedLeadsHeader, matchedLeads, geographicRegions, isNationwide, isActive, states, statesActive, newActiveLeadRegions: items, newActiveLeadRegions, budget } = attorneyModified
    const { type, setShowModal } = props
    useEffect(() => {
        const init = async () => {
            let _newActiveLeadRegions = []
            if (attorney.statesActive && attorney.countiesActive && attorney.citiesActive)
                _newActiveLeadRegions = [
                    ...attorney.statesActive.map(region => {
                        const { regionId: state, regionId } = region
                        const { abbreviation, name, ste_code, } = state
                        return ({
                            ...region,
                            type: 'state',
                            innerText: name,
                            regionId,
                            name,
                            value: name,
                            abbreviation,
                            key: abbreviation,
                            id: abbreviation,
                            background: 'var(--tagBgdState)'
                        })
                    }),
                    ...attorney.countiesActive.map(region => {
                        const { regionId: coty, regionId } = region
                        const { coty_name_long, name, ste_name, coty_name, coty_code, ste_code, geo_point_2d } = coty

                        return ({
                            ...region,
                            type: 'county', //county
                            regionId,
                            innerText: `${name}, ${ste_name}`,
                            value: `${name}, ${ste_name}`,
                            county: name,
                            name: name,
                            coty_code,
                            state: ste_name,
                            ste_name,
                            ste_code,
                            id: coty_code,
                            key: coty_code,
                            geo_point_2d,
                            background: 'var(--tagBgdCounty)'
                        })
                    }),
                    ...attorney.citiesActive.map(region => {
                        const { regionId: coty, regionId } = region
                        const { name, ste_name, pla_code, coty_name, coty_code, ste_code, geo_point_2d } = coty
                        return ({
                            ...region,
                            type: 'place', //place
                            innerText: `${name}, ${ste_name}`,
                            regionId,
                            value: `${name}, ${ste_name}`,
                            pla_code,
                            name: name,
                            coty_code,
                            coty_name,
                            county: coty_name,
                            state: ste_name,
                            ste_name,
                            ste_code,
                            id: pla_code,
                            key: pla_code,
                            geo_point_2d,
                            background: 'var(--tagBgdCity)'
                        })
                    }),



                ]
            let { matchedLeads, matchedLeadsHeader } = await API.getAttorneyDetailed(attorney._id)
            console.log("matchedLeads", matchedLeads)
            setAttorneyModified(prev => ({
                ...prev,
                attorney,
                budget: formatPrice(attorney.budget).replace('$', ''),
                newActiveLeadRegions: _newActiveLeadRegions,
                currentActiveLeadRegions: _newActiveLeadRegions, //from database
                isNationwide: attorney['isNationwide'],
                isActive: attorney['isActive'],
                matchedLeadsHeader,
                matchedLeads
                // states: _states,
                // statesActive: _states
            }))

        }
        init()

    }, [attorney])

    console.log("attorneyModified", attorneyModified)
    console.log("attorney", attorney)

    const getPricing = async () => {

        const res = await API.getPricing(newActiveLeadRegions, attorney._id)
        setAttorneyModified(prev => ({
            ...prev,
            pricingData: res.pricing
        }))
    }

    const setItems = async (formattedItem) => {

        if (!isSelected(formattedItem)) {
            // const res = await API.getPricing(formattedItem, attorney._id)
            const res = await API.getPricing([formattedItem], attorney._id)
            console.log("pricing:", res.pricing, formattedItem)
            if (res.pricing && res.pricing.length)
                setAttorneyModified(prev => ({ ...prev, newActiveLeadRegions: [...prev.newActiveLeadRegions, { ...res.pricing[0], ...formattedItem }] }))
        }
        else
            setAttorneyModified(prev => ({ ...prev, newActiveLeadRegions: [...prev.newActiveLeadRegions].filter(it => it.id !== formattedItem.id) }))
    }


    const validatorAddItem = (trimmedInput, _counties) => {

        if (!_counties.find(it => it.id === trimmedInput)) {
            //setCounties
            return true
        }
        return false
    }

    const isSelected = (val) => {
        return (items.findIndex(item => (item['pla_code'] === val.pla_code && val.pla_code !== undefined) || (item['coty_code'] === val.coty_code && val.coty_code !== undefined) || ((val['type'] === 'state' && item.type === 'state') && val['id'] === item['id'])) !== -1)
    }

    const formatSelection = (trimmedInput) => {
        // const _state = states.find(it => it.name.toLowerCase() === trimmedInput.toLowerCase())

        // if (_state)
        //     return _state.name
        // else 
        return trimmedInput
    }

    const searchStates = (_val) => {
        const _states = states.find()
    }



    const findSearchMatch = (value) => {
        console.log("STATES:", statesArr)
        return statesArr.filter(state => {
            console.log("value is:", value, stateIsSelected(state.name), findMatchAcrossKeys(state, value))
            return (!stateIsSelected(state.name) && findMatchAcrossKeys(state, value))
        })
    }

    const stateIsSelected = (stateName) => {
        return statesActive.find(state => state.name === stateName)
    }


    const getSuggestions = async (_input, setInput) => {

        let matchingStates = findSearchMatch(_input).map(state => {
            const formattedItem = {
                type: 'state',
                innerText: state.name,
                name: state.name,
                value: state.name,
                abbreviation: state.abbreviation,
                key: state.abbreviation,
                id: state.abbreviation,
                regionCode: state.abbreviation,
                regionType: 'State',
                background: 'var(--tagBgdState)',
            }
            return ({
                ...formattedItem,

                onClick: () => {
                    // if (!isSelected(formattedItem)) {
                    // addState(state)
                    setItems(formattedItem)
                    setInput('')
                    // }
                },
            })
        })


        let countyRecords = await API.searchCounties(_input, selectedStates)
        if (countyRecords)
            countyRecords = countyRecords.reduce((arr, item) => {

                const { fields, fields: { coty_name, coty_name_long, geo_point_2d, ste_name, ste_code, coty_code } } = item

                if (isSelected(fields))
                    return arr

                // if (!coty_name.includes(_input.split(',')[0].trim()) || !selectedStates.includes(ste_name))
                if (!coty_name.includes(toTitleCase(_input.split(',')[0].trim())))
                    return arr

                const formattedItem = {
                    type: 'county', //county
                    innerText: `${coty_name_long}, ${ste_name}`,
                    value: `${coty_name_long}, ${ste_name}`,
                    county: coty_name,
                    name: coty_name,
                    coty_code,
                    state: ste_name,
                    ste_name,
                    ste_code,
                    id: coty_code,
                    regionCode: coty_code,
                    regionType: 'County',
                    key: coty_code,
                    geo_point_2d,
                    background: 'var(--tagBgdCounty)'
                }

                arr.push({
                    ...formattedItem,
                    onClick: () => {
                        if (!isSelected(formattedItem)) {
                            // addState(state)
                            setItems(formattedItem)
                            setInput('')
                        }
                    },
                    key: item.id
                })
                return arr
            }, [])

        if (matchingStates.length > 0)
            matchingStates.splice(0, 0, {
                innerText: 'States',
                value: 'States',
                onClick: () => { },
                id: 'States',
                props: {
                    hoverOff: true,
                    className: 'listHeader'
                },
                isKey: true
            })

        if (countyRecords.length > 0)
            countyRecords.splice(0, 0, {
                innerText: 'Counties',
                value: 'Counties',
                onClick: () => { },
                id: 'Counties',
                props: {
                    hoverOff: true,
                    className: 'listHeader'
                },
                isKey: true
            })

        let cityRecords = await API.searchPlaces(_input, selectedStates)

        cityRecords = cityRecords.reduce(
            (arr, item) => {

                const { fields, fields: { coty_name, pla_name, pla_code, geo_point_2d, ste_name, ste_code, coty_code } } = item

                if (isSelected(fields))
                    return arr


                // if (!pla_name.includes(_input.split(',')[0].trim()) || !selectedStates.includes(ste_name))
                if (!pla_name.includes(toTitleCase(_input.split(',')[0].trim())))
                    return arr

                const formattedItem = {
                    type: 'place', //place
                    innerText: `${pla_name}, ${ste_name}`,
                    value: `${pla_name}, ${ste_name}`,
                    pla_code,
                    name: pla_name,
                    coty_code,
                    coty_name,
                    county: coty_name,
                    state: ste_name,
                    ste_name,
                    ste_code,
                    id: pla_code,
                    regionCode: pla_code,
                    regionType: 'City',
                    key: pla_code,
                    geo_point_2d,
                    background: 'var(--tagBgdCity)'
                }

                arr.push({
                    ...formattedItem,
                    onClick: () => {
                        if (!isSelected(formattedItem)) {
                            // addState(state)
                            setItems(formattedItem)
                            setInput('')
                        }
                    },
                    key: item.id
                })
                return arr
            }
            , [])

        if (cityRecords.length > 0)
            cityRecords.splice(0, 0, {
                innerText: 'Cities',
                value: 'Cities',
                onClick: () => { },
                id: 'Cities',
                props: {
                    hoverOff: true,
                    className: 'listHeader'
                },
                isKey: true
            })

        return matchingStates.concat(countyRecords.concat(cityRecords))

    }


    const priceOnBlur = (event) => {
        console.log("true", event.target)
        let value;
        if (parseFloat(event.target.value) !== 0 && event.target.value !== '') {
            if (event.target.value.split('.').length < 2) {
                value = event.target.value + '.00';

            }
            else {
                value = event.target.value.split('.')[0] + '.' + (event.target.value.split('.')[1]).substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
            }
            setAttorneyModified(prev => ({ ...prev, budget: value }))

        }
        else {
            setAttorneyModified(prev => ({ ...prev, budget: '' }))
        }
    }

    const handleSubmit = async () => {
        if (type === 'view-attorney-details') {
            setShowModal()
            return
        }
        let data = {}
        // if (newActiveLeadRegions.length > 0) {
        const res = await API.updateAttorney({
            isActive: true,
            _id: attorney._id,
            newActiveLeadRegions,
            budget,
            isNationwide,
            isActive
        })
        // }
    }

    // useEffect(() => {
    //     const getPricing = async () => {

    //         const res = await API.getPricing(newActiveLeadRegions, attorney._id)
    //         setAttorneyModified(prev => ({
    //             ...prev,
    //             pricingData: res.pricing
    //         }))
    //     }
    //     if (newActiveLeadRegions.length > 0)
    //         getPricing()
    //     // check for pricing data on these regions
    // }, [newActiveLeadRegions])
    // console.log("pricing", attorneyModified.pricingData)

    const rowProps = { className: "hoverOff", background: "transparent" }
    return (
        <Modal {...props} disableCloseOnClickBgd={true} maxWidth="1100px">

            <Table className="hoverOff">
                <TableRow background="transparent">
                    <TableCell userSelect="none" colSpan="3" padding="10px 0" width="min-content">
                        <h3 className="textLeft noMargin">Attorney</h3>
                        {/* <p className="noMargin" style={{ fontSize: '12px' }}>Initial regions selected by the user in their original form submission.</p> */}
                    </TableCell>
                </TableRow>
                <TableRow {...rowProps} >
                    <TableCell userSelect="none" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" width="min-content">
                        Name
                    </TableCell>
                    <TableCell border="1px solid var(--dropdownHoverBackground)" colSpan="2" >
                        {attorney.name}
                    </TableCell>
                </TableRow>

                <TableRow {...rowProps} >
                    <TableCell userSelect="none" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" width="min-content" whiteSpace="nowrap">
                        Firm Name
                    </TableCell>
                    <TableCell border="1px solid var(--dropdownHoverBackground)" colSpan="2" >
                        {attorney.firmName}
                    </TableCell>
                </TableRow>
                <TableRow {...rowProps} >
                    <TableCell userSelect="none" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" width="min-content" whiteSpace="nowrap">
                        Email
                    </TableCell>
                    <TableCell border="1px solid var(--dropdownHoverBackground)" colSpan="2" >
                        {attorney.email}
                    </TableCell>
                </TableRow>
                <TableRow {...rowProps} >
                    <TableCell userSelect="none" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" width="min-content" whiteSpace="nowrap">
                        Phone Number
                    </TableCell>
                    <TableCell border="1px solid var(--dropdownHoverBackground)" colSpan="2" >
                        {formatPhoneNumber(attorney.phoneNumber)}
                    </TableCell>
                </TableRow>
                {
                    type !== 'view-attorney-details' &&
                    <>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0" width="min-content">
                                <h3 className="textLeft noMargin">Nationwide Coverage</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>Turn nationwide coverage for this attorney on/off. With nationwide on, lorem lorem lorem</p>
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="0 0 10px" width="min-content">
                                <Toggle
                                    defaultValue={isNationwide}
                                    _checked={isNationwide}
                                    onChange={(e) => {
                                        console.log("isNationwide", isNationwide)
                                        setAttorneyModified(
                                            prev => ({
                                                ...prev,
                                                isNationwide: !isNationwide
                                            })
                                        )
                                    }} options={[true, false]} showLabelText={true} label="Nationwide" fontSize="12px" />
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0" width="min-content">
                                <h3 className="textLeft noMargin">Active In Matching</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>Enable/disable lead matching for this attorney.</p>
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="0 0 10px" width="min-content">
                                <Toggle
                                    defaultValue={isActive}
                                    _checked={isActive}
                                    onChange={(e) => {
                                        console.log("isActive", isActive)
                                        setAttorneyModified(
                                            prev => ({
                                                ...prev,
                                                isActive: !isActive
                                            })
                                        )
                                    }} options={[true, false]} showLabelText={true} label="Active" fontSize="12px" />
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0" width="min-content">
                                <h3 className="textLeft noMargin">Form Submission Regions</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>Initial regions selected by the user in their original form submission.</p>
                            </TableCell>
                        </TableRow>
                        {
                            (attorney.unidentifiedPlaces && attorney.unidentifiedPlaces.length > 0) &&
                            <TableRow background="transparent" >
                                <TableCell background="var(--red)" border="1px solid var(--red)">
                                    Manual Entries (Unidentified in Database)
                                </TableCell>
                                <TableCell border="1px solid var(--red)">
                                    <Tag>
                                        {attorney.unidentifiedPlaces[0]}
                                    </Tag>
                                </TableCell>
                            </TableRow>
                        }

                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdState)' border="1px solid var(--tagBgdState)" width="min-content">
                                States
                            </TableCell>
                            <TableCell border="1px solid var(--tagBgdState)" colSpan="2" >
                                <Flexbox gap="5px" userSelect="none">
                                    {attorney.statesInitial.map(state => (
                                        <Tag background="#fdca91"

                                            onClick={() => {
                                                const { name, abbreviation } = state

                                                const formattedItem = {
                                                    type: 'state',
                                                    innerText: name,
                                                    name,
                                                    value: name,
                                                    abbreviation,
                                                    key: abbreviation,
                                                    id: abbreviation,
                                                    background: 'var(--tagBgdState)'
                                                }
                                                setItems(formattedItem)
                                            }}
                                        >{state['name']}</Tag>
                                    ))
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps}>
                            <TableCell background='var(--tagBgdCounty)' border="1px solid var(--tagBgdCounty)" width="min-content">
                                Counties
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdCounty)" colSpan="2" >
                                <Flexbox gap="5px">
                                    {attorney.countiesInitial.map(coty => (
                                        <Tag background="#91fdc9"
                                            onClick={() => {
                                                const { coty_name_long, name, ste_name, coty_name, coty_code, ste_code, geo_point_2d } = coty

                                                const formattedItem = {
                                                    type: 'county', //county
                                                    innerText: `${name}, ${ste_name}`,
                                                    value: `${name}, ${ste_name}`,
                                                    county: name,
                                                    name: name,
                                                    coty_code,
                                                    state: ste_name,
                                                    ste_name,
                                                    ste_code,
                                                    id: coty_code,
                                                    key: coty_code,
                                                    geo_point_2d,
                                                    background: 'var(--tagBgdCounty)'
                                                }
                                                setItems(formattedItem)
                                            }}>{coty['name'] + ', ' + coty['state']['abbreviation']}</Tag>
                                    ))
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdCity)' border="1px solid var(--tagBgdCity)" width="min-content">
                                Cities
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdCity)" colSpan="2" >
                                <Flexbox gap="5px">
                                    {attorney.citiesInitial.map(coty => (
                                        <Tag background="#91c5fd"
                                            onClick={() => {
                                                const { name, ste_name, pla_code, coty_name, coty_code, ste_code, geo_point_2d } = coty
                                                const formattedItem = {
                                                    type: 'place', //place
                                                    innerText: `${name}, ${ste_name}`,
                                                    value: `${name}, ${ste_name}`,
                                                    pla_code,
                                                    name: name,
                                                    coty_code,
                                                    coty_name,
                                                    county: coty_name,
                                                    state: ste_name,
                                                    ste_name,
                                                    ste_code,
                                                    id: pla_code,
                                                    key: pla_code,
                                                    geo_point_2d,
                                                    background: 'var(--tagBgdCity)'
                                                }
                                                setItems(formattedItem)
                                            }}>{coty['name'] + ', ' + coty['state']['abbreviation']}</Tag>
                                    ))
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                    </>

                }
                {
                    attorney.matchedLeads &&
                    <>
                        {
                            attorney.matchedLeads.map(lead => {
                                console.log("LEAD:", lead)
                                return (
                                    <>
                                        <TableRow background="transparent" border="none">
                                            <TableCell userSelect="none" colSpan="3" padding="10px 0" border="none">
                                                <h3 className="textLeft noMargin">Lead</h3>
                                                <p className="noMargin" style={{ fontSize: '12px' }}>{lead['phoneNumber']}</p>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow {...rowProps} >
                                            <TableCell userSelect="none" background='var(--dropdownHoverBackground)' border="1px solid var(--dropdownHoverBackground)" width="min-content">
                                                Name
                                            </TableCell>
                                            <TableCell border="1px solid var(--dropdownHoverBackground)" colSpan="2" >
                                                {lead.name}
                                            </TableCell>
                                        </TableRow>
                                    </>)
                            })
                        }
                    </>
                }


                {/* </Table> */}
                {(attorneyModified.currentActiveLeadRegions.length > 0 || attorneyModified.statesActive?.length > 0) &&
                    <>
                        <TableRow background="transparent" border="none">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0" border="none">
                                <h3 className="textLeft noMargin">Current Active Lead Regions</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>Regions currently reflected in the database.</p>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdState)' border="1px solid var(--tagBgdState)" width="min-content">
                                States
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdState)" colSpan="2" >
                                <Flexbox gap="5px">
                                    {attorneyModified.currentActiveLeadRegions.reduce((arr, coty) => {
                                        console.log("coty type", coty.type)
                                        if (coty.type === 'state')
                                            arr.push(
                                                <Tag background="var(--tagBgdState)"
                                                    onClick={() => {
                                                        if (type === 'view-attorney-details')
                                                            return
                                                        const { name, abbreviation } = coty

                                                        const formattedItem = {
                                                            ...coty,
                                                            type: 'state',
                                                            innerText: name,
                                                            value: name,
                                                            name,
                                                            abbreviation,
                                                            key: abbreviation,
                                                            id: abbreviation,
                                                            background: 'var(--tagBgdState)'
                                                        }
                                                        setItems(formattedItem)
                                                    }}>{coty['name']}</Tag>)
                                        return arr

                                    }, [])
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdCounty)' border="1px solid var(--tagBgdCounty)" width="min-content">
                                Counties
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdCounty)" colSpan="2" >
                                <Flexbox gap="5px">
                                    {attorneyModified.currentActiveLeadRegions.reduce((arr, coty) => {

                                        if (coty.type === 'county')
                                            arr.push(
                                                <Tag background="#91fdc9"
                                                    onClick={() => {

                                                        const { coty_name_long, name, ste_name, coty_name, coty_code, ste_code, geo_point_2d } = coty

                                                        const formattedItem = {
                                                            ...coty,
                                                            type: 'county', //county
                                                            innerText: `${name}, ${ste_name}`,
                                                            value: `${name}, ${ste_name}`,
                                                            county: name,
                                                            name: name,
                                                            coty_code,
                                                            state: ste_name,
                                                            ste_name,
                                                            ste_code,
                                                            id: coty_code,
                                                            key: coty_code,
                                                            geo_point_2d,
                                                            background: 'var(--tagBgdCounty)'
                                                        }
                                                        setItems(formattedItem)
                                                    }}>{coty['name'] + ', ' + coty['state']}</Tag>)
                                        return arr

                                    }, [])
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdCity)' border="1px solid var(--tagBgdCity)" width="min-content">
                                Cities
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdCity)" width="100%" colSpan="2" >
                                <Flexbox gap="5px">
                                    {
                                        attorneyModified.currentActiveLeadRegions.reduce((arr, coty) => {

                                            if (coty.type === 'place')
                                                arr.push(
                                                    <Tag background="#91c5fd"
                                                        onClick={() => {
                                                            if (type === 'view-attorney-details')
                                                                return
                                                            const { name, ste_name, pla_code, coty_name, coty_code, ste_code, geo_point_2d } = coty
                                                            const formattedItem = {
                                                                ...coty,
                                                                type: 'place', //place
                                                                innerText: `${name}, ${ste_name}`,
                                                                value: `${name}, ${ste_name}`,
                                                                pla_code,
                                                                name: name,
                                                                coty_code,
                                                                coty_name,
                                                                county: coty_name,
                                                                state: ste_name,
                                                                ste_name,
                                                                ste_code,
                                                                id: pla_code,
                                                                key: pla_code,
                                                                geo_point_2d,
                                                                background: 'var(--tagBgdCity)'
                                                            }
                                                            setItems(formattedItem)
                                                        }}>{coty['name'] + ', ' + coty['state']}</Tag>)
                                            return arr
                                        }, [])
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                    </>
                }
                {

                    type !== 'view-attorney-details' &&
                    <>
                        <TableRow background="transparent">
                            <TableCell colSpan="3" padding="10px 0">
                                <TiledInput
                                    // onFocus={onFocus}
                                    width="100%"
                                    maxWidth="calc(90vw - 200px)"
                                    className="geographicArea"
                                    validatorAddItem={validatorAddItem}
                                    placeHolder={"County, State"}
                                    items={items}
                                    setItems={setItems}
                                    tagOnClick={
                                        function (_tag) {
                                            if (type === 'view-attorney-details')
                                                return
                                            setItems(_tag)
                                        }}
                                    // validatorRemoveItem={validatorRemoveItem}
                                    formatSelection={formatSelection}
                                    getSuggestions={getSuggestions} />
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0">
                                <h3 className="textLeft noMargin">New Active Lead Regions</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>The new regions covered by this attorney that will overwrite the 'current active lead regions'.</p>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdState)' border="1px solid var(--tagBgdState)" width="min-content">
                                States
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdState)" colSpan="2" >
                                <Flexbox gap="5px">
                                    {attorneyModified.newActiveLeadRegions.reduce((arr, coty) => {
                                        if (coty.type === 'state')
                                            arr.push(
                                                <Tag background="var(--tagBgdState)"
                                                    onClick={() => {

                                                        const { name, abbreviation } = coty

                                                        const formattedItem = {
                                                            ...coty,
                                                            type: 'state',
                                                            innerText: name,
                                                            value: name,
                                                            name,
                                                            abbreviation,
                                                            key: abbreviation,
                                                            id: abbreviation,
                                                            background: 'var(--tagBgdState)'
                                                        }

                                                        setItems(formattedItem)
                                                    }}>{coty['name']}</Tag>)
                                        return arr

                                    }, [])
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdCounty)' border="1px solid var(--tagBgdCounty)" width="min-content">
                                Counties
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdCounty)" colSpan="2" >
                                <Flexbox gap="5px">
                                    {attorneyModified.newActiveLeadRegions.reduce((arr, coty) => {

                                        if (coty.type === 'county')
                                            arr.push(
                                                <Tag background="#91fdc9"
                                                    onClick={() => {
                                                        const { innerText, value, coty_name_long, ste_name, coty_name, coty_code, ste_code, geo_point_2d } = coty
                                                        const formattedItem = {
                                                            ...coty,
                                                            type: 'county', //county
                                                            innerText: innerText,
                                                            value: value,
                                                            county: coty_name,
                                                            name: coty_name,
                                                            coty_code,
                                                            state: ste_name,
                                                            ste_name,
                                                            ste_code,
                                                            id: coty_code,
                                                            key: coty_code,
                                                            geo_point_2d,
                                                            background: 'var(--tagBgdCounty)'
                                                        }
                                                        setItems(formattedItem)
                                                    }}>{coty['name'] + ', ' + coty['state']}</Tag>)
                                        return arr

                                    }, [])
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow {...rowProps} >
                            <TableCell background='var(--tagBgdCity)' border="1px solid var(--tagBgdCity)" width="min-content">
                                Cities
                            </TableCell>
                            <TableCell userSelect="none" border="1px solid var(--tagBgdCity)" width="100%" colSpan="2" >
                                <Flexbox gap="5px">
                                    {
                                        attorneyModified.newActiveLeadRegions.reduce((arr, coty) => {

                                            if (coty.type === 'place')
                                                arr.push(
                                                    <Tag background="#91c5fd"
                                                        onClick={() => {
                                                            const { innerText, value, name, ste_name, pla_code, coty_name, coty_code, ste_code, geo_point_2d } = coty
                                                            const formattedItem = {
                                                                ...coty,
                                                                type: 'place', //place
                                                                innerText: innerText,
                                                                value: value,
                                                                pla_code,
                                                                name: name,
                                                                coty_code,
                                                                coty_name,
                                                                county: coty_name,
                                                                state: ste_name,
                                                                ste_name,
                                                                ste_code,
                                                                id: pla_code,
                                                                key: pla_code,
                                                                geo_point_2d,
                                                                background: 'var(--tagBgdCity)'
                                                            }
                                                            setItems(formattedItem)
                                                        }}>{coty['name'] + ', ' + coty['state']}</Tag>)
                                            return arr
                                        }, [])
                                    }
                                </Flexbox>
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0" width="min-content">
                                <h3 className="textLeft noMargin">Budget</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>The current remaining budget for this attorney's leads.</p>
                            </TableCell>
                        </TableRow>
                        <TableRow className="hoverOff noSelect" background="transparent">
                            <TableCell colSpan="3" padding="0" border="none">
                                <InputField
                                    name="price"
                                    padding="0 26px"
                                    value={attorneyModified.budget}
                                    onChange={(event) => {
                                        let value = event.target.value !== undefined ? event.target.value : event.target.attributes.value.value;

                                        let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                        let decimal; //MAX_DEC_PLACES
                                        if (value.split('.').length > 1) {
                                            decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
                                            price = price + '.' + decimal;
                                        }
                                        setAttorneyModified(prev => ({ ...prev, budget: price }))

                                    }}
                                    priceOnBlur={priceOnBlur} />
                            </TableCell>
                        </TableRow>
                        <TableRow background="transparent">
                            <TableCell userSelect="none" colSpan="3" padding="10px 0" width="min-content">
                                <h3 className="textLeft noMargin">Lead Region Pricing</h3>
                                <p className="noMargin" style={{ fontSize: '12px' }}>XXXXXXXXX</p>
                            </TableCell>
                        </TableRow>
                        {/* <TableRow className="hoverOff noSelect" background="transparent">
                    <TableCell colSpan="2" padding="0" border="none">
                        
                    </TableCell>
                </TableRow> */}
                        <TableRow background="transparent">
                            <TableCell userSelect="none" width="min-content" >
                                Region
                            </TableCell>
                            {/* <TableCell userSelect="none" width="min-content" >
                        Region Type
                    </TableCell> */}
                            <TableCell userSelect="none" width="min-content" >
                                Price
                            </TableCell>
                            <TableCell whiteSpace="nowrap" userSelect="none" width="min-content" >
                                Price Source
                            </TableCell>
                        </TableRow>
                        {/* {
                    attorneyModified.pricingData.map(it => {
                        let regionId;
                        if (it.regionId)
                            regionId = it.regionId
                        else
                            regionId = it
                        const { regionType } = it
                        let pricing = null
                        let name = regionId ? regionId.name : it.name
                        if (it.regionType === 'City')
                            name = name + ' , ' + regionId.state.abbreviation
                        if (it.regionType === 'County')
                            name = name + ' , ' + regionId.state.abbreviation
                        if (it.pricingType === 'default') {
                            let result = null
                            if (regionId.counties)
                                result = regionId.counties.find(it => it.defaultLeadPrice !== 0)
                            if (result)
                                pricing = result
                            else if (regionId.state && regionId.state.defaultLeadPrice !== 0)
                                pricing = regionId.state
                            else if (regionId.regionType === 'State')
                                pricing = regionId
                        }
                        let defaultValue = regionId.pricing ? regionId.pricing : it.pricingType === 'default' && pricing !== null ? pricing.defaultLeadPrice : 0
                        if (pricing)
                            console.log("pricing default", pricing, pricing.defaultLeadPrice)
                        return (
                            <TableRow background="transparent">
                                <TableCell userSelect="none" width="min-content" whiteSpace="nowrap" background={`var(--tagBgd${it['regionType']})`} border={`1px solid var(--tagBgd${it['regionType']})`}>
                                    {name}
                                </TableCell>
                                <TableCell userSelect="none" padding="0" width="min-content" border={`1px solid var(--tagBgd${it['regionType']})`}>
                                    <TableCellWithInputField
                                        formatCellValueDisplay={(_val) => formatPrice(_val)}
                                        formatInputFieldValue={(_val) => formatPriceInputField(_val)}
                                        formatCellValue={(_val) => formatPrice(_val).replaceAll('$', '')}
                                        onChange={(_val) => {
                                            const content = attorneyModified.pricingData
                                            const newActiveLeadRegionsArr = attorneyModified.newActiveLeadRegions
                                            const indexOfUpdate = content.findIndex(item => item.regionType === regionType && item._id === it._id)
                                            const indexOfUpdate2 = newActiveLeadRegionsArr.findIndex(item =>
                                                (item.type === regionType || item.type === 'place' && regionType === 'City') && item.name === it.name)
                                            let newPrice = parseFloat(_val.replace(',', ''))
                                            if (defaultValue !== 0 && newPrice === defaultValue)
                                                newPrice = undefined
                                            const element = { ...content[indexOfUpdate], newPrice }
                                            const element2 = { ...newActiveLeadRegionsArr[indexOfUpdate], newPrice }
                                            content.splice(indexOfUpdate, 1, element)
                                            newActiveLeadRegionsArr.splice(indexOfUpdate2, 1, element2)
                                            setAttorneyModified(prev => ({
                                                ...prev,
                                                pricingData: content,
                                                // newActiveLeadRegions:
                                            }))
                                        }}
                                        defaultValue={defaultValue}
                                    />
                                </TableCell>
                                <TableCell whiteSpace="nowrap" userSelect="none" width="min-content" border={`1px solid var(--tagBgd${it['regionType']})`}>

                                    {
                                        (it.pricingType === 'default' && pricing !== null && !it.newPrice)
                                        && `Default - ${pricing.regionType}`
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })
                } */}
                        {
                            attorneyModified.newActiveLeadRegions.map(it => {
                                console.log("item is", it)
                                let regionId;
                                if (it.regionId)
                                    regionId = it.regionId
                                else
                                    regionId = it
                                const { regionType } = it
                                let pricing = null
                                let name = regionId ? regionId.name : it.name
                                if (it.regionType === 'City')
                                    name = name + ', ' + regionId.state.abbreviation || regionId.ste_name
                                if (it.regionType === 'County')
                                    name = name + ', ' + regionId.state.abbreviation || regionId.ste_name
                                // if (it.pricingType === 'default') {
                                let result = null
                                if (regionId.defaultLeadPrice)
                                    result = regionId
                                if (it.defaultPrice)
                                    result = it
                                if (regionId.counties && !result)
                                    result = regionId.counties.find(it => it.defaultLeadPrice !== 0)
                                if (result)
                                    pricing = result
                                else if (regionId.state && regionId.state.defaultLeadPrice !== 0)
                                    pricing = regionId.state
                                else if (regionId.regionType === 'State')
                                    pricing = regionId
                                // }
                                console.log("region id:", regionId)
                                let defaultValue = regionId.pricing ? regionId.pricing :
                                    it.pricingType === 'user-specific' ? it.price :
                                        it.pricingType === 'default' && pricing !== null ? pricing.defaultLeadPrice : it.defaultLeadPrice ? it.defaultLeadPrice : 0
                                if (pricing)
                                    console.log("pricing default", pricing, pricing.defaultLeadPrice)
                                return (
                                    <TableRow background="transparent">
                                        <TableCell userSelect="none" width="min-content" whiteSpace="nowrap" background={`var(--tagBgd${it['regionType']})`} border={`1px solid var(--tagBgd${it['regionType']})`}>
                                            {it.innerText}
                                        </TableCell>
                                        <TableCell userSelect="none" padding="0" width="100%" border={`1px solid var(--tagBgd${it['regionType']})`}>
                                            <TableCellWithInputField
                                                formatCellValueDisplay={(_val) => formatPrice(_val)}
                                                formatInputFieldValue={(_val) => formatPriceInputField(_val)}
                                                formatCellValue={(_val) => formatPrice(_val).replaceAll('$', '')}
                                                onChange={(_val) => {
                                                    const content = attorneyModified.pricingData
                                                    const newActiveLeadRegionsArr = attorneyModified.newActiveLeadRegions
                                                    const indexOfUpdate = content.findIndex(item => item.regionType === regionType && item._id === it._id)
                                                    const indexOfUpdate2 = newActiveLeadRegionsArr.findIndex(item => {
                                                        console.log("XXXXXXXXXXX: ", item, regionType, it)
                                                        return ((item.type.toLowerCase() === regionType.toLowerCase() || (item.type === 'place' && regionType === 'City')) && item.name === it.name)
                                                    })
                                                    let newPrice = parseFloat(_val.replace(',', ''))
                                                    if (defaultValue !== 0 && newPrice === defaultValue)
                                                        newPrice = undefined
                                                    const element = { ...content[indexOfUpdate], newPrice }
                                                    const element2 = { ...newActiveLeadRegionsArr[indexOfUpdate2], newPrice }
                                                    content.splice(indexOfUpdate, 1, element)
                                                    newActiveLeadRegionsArr.splice(indexOfUpdate2, 1, element2)
                                                    setAttorneyModified(prev => ({
                                                        ...prev,
                                                        pricingData: content,
                                                        newActiveLeadRegions: newActiveLeadRegionsArr
                                                    }))
                                                }}
                                                defaultValue={defaultValue}
                                            />
                                        </TableCell>
                                        <TableCell whiteSpace="nowrap" userSelect="none" width="min-content" border={`1px solid var(--tagBgd${it['regionType']})`}>

                                            {
                                                (it.pricingType === 'default' && pricing !== null && !it.newPrice)
                                                && `Default - ${pricing.regionType}`
                                            }
                                            {
                                                (!it.pricingType && pricing !== null && !it.newPrice)
                                                && `Default - ${pricing.regionType}`
                                            }
                                            {
                                                (it.pricingType === 'user-specific' && pricing !== null)
                                                && <>User Specific<br />{pricing.regionType} Default - {formatPrice(pricing.defaultLeadPrice)}</>
                                            }
                                            {
                                                (it.pricingType === 'user-specific' && pricing === null)
                                                && <>User Specific<br />No region default</>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </>
                }

            </Table>
            {matchedLeadsHeader && <Table outline="1px solid var(--formBlue1)" oddHighlight={true} width="100%" >
                <TableRow className="tableHeader" isHeader={true}>
                    {
                        Object.keys(matchedLeadsHeader).map(it => {
                            const isGroup = matchedLeadsHeader[it]['title'] === undefined
                            return (
                                <TableCell userSelect="all" className={matchedLeadsHeader[it]['className']}>
                                    {!isGroup && (matchedLeadsHeader[it]['title'] ? matchedLeadsHeader[it]['title'] : '-')}
                                    {isGroup && (
                                        <>
                                            {Object.keys(matchedLeadsHeader[it].fields).map((it2, index) => {
                                                const { fields } = matchedLeadsHeader[it]
                                                //: { [it2]: { title } }
                                                let title = ''
                                                if (fields[it2])
                                                    title = fields[it2].title
                                                if (index === 0)
                                                    return (<>
                                                        <Flexbox gap="2px" userSelect="all">
                                                            {title}
                                                        </Flexbox>
                                                    </>)
                                                else
                                                    return (<>
                                                        <Flexbox gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                                            {title}
                                                        </Flexbox>
                                                    </>)
                                            })}
                                        </>)}
                                </TableCell>
                            )
                        })
                    }
                </TableRow>
                {
                    matchedLeads && matchedLeads.map(lead => {
                        return (
                            <TableRow key={lead.id} color={!lead.city || !lead.city.isValid ? '#b30000' : undefined}>
                                {
                                    Object.keys(matchedLeadsHeader).map(it => {
                                        const isGroup = matchedLeadsHeader[it]['title'] === undefined
                                        const value = typeof (lead[it]) === 'object' ? lead[it].symbol || lead[it].value || '-' : lead[it]
                                        return (
                                            <TableCell userSelect="all" className={matchedLeadsHeader[it]['className']}>
                                                {!isGroup && (
                                                    <span title={typeof (lead[it]) === 'object' ? lead[it].value ? lead[it].value : '-' : lead[it]}>{value}</span>
                                                )}
                                                {isGroup && (
                                                    <>
                                                        {Object.keys(matchedLeadsHeader[it].fields).map((it2, index) => {
                                                            const value = lead[it2] === null ? "-" : typeof (lead[it2]) === 'object' ? lead[it2].symbol || lead[it2].value || '-' : lead[it2]
                                                            if (index === 0)
                                                                return (<>
                                                                    <Flexbox
                                                                        title={lead[it2] === null ? undefined : typeof (lead[it2]) === 'object' ? lead[it2].value ? lead[it2].value : '-' : lead[it2]}
                                                                        gap="2px" userSelect="all">
                                                                        {value}
                                                                    </Flexbox>
                                                                </>)
                                                            else
                                                                return (<>
                                                                    <Flexbox
                                                                        title={lead[it2] === null ? undefined : typeof (lead[it2]) === 'object' ? lead[it2].value ? lead[it2].value : '-' : lead[it2]}
                                                                        gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                                                        {value}
                                                                    </Flexbox>
                                                                </>)
                                                        })}
                                                    </>)}
                                            </TableCell>
                                        )
                                    })
                                }
                                <ActionMenu />
                            </TableRow>)
                    })
                }
            </Table>}

            <Button width="100%" text={type === 'view-attorney-details' ? 'Close' : "Save"} height="50px"
                // disabled={!items.length}
                onClick={handleSubmit} />
        </Modal>
    )
}

export default AttorneysModal