import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import Flexbox from '../Flexbox'

import CloseIcon from '../../images/close.svg'

export const Icon = styled.img`
height: 15px;
width: 15px;
position: relative;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    position: fixed;
    z-index: 5;
    right: 20px;
    top: 20px;
}
`;
export const Icon2 = styled.img`
height: 50px;
width: 50px;

`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: ${({ open }) => open ? '0' : '0 0 0 -250px'};
    flex-basis: 250px;
    flex-shrink: 1;
    background: var(--sideBarBackground);
    height: 100vh;
    overflow: auto;
    padding: 1em 0;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    position: fixed;
    width: ${({ open }) => open ? '100%' : '0'};
    z-index: 2;
}
`;
const Box = styled.div`
position: relative;
z-index: 10;
width: 50px;
height: 50px;
align-items: center;
display: flex;
justify-content: center;
right: 0;
align-self: flex-end;
img, svg {
    height: 20px;
    width: 20px;
}
`;

const SideBar = ({ options, title = 'SideBar', active, optionIsActive }) => {

    const navigate = useNavigate()
    const [open, setOpen] = useState(true)
    return (
        <>
            {!open && <Box onClick={() => setOpen(prev => !prev)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg></Box>}
            <Container open={open}>
                <Flexbox width="100%" padding="0 20px 10px" justifyContent="space-between" className={!open ? "hideOnMobile" : undefined}>
                    {title}
                    <Box onClick={() => setOpen(prev => !prev)} >
                        <Icon2 src={CloseIcon} /></Box>
                </Flexbox>
                {
                    options['category-order'].map(category => {
                        return (
                            <>
                                <Flexbox padding="5px 20px" whiteSpace="nowrap">
                                    {options[category].innerText}
                                </Flexbox>
                                {
                                    options[category]['option-order'].map(_option => {
                                        const option = options[category].options[_option]
                                        return (
                                            <Flexbox
                                                background={optionIsActive(category, option) ?
                                                    'var(--sideBarActiveBackground)'
                                                    : 'transparent'
                                                }
                                                fontSize="13px"
                                                whiteSpace="nowrap"
                                                padding="10px 20px"
                                                width="100%"
                                                onClick={() => { navigate(`/${options[category].path}/${option.path}`, { state: { category, optionId: option.id } }) }}>
                                                {option.innerText}
                                            </Flexbox>)
                                    })
                                }
                            </>
                        )
                    })
                }
            </Container></>
    )
}

export default SideBar