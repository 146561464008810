import React from 'react'
import styled from 'styled-components';

const Button = styled.div`
/* height: 50px; */
width: fit-content;
padding: 0.3em 0.5em;
font-size: 12px;
min-height: 40px;
/* width: 50px; */
background-color: aliceblue;
color: blue;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid blue;
border-radius: 3px;
gap: 0.5em;
cursor: pointer;
`;

const ResetButton = ({ onClick }) => {
    return (
        <Button onClick={onClick}>
            <span>Reset</span>
            <button>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.85355 2.14645C5.04882 2.34171 5.04882 2.65829 4.85355 2.85355L3.70711 4H9C11.4853 4 13.5 6.01472 13.5 8.5C13.5 10.9853 11.4853 13 9 13H5C4.72386 13 4.5 12.7761 4.5 12.5C4.5 12.2239 4.72386 12 5 12H9C10.933 12 12.5 10.433 12.5 8.5C12.5 6.567 10.933 5 9 5H3.70711L4.85355 6.14645C5.04882 6.34171 5.04882 6.65829 4.85355 6.85355C4.65829 7.04882 4.34171 7.04882 4.14645 6.85355L2.14645 4.85355C1.95118 4.65829 1.95118 4.34171 2.14645 4.14645L4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
            </button>
        </Button>
    )
}

export default ResetButton