import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import Flexbox from '../Flexbox'

import close from '../../images/close.svg'

const CloseIcon = styled.img`
height: 15px;
width: 15px;
`;

const Modal = ({ width = "90%", maxWidth = '700px', showModal, setShowModal, children, title = 'Modal', gap="20px", disableCloseOnClickBgd = false, bgd = "rgba(255,255,255,0.75)" , ...props }) => {

    const _setShowModal = (e) => {
        if (typeof (setShowModal) === 'function')
            setShowModal()
    }
    const wrapperRef = useRef(null)

    return (
        <Flexbox zIndex="6" overflow="auto" top="0" left="0" padding="50px" paddingMobile="5px" alignItems="start" justifyContent="center" background={bgd} position="fixed" height="100vh" width="100vw" refID={wrapperRef}  onClick={(e) => {

            if (wrapperRef.current && e.target === wrapperRef.current && !disableCloseOnClickBgd)
                _setShowModal()
        }} >
            <Flexbox gap={gap} column margin="auto 0" alignItems="start" background="#fff" padding="50px" paddingMobile="5px" boxShadow="0 0 5px #b0b0b0" width={width} maxWidth={maxWidth} {...props}>
                <Flexbox justifyContent="space-between" width="100%">
                    <span>{title}</span>
                    <CloseIcon src={close} onClick={() => _setShowModal()} />
                </Flexbox>
                {children}
            </Flexbox>
        </Flexbox>
    )
}

export default Modal