import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
// import states from '../../utils/states'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Tag from '../Tag'
import InputField from '../InputField'
import Flexbox from '../Flexbox'
import Icon from '../Icon'
import TiledInput from '../TiledInput'
import TableCellWithInputField from '../TableCellWithInputField'

import cancel from '../../images/close.svg'
import accept from '../../images/acceptIcon.svg'

import { formatPrice, formatPriceInputField } from '../../utils'

// const RegionLeadPrice = ({ defaultLeadPrice, regionType, regionId, onPriceUpdate }) => {

//     const formatCellValue = (_val) => {

//     }
//     const [price, setPrice] = useState(formatPrice(defaultLeadPrice).replaceAll('$', ''))
//     const [show, setShow] = useState(false)
//     const triggerFocus = useRef(false)

//     const onKeyDown = async (e) => {
//         const { key } = e;
//         console.log("key down event", key)
//         if (key === 'Enter') {
//             console.log("submit")
//             await updatePlace()
//         }
//     }

//     const updatePlace = async () => {
//         console.log("regionType", regionType, regionId, price)
//         const res = await API.updatePlace(regionType, regionId, parseFloat(price.replaceAll(',', '')))
//         onPriceUpdate(regionType, regionId, parseFloat(price.replaceAll(',', '')))
//         setShow(!show)
//     }
//     useEffect(() => {
//         if (show)
//             triggerFocus.current = true
//     }, [show])

//     return (
//         <>

//             <GridItem justify="start" gap="5px">
//                 {
//                     show && <Flexbox zIndex="1" position="fixed" background="none" width="100vw" height="100vh" top="0" right="0" onClick={() => setShow(!show)}></Flexbox>}
//                 <Flexbox flexGrow="1" width="100%" >
//                     {!show &&
//                         <Flexbox
//                             padding="0 26px"
//                             onClick={() => {
//                                 setShow(!show)
//                                 // if (inputRef.current) {
//                                 console.log("current ref")

//                                 // }
//                             }}>
//                             {!show && formatPrice(price)}
//                         </Flexbox>}
//                     <Flexbox
//                         position="relative" zIndex="2" display={show ? 'flex' : 'none'} gap="5px">
//                         <InputField height="2.5em" fontSize="12px" margin="0" onKeyDown={onKeyDown} triggerFocus={show} border="none" padding="0 26px" name="price" value={price} onChange={(e) => setPrice(formatPriceInputField(e.target.value))} />

//                     </Flexbox>
//                 </Flexbox>
//                 {
//                     price !== formatPrice(defaultLeadPrice).replaceAll('$', '') &&
//                     <>
//                         <Icon zIndex="2" src={accept} onClick={async () => await updatePlace()} />
//                         <Icon zIndex="2" src={cancel} onClick={() => setPrice(formatPrice(defaultLeadPrice).replaceAll('$', ''))} />
//                     </>
//                 }
//             </GridItem >
//         </>
//     )
// }

const RegionLeadPrice = ({ defaultLeadPrice, regionType, regionId, onPriceUpdate }) => {

    const formatCellValue = (_val) => formatPrice(_val).replaceAll('$', '')
    const formatCellValueDisplay = (_val) => formatPrice(_val)
    const formatInputFieldValue = (_val) => formatPriceInputField(_val)

    const updatePlace = async (_price) => {
        console.log("regionType", regionType, regionId, _price)
        const res = await API.updatePlace(regionType, regionId, parseFloat(_price.replaceAll(',', '')))
        onPriceUpdate(regionType, regionId, parseFloat(_price.replaceAll(',', '')))

    }

    return (
        <TableCellWithInputField
            minWidth="100px"
            defaultValue={defaultLeadPrice}
            formatCellValueDisplay={formatCellValueDisplay}
            formatInputFieldValue={formatInputFieldValue}
            formatCellValue={formatCellValue}
            onSubmit={updatePlace} />
    )
}

const Regions = () => {
    const [regions, setRegions] = useState({
        states: [],
        cities: [],
        counties: [],
        content: [],
        filter: {
            active: '',
            enum: ['cities', 'states', 'counties', 'none']
        },
        searchTerm: []
    })

    const setSearchTerm = (val) => {
        setRegions(prev => ({
            ...prev,
            searchTerm: [val]
        }))
    }

    const fetchData = async () => {
        const res = await API.getPlaces();
        const { cities: _cities, states: _states, counties: _counties } = res
        setRegions(prev => ({
            ...prev,
            ...res,
            content: _states.concat(_cities.concat(_counties)).sort((a, b) => {
                if (b.highestPayingAttorney.length > 0) {
                    let val = a.defaultLeadPrice
                    if (a.highestPayingAttorney.length > 0)
                        val = a.highestPayingAttorney[0].price > val ? a.highestPayingAttorney[0].price : val

                    return (b.highestPayingAttorney[0].price - val)
                }
                return (b.defaultLeadPrice - a.defaultLeadPrice)
            })
        }))
    }
    useEffect(() => {
        fetchData()
    }, [])

    const onPriceUpdate = (regionType, id, price) => {
        let { content } = regions
        const indexOfUpdate = content.findIndex(item => item.regionType === regionType && item._id === id)
        const element = { ...content[indexOfUpdate], defaultLeadPrice: price }
        content.splice(indexOfUpdate, 1, element)
        setRegions(prev => ({
            ...prev,
            content
        }))
    }

    const { states, counties, cities, content } = regions

    const getBgd = (type) => {
        switch (type) {
            case 'State':
                return 'var(--tagBgdState)'
            case 'County':
                return 'var(--tagBgdCounty)'
            case 'City':
                return 'var(--tagBgdCity)'
            default:
                return '#fff';
        }

    }

    const validatorAddItem = (trimmedInput, items) => {

        if (!items.find(it => it.id === trimmedInput)) {
            //setCounties
            return true
        }
        return false
    }

    const getSuggestions = () => {

    }

    return (
        <Flexbox column height="100vh" flexGrow="1" overflow="auto" >
            <TiledInput
                // onFocus={onFocus}
                width="100%"
                maxWidth="1000px"
                className="geographicArea"
                validatorAddItem={validatorAddItem}
                placeHolder={"County, State"}
                items={regions.searchTerm}
                setItems={setSearchTerm}
                tagOnClick={
                    function (_tag) {
                        console.log("_tag", _tag)
                        setSearchTerm(_tag)
                    }}
                // validatorRemoveItem={validatorRemoveItem}
                formatSelection={(sel) => sel}
                getSuggestions={getSuggestions} />
            <GridContainer
                fontSize="12px"
                align="center"
                alignContent="start"
                justify="center"
                justifyItems="center"
                border="1px solid #8f8f8f"
                padding="50px"
                itemsPerRow="6"
                className="isTable"
                gridTemp={{ columns: 'min-content 1fr min-content min-content repeat(2, 1fr)' }}
                itemPadding="20px 10px"
                nthHighlight=""
            >
                <GridItem>Type</GridItem>
                <GridItem justify="start">Place</GridItem>
                <GridItem justify="start" textAlign="left">Default Lead Price</GridItem>
                <GridItem>Highest Price/Lead</GridItem>
                <GridItem># Appearances in Form Submissions</GridItem>
                <GridItem># Attorneys with Active Region</GridItem>
                {
                    content.map(state => {
                        console.log("state", state)
                        let highestPayingAttorney = null
                        if (state.highestPayingAttorney.length > 0)
                            highestPayingAttorney = state.highestPayingAttorney[0]
                        return (<>
                            <GridItem><Tag name={state.regionType} hideButton={true} background={getBgd(state.regionType)} /></GridItem>
                            <GridItem justify="start">{`${state.name}${state.regionType !== 'State' ? ', ' + state.ste_name : ''}`}</GridItem>
                            <RegionLeadPrice defaultLeadPrice={state.defaultLeadPrice} regionType={state.regionType} regionId={state._id} fetchData={fetchData} onPriceUpdate={onPriceUpdate} />
                            <GridItem>{
                                highestPayingAttorney !== null && formatPrice(highestPayingAttorney['price'])
                            }</GridItem>
                            <GridItem>x</GridItem>
                            <GridItem>{state['nbHitsActive']}</GridItem>
                        </>)
                    })
                }
            </GridContainer>
        </Flexbox>

    )
}

export default Regions