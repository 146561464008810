import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog"
import './AlertDialog.css';
import { Button } from 'primereact/button';
const AlertDialog = ({ onClick = () => { }, description = 'xx', buttonText = "Delete", itemName = 'Remove' }) => {
    // const [data, setData] = useState({})


    return (
        <>
            <AlertDialogPrimitive.Root
                
                maxWidth={"900px"}
                triggerProps={{ color: '#fff', bgd: 'green' }}
                triggerButtonText='Remove'
                title={"Remove Lead"}
                buttonText={buttonText}
            // buttonText={`Yes, Send Lead to ${attorney?.firmName}`}
            >
                <AlertDialogPrimitive.Trigger asChild>
                    <button className="Button red" maxWidth={"200px"} style={{maxWidth: '200px'}}>Delete Auto Config</button>
                </AlertDialogPrimitive.Trigger>
                <AlertDialogPrimitive.Portal>
                    <AlertDialogPrimitive.Overlay className="AlertDialogOverlay" />
                    <AlertDialogPrimitive.Content className="AlertDialogContent">
                        <AlertDialogPrimitive.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialogPrimitive.Title>
                        <AlertDialogPrimitive.Description className="AlertDialogDescription">
                            {description}
                        </AlertDialogPrimitive.Description>
                        <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                            <AlertDialogPrimitive.Cancel asChild>
                                <button className="Button cancel">Cancel</button>
                            </AlertDialogPrimitive.Cancel>
                            <AlertDialogPrimitive.Action asChild onClick={onClick}>
                                <button className="Button red">Yes, delete {itemName}</button>
                            </AlertDialogPrimitive.Action>
                        </div>
                    </AlertDialogPrimitive.Content>
                </AlertDialogPrimitive.Portal>
            </AlertDialogPrimitive.Root>
        </>)
}

export default AlertDialog