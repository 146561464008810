import React, { useState } from 'react';
import * as Select from '@radix-ui/react-select';
import classnames from 'classnames';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
// import './styles.css';
import { Label } from './InputField'
import styled from 'styled-components';
import { nanoid } from 'nanoid';
const GridContainer = styled.div`
display: grid;
position: relative;
height: auto;
width: 100%;
/* width: 100%; */
grid-template-areas: 
"label valid"
    "field field";
    grid-template-columns: auto min-content;
    align-items: center;
    gap: 0.25em;
`;

const SelectItemStyled = styled(Select.Item)`


&.SelectItem {
    font-size: 13px;
    line-height: 1;
    color: var(--formBlue1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
}

&.SelectItem[data-disabled] {
    color: #575555;
    pointer-events: none;
}
&.SelectItem[data-highlighted] {
    outline: none;
    background-color: var(--formBlue1);
    color: #fff;
}
`;

const SelectLabel = styled(Select.Label)`
&.SelectLabel {
    padding: 0 25px;
    font-size: 12px;
    line-height: 25px;
    color: var(--darkGrey);
    }

`;

export const Trigger = styled(Select.Trigger)`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1;
    height: 49px;
    gap: 5px;
    border: 1px solid var(--formBlue1);
    grid-area: field;
    background-color: white;
    color: var(--formBlue1);
    box-shadow: 0 0 4px rgba(var(--formBlue2Raw),0.95);
    margin: ${({ margin }) => margin};
    width: 100%;
    min-width: 250px;
    max-width: ${({ maxWidth }) => maxWidth};
    &:hover {
        /* background-color: var(--mauve3); */
    }
    &:focus {
        box-shadow: 0 0 0 2px black;
    }
    &[data-placeholder] {
        /* color: var(--formBlue1); */
    }
    
`;
const SelectContent = styled(Select.Content)`
    /* overflow: hidden; */
    position: relative;
    display: flex;
    background-color: #ffffff;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border-radius: 6px;
    color: var(--formBlue1);
    max-width: 600px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    z-index: 100;
`;
const RadixDropdown = ({ displayKey = 'innerText', valueIsSelectedKey = 'value', label, labelTextAlign, showLabel = false, hideHeader = false, margin, placeholder = "Select...", options, onValueChange = () => { }, value, displayValue = value,
    showValidationCheckmark = false, ...props }) => {
    const [open, setOpen] = useState(false)
    return (
        <GridContainer alignItems="center" column>
            {showLabel && <Label htmlFor={props['name']}
                label={label}
                labelTextAlign={labelTextAlign}
                {...props} />}

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select.Root
                    value={value}
                    onValueChange={(v) => {
                        let selected =
                            Array.isArray(options) ? options :
                                Object.keys(options).map(option => {
                                    return (options[option]?.options)
                                    // return (options[option]?.options.find(item => item.value === v))
                                })
                        selected = selected.flat().find(item => item[valueIsSelectedKey] === v)
                        // let options
                        if (selected)
                            onValueChange(selected)
                    }}
                    open={open}
                    name={props['name'] || nanoid()}
                    onOpenChange={() => setOpen(prev => !prev)}
                    {...props}
                >
                    <Trigger className="SelectTrigger" aria-label={displayValue}>
                        <Select.Value aria-label={displayValue} placeholder={placeholder}>
                            {displayValue}
                        </Select.Value>
                        <Select.Icon className="SelectIcon">
                            <ChevronDownIcon />
                        </Select.Icon>
                    </Trigger>
                    <Select.Portal>
                        <SelectContent className="SelectContent" position="popper">
                            <Select.ScrollUpButton className="SelectScrollButton">
                                <ChevronUpIcon />
                            </Select.ScrollUpButton>
                            <Select.Viewport className="SelectViewport">

                                {
                                    Array.isArray(options) && <Select.Group>{
                                        options.map(option => {
                                            const { innerText, id } = option
                                            let itemKey = option[valueIsSelectedKey] || id
                                            let displayText = option[displayKey]
                                            return (

                                                <SelectItem value={itemKey} key={itemKey}>{displayText}</SelectItem>

                                            )
                                        })
                                    }</Select.Group>
                                }
                                {
                                    typeof (options) === 'object' &&
                                    Object.keys(options).map((optionKey, index) => {
                                        let { groupLabel, options: _catOptions } = options[optionKey]
                                        if (!_catOptions)
                                            return <React.Fragment key={`${props['name'] || nanoid()}-${index}`}></React.Fragment>
                                        return (<React.Fragment key={index}>
                                            <Select.Group key={`${props['name']}-${groupLabel}`}>
                                                {groupLabel && <Select.Label className="SelectLabel" key={`${props['name']}-${groupLabel}-SelectLabel`}>{groupLabel}</Select.Label>}
                                                {
                                                    _catOptions.map(it => {
                                                        let item = typeof (it) === 'string' ? it : it['innerText']
                                                        let itemKey = it[valueIsSelectedKey]
                                                        let displayText = it[displayKey]
                                                        return (<SelectItem value={itemKey} key={itemKey}>{displayText}</SelectItem>)
                                                    })
                                                }
                                            </Select.Group>
                                            <Select.Separator className="SelectSeparator" key={`${groupLabel}-separator`} />
                                        </React.Fragment>)
                                    })
                                }
                            </Select.Viewport>
                            <Select.ScrollDownButton className="SelectScrollButton">
                                <ChevronDownIcon />
                            </Select.ScrollDownButton>
                        </SelectContent>
                    </Select.Portal>
                </Select.Root>
            </div>
        </GridContainer>
    )
};

const SelectItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <SelectItemStyled className={classnames('SelectItem', className)} {...props} ref={forwardedRef}>
            <Select.ItemText>{children}</Select.ItemText>
            <Select.ItemIndicator className="SelectItemIndicator">
                <CheckIcon />
            </Select.ItemIndicator>
        </SelectItemStyled>
    );
});

export default RadixDropdown;
