import React, { useEffect, useState, useRef } from 'react'
import InputField from './InputField'
import PageContentContainer from './PageContentContainer'
import API from '../API'

import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
// import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/saga-green/theme.css"
import dayjs from 'dayjs'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog as PrimeDialog } from 'primereact/dialog';
import { Button as PrimeButton } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
// dayjs().format()
import 'primeicons/primeicons.css';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import styled from 'styled-components'
import { useAuth } from '../contexts/AuthContext'
import TextArea from './TextArea';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: 'now',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1M",
        MM: "%dM",
        y: "a year",
        yy: "%d years"
    }
})
const statusBodyTemplate = (dispute, fieldKey, tagOverrideField = null) => {
    return <Tag value={dispute[fieldKey]} severity={getSeverity(dispute, fieldKey, tagOverrideField)}></Tag>;
};

const H3 = styled.h3`
color: var(--formBlue1);
`;

const getSeverity = (dispute, fieldKey, tagOverrideField = null) => {
    switch (typeof (dispute) === 'string' ? dispute : dispute[tagOverrideField ? tagOverrideField : fieldKey]) {
        case 'ACCEPTED':
            return 'success';

        case 'PENDING':
            return 'warning';

        case 'REJECTED':
            return 'danger';
        case 'State':
            return 'info';

        case 'County':
            return 'info';

        case 'City':
            return 'info';
        default:
            return null;
    }
};

const AcceptedDisputeDialog = ({ status, adminNotes }) => {
    return (
        <>
            <H3>Admin</H3>
            <h4>Status</h4>
            <p>{status || '-'}</p>
            <h4>Notes</h4>
            <p>{adminNotes || '-'}</p>
        </>)
}

const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
};

const GridContainer = styled.div`
display: grid;
grid-template: auto / 1fr;
gap: 5px;
background-color: aliceblue;
`;

const PendingDisputeDialog = ({ defaultDisputeResponses, statuses, _id, getData }) => {
    const [disputeUpdate, setDisputeUpdate] = useState({
        status: 'PENDING',
        adminNotes: '',
        selectedResponse: ''
    })
    const [serverRes, setServerRes] = useState({
        didSubmit: false,
        success: false,
        msg: ''
    })
    const { status, adminNotes, selectedResponse } = disputeUpdate
    const { didSubmit, success, msg } = serverRes
    // defaultDisputeResponses
    const onChange = (field, value) => setDisputeUpdate(prev => ({ ...prev, [field]: value }))
    return (
        <GridContainer gap="5px">
            <H3>Admin</H3>
            <h4>Status</h4>
            <Dropdown value={status} options={statuses}
                onChange={(e) => onChange("status", e.value)}
                itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
            {
                status === 'REJECTED' &&
                <>
                <h4>Default Responses</h4>
                    <Dropdown value={selectedResponse} options={defaultDisputeResponses.map(it => it['name'])}
                        onChange={(e) => {
                            let note = defaultDisputeResponses.find(it => it['name'] === e.value)
                            setDisputeUpdate(prev => ({ ...prev, selectedResponse: e.value }))
                            console.log("note",note)
                            if (note) {
                                note = note.note
                                onChange("adminNotes", note)
                            }
                        }}
                        itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
                        <h4>Admin Notes</h4>
                    <TextArea onChange={(e) => onChange("adminNotes", e.target.value)} value={adminNotes} /></>
            }
            <Button
                style={{ width: '100%', marginTop: '10px' }}
                disabled={status === 'PENDING'} label="Submit" onClick={async () => {
                    let res = await API.processDispute({ adminNotes, status, disputeId: _id })
                    // console.log("res",res)
                    setServerRes(prev => ({ ...prev, didSubmit: true, success: res['success'], msg: res['msg'] || 'Unknown' }))
                    if (res['success'])
                        await getData()
                }} />
            {
                didSubmit && <p>{msg}</p>
            }
        </GridContainer>)
}

const DisputeDialogBody = (dispute) => {
    const { shortId, reasonForDispute, comments, status, updatedAt, lead: { email, name, updatedAt: updatedAtLead, phoneNumber } } = dispute
    return (<>
        <H3>Dispute</H3>
        <h4>Dispute Id</h4>
        <p>{shortId}</p>
        <h4>Submitted</h4>
        <p>{getDate('createdAt')(dispute)}</p>
        <h4>Reason</h4>
        <p>{reasonForDispute['name']}</p>
        <h4>Dispute Comments</h4>
        <p>{comments}</p>
        <h4>Updated</h4>
        <p>{getDate('updatedAt')(dispute)}</p>


        {
            status !== "PENDING" && <AcceptedDisputeDialog {...dispute} />
        }
        <H3>Lead</H3>
        <h4>Name</h4>
        <p>{name}</p>
        <h4>Phone</h4>
        <p>{phoneNumber}</p>
        <h4>Email</h4>
        <p>{email}</p>
        <h4>Lead Received</h4>
        <p>{getDate('lead.updatedAt')(dispute)}</p>
        {
            status === "PENDING" && <PendingDisputeDialog {...dispute} />
        }
    </>)
}

const buttonBodyTemplate = (setDialogue) => (dispute) => {
    if (dispute.status === 'PENDING')
        return <></>
    return (<div><PrimeButton id="infoButton" icon="pi pi-info" rounded severity="help" aria-label="Info" onClick={() => setDialogue(true, <DisputeDialogBody {...dispute} />)} /></div>)
    return <PrimeButton onClick={() => setDialogue(true, dispute['adminNotes'] || '-')} className="pi pi-info" rounded aria-label="Info" />;
};

const Dialog = ({ visible, setVisible, text }) => {
    return (<PrimeDialog header="Details" visible={visible} style={{ width: '50vw' }} position="bottom" onHide={() => setVisible(false)}>
        {/* <p className="m-0"> */}
        {text}
        {/* </p> */}
    </PrimeDialog>)
}
/*
const buttonBodyTemplate = (dialogue, setDialogue) => (dispute) => {
    if (dispute.status === 'PENDING')
        return <></>
    return <RadixDialog {...dialogue} dialogTrigger={<PrimeButton onClick={() => setDialogue(true, dispute['adminNotes'] || '-')} className="pi pi-info-circle" rounded outlined aria-label="Info" />} />
    // return;
};
*/


const getColumn = (dispute) => {
    switch (dispute.type) {
        case 'ACCEPTED':
            return 'success';

        case 'PENDING':
            return 'warning';

        case 'REJECTED':
            return 'danger';

        default:
            return null;
    }
};

String.prototype.padLeft = function (length, character) {
    return new Array(length - this.length + 1).join(character || ' ') + this;
};

Date.prototype.toFormattedString = function () {
    return [String(this.getMonth() + 1).padLeft(2, '0'),
    String(this.getDate()).padLeft(2, '0'),
    String(this.getFullYear()).slice(-2)].join("/") + " " +
        [String(this.getHours()).padLeft(2, '0'),
        String(this.getMinutes()).padLeft(2, '0')].join(":");
};

const getObjDeeperLayer = (a, c) => {
    if (typeof (a) !== 'object' || a === null)
        return a
    let b = c.split('.')
    return (b.reduce((_a, _b) => {
        return (_a[_b])
    }, a))
}

const dateFormatByType = (date, dateType) => {
    switch (dateType) {
        case "FROM-NOW":
            return dayjs(date).fromNow()
        default:
            return dayjs(date).format('ddd, MMM DD')
    }
}

const getDate = (fieldKey, dateType = null) => (dispute) => {
    let date = getObjDeeperLayer(dispute, fieldKey)
    if (!date)
        return (<></>)
    return (dateFormatByType(date, dateType))
}

const getTag = (tagType, fieldKey, tagOverrideField = null) => (dispute) => {
    return statusBodyTemplate(dispute, fieldKey, tagOverrideField)
}

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

const DisputesTable = ({ _active }) => {
    const { currentUser, currentUser: { user } } = useAuth()
    const initialRef = useRef(true)
    const [data, setData] = useState({
        page: 1,
        perPage: 1,
        totalRecords: 0,
        header: {},
        disputes: [],
        defaultDisputeResponses: [],
        dialogue: {
            visible: false,
            text: ""
        }
    })

    const [statuses] = useState(['ACCEPTED', 'PENDING', 'REJECTED',])
    const { defaultDisputeResponses, totalRecords, page, perPage, header, disputes, dialogue: { text }, dialogue } = data
    const [visible, setVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [filters, setFilters] = useState({
        "attorney.firmName": { value: null, matchMode: FilterMatchMode.EQUALS },
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const getData = async (_refine = { [_active.filterAccessor]: _active.value }) => {
        try {
            const res = await API.getDisputes(_refine)
            const { result, header, defaultDisputeResponses } = res
            if (initialRef.current) {
                initialRef.current = false
                setData(prev => ({
                    ...prev,
                    defaultDisputeResponses,
                    header
                }))
            }
            setCustomers(result)
            setLoading(false)
        } catch (error) {

        }
    }
    const onRowSelect = (event) => {
        console.log("row select")
        setDialogue(true, <DisputeDialogBody defaultDisputeResponses={defaultDisputeResponses} statuses={statuses} getData={getData} {...event.data} />)
    };

    const onRowUnselect = (event) => {
        console.log("row unselect")
        setDialogue(false, <></>)
    };

    const [customers, setCustomers] = useState([])
    useEffect(() => {

        getData()
        // DisputeService.getCustomers().then((data) => {
        //     const { result } = data
        //     console.log("data", data, result)
        //     setCustomers(result)
        // });

    }, [])

    // const setVisible = (visibility) => {
    //     console.log("setting visibility to:", visibility)
    //     setData(prev => ({
    //         ...prev,
    //         dialogue: {
    //             ...prev.dialogue,
    //             visible: visibility ? visibility : !prev.visible
    //         }
    //     }))
    // }
    const setDialogue = (visibility, text) => {
        setData(prev => ({
            ...prev,
            dialogue: {
                visible: visibility,
                text
            }
        }))
        setVisible(visibility)
    }

    const statusRowFilterTemplate = (_statuses) => (options) => {

        return (
            <Dropdown value={options.value} options={_statuses}
                onChange={(e) => {
                    options.filterApplyCallback(e.value)
                }}
                itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '6rem' }} />
        );
    };

    // const renderHeader = () => {
    //     return (
    //         <div className="flex justify-content-end">
    //             <span className="p-input-icon-left">
    //                 <i className="pi pi-search" />
    //                 <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
    //             </span>
    //         </div>
    //     );
    // };
    // const _header = renderHeader();
    return (
        <>
            <PageContentContainer margin="calc(var(--navBarHeight) * -1) 0 0" column background="var(--formBackground)" padding="50px 0 0" maxHeight="100%" height="100vh" justifyContent="center">
                <div className='card' style={{ width: '95%', position: 'relative' }}>
                    <h1 style={{ textAlign: 'left' }}>Hello, {user.displayName}.</h1>
                    <DataTable
                        // sortField="updatedAt" sortOrder={-1}
                        paginator rows={5}
                        scrollable
                        metaKeySelection={false}
                        value={customers} tableStyle={{ minWidth: '50rem', borderSpacing: '0px' }} stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        filterDisplay="row"
                        filters={filters}
                        // header={_header}
                        loading={loading}
                        // globalFilterFields={['status']}
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        // paginator rows={2} rowsPerPageOptions={[5, 10, 25, 50]}
                        selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="_id"
                        onRowSelect={onRowSelect} onRowUnselect={onRowUnselect}>
                        {
                            Object.keys(header).map(headerKey => {
                                const { sortable, title, type, tagType, tagOverrideField, filter, filters } = header[headerKey]
                                let additionalFilter = { key: title }
                                if (filter) {
                                    additionalFilter.filter = true
                                    additionalFilter.showFilterMenu = false
                                    additionalFilter.filterMenuStyle = { width: '14rem' }
                                    if (filters)
                                        additionalFilter.filterElement = statusRowFilterTemplate(filters.map(it => typeof (it) === 'string' ? it : it._id))
                                    // additionalFilter.filterElement = statusRowFilterTemplate
                                    // if (title === 'Status')
                                    //     additionalFilter.statusRowFilterTemplate = statusRowFilterTemplate
                                }
                                if (type === 'TAG' && tagType)
                                    return <Column field={headerKey} header={title} body={getTag(tagType, headerKey, tagOverrideField)} {...additionalFilter} ></Column>
                                if (type === 'DATE') {
                                    return <Column field={headerKey} header={title} body={getDate(headerKey, header[headerKey]['dateType'])} {...additionalFilter} ></Column>
                                }
                                return (<Column field={headerKey} header={title} {...additionalFilter} />)
                            })
                        }
                        {/* <Column header="Details" body={buttonBodyTemplate(setDialogue)} textAlign="center" /> */}

                    </DataTable>
                </div>
                <Dialog {...dialogue} setVisible={setVisible} visible={visible} getData={getData} defaultDisputeResponses={defaultDisputeResponses} />
            </PageContentContainer>

        </>
    )
}

export default DisputesTable