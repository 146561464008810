import React from 'react';
import * as Dialog from '@radix-ui/react-alert-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

const DialogTrigger = styled(Dialog.Trigger)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
    background: ${({ bgd = '#4d0026' }) => bgd};
    color: ${({ color = '#fff' }) => color};
`;
const DialogOverlay = styled(Dialog.Overlay)`
    background-color: black;
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 1;
    opacity: 0.9;
`;
const DialogContent = styled(Dialog.Content)`
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: ${({ maxWidth = '450px' }) => maxWidth};
    max-height: 85vh;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 1;
    @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
        max-height: unset;
    }
&:focus {
    outline: none;
}
.IconButton{
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--violet-11);
    position: absolute;
    top: 10px;
    right: 10px;

}
.Button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
    /* background: #4d0026; */
    /* color: white; */
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
}
.Button.Cancel{
    background: #c5c5c5;
    color: #727272; 
    border-color: #727272;
}

`;

const DialogAction = styled(Dialog.Action)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
    background: #fb000081;
    color: #9f0000;
    user-select: none;
    cursor: pointer;
    border-color: #9f0000;
    border-width: 1px;
    border-style: solid;
`;

const DialogTitle = styled(Dialog.Title)`
    margin: 0;
    font-weight: 500;
    color: #4d0026;
    font-size: 17px;

`;
const DialogDescription = styled(Dialog.Description)`
    margin: 10px 0 20px;
    color: #38001e;
    font-size: 15px;
    line-height: 1.5;

`;


const RadixDialog = ({ onClick, children, desc, title, triggerButtonText = 'Open', buttonText = 'Save', triggerProps = {}, maxWidth }) => (
    <Dialog.Root>
        <DialogTrigger asChild {...triggerProps}>
            <button className="Button violet">{triggerButtonText}</button>
        </DialogTrigger>
        <Dialog.Portal>
            <DialogOverlay className="DialogOverlay" />
            <DialogContent className="DialogContent" maxWidth={maxWidth}>
                <DialogTitle className="DialogTitle">{title}</DialogTitle>
                {desc && <DialogDescription className="DialogDescription">
                    {desc}
                </DialogDescription>}
                {/* <fieldset className="Fieldset">
                    <label className="Label" htmlFor="name">
                        Name
                    </label>
                    <input className="Input" id="name" defaultValue="Pedro Duarte" />
                </fieldset>
                <fieldset className="Fieldset">
                    <label className="Label" htmlFor="username">
                        Username
                    </label>
                    <input className="Input" id="username" defaultValue="@peduarte" />
                </fieldset> */}
                {children}
                <div style={{ marginTop: '20px', display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                    <Dialog.Cancel asChild>
                        <button className="Button Cancel">Cancel</button>
                    </Dialog.Cancel>
                    <DialogAction asChild>
                        <button className="ActionButton" onClick={onClick}>{buttonText}</button>
                    </DialogAction>
                </div>
                <Dialog.Cancel asChild>
                    <button className="IconButton" aria-label="Cancel">
                        <Cross2Icon />
                    </button>
                </Dialog.Cancel>
            </DialogContent>
        </Dialog.Portal>
    </Dialog.Root>
);

export default RadixDialog;
