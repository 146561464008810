import React from 'react'

import Flexbox from './Flexbox'

const PageContentContainer = ({ children, ...props }) => {
    return (
        <Flexbox
            width="100%"
            row
            marginTop="var(--navBarHeight)"
            flexDirection="column"
            overflow="hidden auto"
            height="calc(100vh - var(--navBarHeight))"
            {...props}>
            {children}
        </Flexbox>
    )
}

export default PageContentContainer