import React, { useEffect } from 'react'
import { useSearchParams, useLocation, useParams } from 'react-router-dom'
import API from '../API'

const ProcessDispute = () => {
    const params = useParams()
    const location = useLocation()
    let { search } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    console.log("search", search)
    console.log("searchParams", searchParams)
    console.log("location", params)
    useEffect(() => {

        const verifyLogin = async () => {
            if (searchParams) {
                let queryObj = Object.fromEntries(searchParams)
                const { token, action, leadId, disputeId } = queryObj
                if (token && action === 'ACCEPTED')
                    await API.account({token, action, leadId, disputeId})
            }
        }
        verifyLogin()
    }, [])
    return (
        <div>ProcessDispute</div>
    )
}

export default ProcessDispute