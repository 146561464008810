import React, { useState, useEffect } from 'react';
import API from '../API'
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Popover, PopoverTrigger, PopoverContent } from '@radix-ui/react-popover';
import { useNavigate } from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const GeographicRegionsTable = () => {
    const [regions, setRegions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getGeoRegions() {
            try {
                let response = await API.getGeographicRegions()
                setRegions(response.data);
            } catch (error) {
                console.error('Error fetching geographic regions:', error);
            }
        }
        getGeoRegions()
    }, []);

    const renderRegionNames = (rowData) => {
        if (rowData.regionIds && rowData.regionIds.length > 0) {
            const firstRegion = rowData.regionIds[0];
            return (
                <Popover>
                    <PopoverTrigger asChild>
                        <Button label={firstRegion.regionName} />
                    </PopoverTrigger>
                    <PopoverContent>
                        <ul>
                            {rowData.regionIds.map((region, index) => (
                                <li key={index}>{region.regionName}</li>
                            ))}
                        </ul>
                    </PopoverContent>
                </Popover>
            );
        } else if (rowData.regionId) {
            return rowData.regionId.name;
        }
        return null;
    };

    const handleRowClick = (rowData) => {
        console.log("rowData:::",rowData)
        navigate(`/geographic-region/${rowData._id}`);
    };

    return (
        <div className="geographic-regions-table">
            <h2>Geographic Regions</h2>
            <DataTable value={regions} onRowClick={(e) => handleRowClick(e.data)}>
                <Column field="attorneyId.firmName" header="Attorney Firm Name" />
                <Column field="practiceAreaId.name" header="Practice Area Name" />
                <Column header="Pricing">
                    {(rowData) => (
                        <>
                            <div>{rowData.price}</div>
                            <div>{rowData.pricingType}</div>
                        </>
                    )}
                </Column>
                <Column field="regionalBudget" header="Regional Budget" />
                <Column header="Region Names" body={renderRegionNames} />
            </DataTable>
        </div>
    );
};

export default GeographicRegionsTable;
