import { createGlobalStyle } from 'styled-components';

const hexToRgbA = (hex, alpha = 1) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
}


export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --navBarHeight: 60px;
        --defaultContainerHeight: calc(100vh - var(--navBarHeight));
        --background: #fff;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --primary: #000;
        --red:#b30000;
        --fontColor: #000;
        --activeTab: var(--fontColor);
        --inactiveTab: var(--secondary);
        --secondary: #919191;
        --black: #000;
        --red: #9e0000;
        --green: #03cc00;
        --formBlue1: #386aff;
        --formBlue2: rgb(27, 134, 246);
        --defaultBorder: 1px solid var(--black);
        --borderMediumThick: 2px solid var(--primary);
        --defaultFont: 'Albert Sans', sans-serif;
        --formColor: #ffffff;
        --defaultPagePadding: 0 50px;
        --tagBgdState: #fdca91;
        --tagBgdCity: #91c5fd;
        --tagBgdCounty: #91fdc9;
        --formBackground: linear-gradient(var(--formBlue1), var(--formBlue2));
        --formBackgroundAngled: linear-gradient(45deg,var(--formBlue1), var(--formBlue2));
        --buttonDisabledBgd: #d6d6d6;
        --buttonDisabledText: #999999;
        --buttonDisabledBorder: 2px solid #999999;
        --formSelectedTileColor: #fff;
        --greyBackground: #262626;
        --greyBackground: #262626;
        --buttonFontSize: 13px;
        --dropdownLetterSpacing: '';
        --dropdownBoxShadow: none;
        --dropdownBackground: rgba(255,255,255,0.90);
        --dropdownBorder: var(--defaultBorder);
        --dropdownTextTransform: none;
        --dropdownHoverBackground: #e8e8e8; // 
        --nthRowBackground: #e8e8e8; // 
        --tableRowHover: rgb(178, 216, 255);
        --dropdownSelectedItemBackground: var(--formBlue2);
        --dropdownColor: var(--formBlue1);
        --inputBoxShadow: none;
        --inputBackground: rgba(255,255,255,0.25);
        --inputPlaceHolderColor: #606b76;
        --inputColor: #fff;
        --inputBackground: transparent;
        --inputBorder: var(--defaultBorder);
        --inputFocusOutline: var(--defaultBorder);
        --inputFocusBorder: var(--defaultBorder);
        --inputLabelFontSize: 0.7rem;
        --checkboxColor: var(--primary);
        --checkboxCheck: 1px solid var(--formBlue2);
        --checkboxBoxShadow: none;
        --checkboxBorder: var(--inputBorder);
        --defaultButtonFontWeight: 700;
        --buttonfontWeightHover: 700;
        --defaultButtonLetterSpacing: 0.03em;
        --defaultButtonPadding: 20px 30px; 
        --defaultButtonBackground: #386aff;
        --buttonDisabledBackground: #c2c2c2;
        --defaultButtonColor: #ffffff;
        --defaultButtonBackgroundHover: #99c5ff;
        --defaultButtonColorHover: #386aff;
        --defaultButtonBorderHover: 2px solid #386aff;
        --defaultButtonBorder: 2px solid var(--defaultButtonBackground);
        --fontMicro: 10px;
        --navBarPadding: 0px 50px;
        --navBarBoxShadow: 0 0 5px #000;
        --navBarFontWeightActive: 700;
        --fontSmall: 11px;
        --headerWeight: 500;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --fontNormal: 1.0em;
        --bold: 700;
        --medium: 500;
        --boldSmall: 600;
        --boldSmallLetterSpacing: 0.07rem;
        /* --buttonHeight: 56px; */
        --buttonBorder: 1px solid #000;
        --purpleGrey: #696980;
        --defaultInputHeight: 49px;
        --sideBarBackground: #99daff;
        --sideBarActiveBackground: rgba(255,255,255,0.5);
    }
    
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    .hideOnMobile{
        display: none !important;
        visibility: hidden !important;
        width: 0 !important;
        height: 0 !important;
    }

}
@media only screen and (min-width: 750px), screen and (min-device-width: 750px) {
    .hideOnDesktop{
        display: none !important;
        visibility: hidden !important;
        width: 0 !important;
        height: 0 !important;
    } 

}

    * {
        box-sizing: border-box;
        font-family: var(--defaultFont);
    }
    #root{
        max-width: 100%;
        height: 100%;
    }
    .nowrap{
        white-space: nowrap;
    }
    .gradientText{
        background: var(--formBackgroundAngled);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .textLeft{
        text-align: left;
    }
    .noMargin{
        margin: 0;
    }
    .noSelect td{
        user-select: none;
    }
    .toolTipContainer .toolTip{
        display: none;
    }

    .toolTipContainer:hover .toolTip{
        display: flex;
    }
    @import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  all: unset;
}
.p-dropdown{
  border-radius: 6px;
  padding: 5px;
  border-radius: 6px;
  background-color: white;
}
.p-dropdown-panel {
  margin-top: 5px;
  .p-dropdown-items{
    .p-dropdown-item{
  padding: 1em 0.7em;
}
  }
}

.DropdownMenuPortal{
    z-index: 300 !important;
}
.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  color: var(--violet11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state='open'] {
  background-color: var(--violet4);
  color: var(--violet11);
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve8);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: var(--violet9);
  color: var(--violet1);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve11);
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: var(--violet6);
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
}
.IconButton:hover {
  background-color: var(--violet3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve8);
}
@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 
    .SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  color: #000;
  box-shadow: 0 2px 10px var(--blackA7);
}
.SelectTrigger:hover {
  background-color: var(--mauve3);
}
.SelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
  color: #000;
}

.SelectIcon {
  color: #000;
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: #000;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: #000;
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet9);
  color: #000;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: #000;
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet11);
  cursor: default;
} */

    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--black);
        height: 100%;
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    html{
        margin: 0;
        font-size: 15px;
        font-family: 'Colombia';
        height: 100%;
        background: var(--background);
        /* h1{
            font-size: 3em;
            font-weight: var(--bold);
        } 
        */
        h1{
            user-select: none;
            font-size: 2em;
            font-weight: var(--bold);
        }
        h2{
            user-select: none;
            font-size: 1.5em;
            font-weight: var(--medium);
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            margin: 0;
            
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            /* font-weight: 300; */
            font-size: 1rem;
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;