import React, { useEffect, useState, useRef } from 'react'
import API from '../API'

import { Button } from 'primereact/button';

import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/saga-green/theme.css"
import dayjs from 'dayjs'
// dayjs().format()
import 'primeicons/primeicons.css';
import styled from 'styled-components'
import { useAuth } from '../contexts/AuthContext'
import TextArea from './TextArea';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: 'now',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1M",
        MM: "%dM",
        y: "a year",
        yy: "%d years"
    }
})

export const TimeWindowAdditionWrapper = styled.div`
background-color: #f0fafc;
display: flex;
flex-direction: column;
align-items: center;
border: 1px solid #c3c7cb;
padding-bottom: 20px;
`;

export const DaysWrapper = styled.div`
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-auto-flow: row;
    width: 100%;
    position: relative;
    row-gap: 5px;
    label{
        text-align: left;
    }
`
export const DayLabel = styled.label`
    display: flex;
    color: #434a4f;
    font-size: 1.2rem;
    background-color: rgba(205, 224, 235,50%);
    border: 1px solid #c3c7cb;
    padding: 15px 10px;
    width: 100%;
    max-width: 400px;
    span{
        margin-left: 5px;
    }
`;

const TimeWindowInputContainer = styled.div`
display: grid;
grid-template-columns: auto auto;
grid-auto-flow: row;
grid-template-rows: min-content;
column-gap: 3px;
row-gap: 10px;
/* justify-items: center; */
align-items: center;
    label{
        text-align: left;
        /* margin-right: 5px; */
    }
`;

const AdminNotes = ({ noteCategory }) => {
    const { currentUser, currentUser: { user } } = useAuth()
    const initialRef = useRef(true)
    const [data, setData] = useState({
        isEnabled: true,
        dialogue: {
            visible: false,
            text: ""
        }
    })

    const { dialogue: { text }, dialogue } = data
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(true);

    const [config, setConfig] = useState(null);
    const [note, setAdminNote] = useState('');
    const getData = async () => {
        try {
            const data = await API.getAdminNotes('Lead')
            console.log("data", data)
            setConfig(data.adminNote);
            setAdminNote(data.adminNote.note);
            setLoading(false)
        } catch (error) {

        }
    }

    const updateConfig = async (updatedConfig) => {
        const res = await API.updateAdminNotes(updatedConfig)
        setConfig(res.adminNote);
        setAdminNote(res.adminNote.note);
    };
    const handleUpdateAdminNote = () => {
        const updatedConfig = { ...config, note: note, noteCategory };
        console.log("note", note)
        updateConfig(updatedConfig);
    };

    useEffect(() => {

        getData()

    }, [])
    return (
        <>
            <div className='card'
                style={{ width: '95%', position: 'relative', display: 'grid', gridAutoRows: 'auto', gridAutoFlow: 'row', gridTemplateColumns: '1fr', gap: '10px', padding: '10px', backgroundColor: '#c8e5dd', marginBottom: '20px', justifyItems: 'center' }}>

                <label htmlFor="note">Admin Notes</label>
                <div style={{background: '#f2f2f2', width: '100%'}}><TextArea id="note" value={note} onChange={(e) => setAdminNote(e.target.value)} /></div>

                <Button label="Update Notes" onClick={handleUpdateAdminNote} style={{ marginTop: '20px', maxWidth: '300px' }} />
            </div>
        </>
    )
}

export default AdminNotes