const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2, xNb = null, yNb = null) => {
    let elements;
    elements = document.querySelectorAll(className);
    Array.from(elements).forEach((element) => {
        let BBox = element.getBBox();
        let BBoxX2 = xFactor == 0 ? 0 : xNb ? xNb : BBox["width"] / xFactor;
        let BBoxY2 = yFactor == 0 ? 0 : yNb ? yNb : BBox["height"] / yFactor;
        let BBoxX = xNb ? xNb : BBox["x"] + BBoxX2;
        let BBoxY = yNb ? yNb : BBox["y"] + BBoxY2;
        BBox = BBoxX + "px" + " " + BBoxY + "px";
        element.style.transformOrigin = BBox;
    });
}

const objectIsEmpty = (ob) => Object.keys(ob).length === 0

const formatPrice = (_value) => {
    if (!_value)
        return '-'
    const MAX_DEC_PLACES = 2
    let value = _value.toString()
    let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let decimal; //MAX_DEC_PLACES
    if (value.split('.').length > 1) {
        decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
        price = price + '.' + decimal;
    }
    return '$' + price
}

const formatPriceInputField = (_value) => {
    let value = _value;

    let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let decimal; //MAX_DEC_PLACES
    if (value.split('.').length > 1) {
        decimal = value.split('.')[1].substring(0, 2).replace(/\D/g, "")
        price = price + '.' + decimal;
    }
    return price
}

const parseBoolean = (value) => {
    if (typeof (value) === 'boolean')
        return value
    else if (typeof (value) === 'string')
        return value === 'true'
    else
        return false
}

const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber['phoneNumber'])
        return '-'
    let number = phoneNumber['phoneNumber'].match(/^(\d{3})(\d{3})(\d{4})$/).reduce((a, b, index) => {
        if (index !== 0) {
            if (index === 1)
                a = b
            else
                a = a + '-' + b
        }
        return a
    }, '')
    if (phoneNumber['ext'])
        number = number + ' ext.' + phoneNumber['ext']
    return number
}

const normalizeString = (str) => {
    return str.toLowerCase()
}

const enforceArray = (value) => {
    if (Array.isArray(value)) {
        return value
    }
    else return [value]
}

const findMatchAcrossKeys = (obj, value) => {
    return Object.keys(obj).reduce((a, key) => {
        if (normalizeString(obj[key]).includes(normalizeString(value)))
            return true
        else return a
    }, false)
}

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export { toTitleCase, enforceArray, normalizeString, fixSvgElementCoordinates, formatPrice, formatPhoneNumber, formatPriceInputField, findMatchAcrossKeys, objectIsEmpty, parseBoolean }