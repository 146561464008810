import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import API from '../../API'
import Table from '../Table'
import TableCell from '../TableCell'
import TableRow from '../TableRow'
import Flexbox from '../Flexbox'
import LoadingWheel from '../LoadingWheel'
import DotMenu from '../DotMenu'
import LeadsModal from '../LeadsModal'
import Icon from '../Icon'
import { formatPrice, formatPhoneNumber } from '../../utils'

import TrueIcon from '../../images/acceptIcon.svg'
import Alert from '../../images/alert.svg'
import FalseIcon from '../../images/close.svg'
import { useNavigate } from 'react-router-dom'
import AdminNotes from '../AdminNotes'
import ConfirmationModal from '../ConfirmationModal'

const Tag = styled.div`
padding: 3px;
border: 1px solid #545454;
background: ${({ background = '#fff' }) => background};
border-radius: 5px;
`;

const LeadsTable = ({ _active }) => {
    const navigate = useNavigate()
    console.log("active", _active)
    const [modal, setModal] = useState({
        showModal: false,
        type: '',
        lead: null,
        header: {}
    })
    const [showRemovalModal, setShowRemovalModal] = useState({ shouldShow: false, lead: null })
    const setShowModal = () => {
        setModal(prev => ({ ...prev, showModal: !prev.showModal }))
    }
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        leads: [],
        header: {},
        refine: {
            options: {
                'isActive': {
                    id: 'isActive', value: false, name: 'New',
                    enum: [
                        {
                            value: false, name: 'New',
                            // accessors: [
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            // ]
                        },
                        {
                            value: true, name: 'Active',
                            // accessors: [
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            // ]
                        },
                    ]
                }
            },
            active: {
                'isActive': { id: 'isActive', value: false, name: 'New' }
            },
        }
    })
    const { refine, refine: { active }, leads } = data
    const getLeads = async (_refine = { id: _active.filterAccessor, value: _active.value }, secondary = _active['secondaryAccessors'] ? _active['secondaryAccessors'] : []) => {
        setLoading(true)
        const res = await API.getLeads(null, null, { id: _refine['id'], value: _refine['value'] }, secondary)
        const { leads, header } = res
        const accessors = ['name', 'email', 'phoneNumber']
        const { geographicRegions } = res
        // let statesActive = [], countiesActive = [], citiesActive = []
        // if (geographicRegions) {
        //     geographicRegions.forEach(region => {
        //         let newFormat = { ...region, ...region.regionId }
        //         if (newFormat.regionType === 'County')
        //             countiesActive.push(newFormat)
        //         if (newFormat.regionType === 'City')
        //             citiesActive.push(newFormat)
        //         if (newFormat.regionType === 'State')
        //             citiesActive.push(newFormat)
        //     })
        // }
        setData(prev => ({
            ...prev,
            header: header ? header : {},
            leads: leads.map(item => {
                let geoMatches = [], geoMatchesAll = [];
                if (item.geoMatches)
                    geoMatches = item.geoMatches.flat().sort((a, b) => b.price - a.price)
                if (item.geoMatchesAll)
                    geoMatchesAll = item.geoMatchesAll.flat().sort((a, b) => b.price - a.price)
                return ({ ...item, geoMatches, geoMatchesAll })
            })
        }))
        setLoading(false)
    }
    console.log("data", data)

    const getDotMenu = (lead) => {
        const options = []
        options.push({
            innerText: 'Remove',
            value: 'remove',
            onClick: () => {
                console.log("remove lead", lead)
                setShowRemovalModal(prev => ({ shouldShow: !prev.shouldShow, lead }))
            }
            // onClick: async function () {
            //     const res = await API.removeAndMarkLeadUnmatched(null, lead._id)
            //     console.log("res is", res)
            // }
        })
        // if (_active.value === false)
        //     options.push({
        //         innerText: 'Make Active',
        //         value: 'activate',
        //         onClick: function () {
        //             let modalData;
        //             setModal(prev => ({
        //                 ...prev,
        //                 showModal: true,
        //                 type: 'move-to-active',
        //                 title: 'Make Active',
        //                 lead
        //             }))
        //         }
        //     })
        // if (_active.value === true)
        //     options.push({
        //         innerText: 'Edit',
        //         value: 'activate',
        //         onClick: function () {
        //             let modalData;
        //             setModal(prev => ({
        //                 ...prev,
        //                 showModal: true,
        //                 type: 'edit-lead-details',
        //                 title: 'Edit',
        //                 lead
        //             }))
        //         }
        //     })
        if (_active.value === false)
            options.push({
                innerText: 'Match Lead To Attorney',
                value: 'match-attorney',
                onClick: async function () {
                    const res = await API.matchLeadToAttorney(null, lead._id)
                    console.log("res is", res)
                }
            })
        options.push({
            innerText: 'Send Lead',
            value: 'view-details',
            onClick: function () {
                let modalData;
                if (_active && Array.isArray(_active.secondaryAccessors) && _active.secondaryAccessors.find(it => it['isComplete']))
                    setModal(prev => ({
                        ...prev,
                        showModal: true,
                        type: 'view-lead-details',
                        title: 'Lead Details',
                        lead,
                        header
                    }))
                else
                    navigate(`/sendLead?leadId=${lead._id}`)
            }
        })
        return options
    }



    useEffect(() => {
        getLeads()
    }, [_active])
    const { header } = data
    const shouldUseInitialFormSubmissionValues = () => ((_active.filterAccessor === 'isActive' && _active.value === false) || (_active.filterAccessor === 'isNewRecord' && _active.value === true))
    const { showModal } = modal
    return (
        <Flexbox column alignItems="start" width="100%"
            // height={showModal ? "100vh" : '100%'} 
            height="100%"
            margin="50px 0 0"
            minHeight={'100vh'} >
            <Flexbox column alignItems="start" height="100%" width="100%" padding="50px 50px"
                // paddingMobile="0 5% 20px"
                paddingMobile="0"
                overflow={showModal ? "hidden" : 'auto'}
            // margin="0 0 20px"
            >
                <AdminNotes noteCategory="Lead" />
                <Table outline="1px solid var(--formBlue1)" oddHighlight={true} width="100%" >
                    <TableRow className="tableHeader" isHeader={true}>
                        {
                            Object.keys(header).map(it => {
                                const isGroup = header[it]['title'] === undefined
                                return (
                                    <TableCell userSelect="all" className={header[it]['className']}>
                                        {!isGroup && (header[it]['title'] ? header[it]['title'] : '-')}
                                        {isGroup && (
                                            <>
                                                {Object.keys(header[it].fields).map((it2, index) => {
                                                    const { fields } = header[it]
                                                    //: { [it2]: { title } }
                                                    let title = ''
                                                    if (fields[it2])
                                                        title = fields[it2].title
                                                    if (index === 0)
                                                        return (<>
                                                            <Flexbox gap="2px" userSelect="all">
                                                                {title}
                                                            </Flexbox>
                                                        </>)
                                                    else
                                                        return (<>
                                                            <Flexbox gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                                                {title}
                                                            </Flexbox>
                                                        </>)
                                                })}
                                            </>)}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                    {
                        loading && <TableRow background="none">
                            <TableCell colSpan="8" textAlign="center"><LoadingWheel /></TableCell>
                        </TableRow>
                    }
                    {
                        !loading &&
                        leads.map(lead => {

                            return (
                                <TableRow key={lead.id} color={!lead.city || !lead.city.isValid ? '#b30000' : undefined}>
                                    {
                                        Object.keys(header).map(it => {
                                            const isGroup = header[it]['title'] === undefined
                                            const value = typeof (lead[it]) === 'object' ? lead[it].symbol || lead[it].value || '-' : lead[it]
                                            return (
                                                <TableCell userSelect="all" className={header[it]['className']}>
                                                    {!isGroup && (
                                                        <span title={typeof (lead[it]) === 'object' ? lead[it].value ? lead[it].value : '-' : lead[it]}>{value}</span>
                                                    )}
                                                    {isGroup && (
                                                        <>
                                                            {Object.keys(header[it].fields).map((it2, index) => {
                                                                const value = lead[it2] === null ? "-" : typeof (lead[it2]) === 'object' ? lead[it2].symbol || lead[it2].value || '-' : lead[it2]
                                                                if (index === 0)
                                                                    return (<>
                                                                        <Flexbox
                                                                            title={lead[it2] === null ? undefined : typeof (lead[it2]) === 'object' ? lead[it2].value ? lead[it2].value : '-' : lead[it2]}
                                                                            gap="2px" userSelect="all">
                                                                            {value}
                                                                        </Flexbox>
                                                                    </>)
                                                                else
                                                                    return (<>
                                                                        <Flexbox
                                                                            title={lead[it2] === null ? undefined : typeof (lead[it2]) === 'object' ? lead[it2].value ? lead[it2].value : '-' : lead[it2]}
                                                                            gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                                                            {value}
                                                                        </Flexbox>
                                                                    </>)
                                                            })}
                                                        </>)}
                                                </TableCell>
                                            )
                                        })
                                    }
                                    <TableCell userSelect="none"><DotMenu options={getDotMenu(lead)} /></TableCell>
                                    <TableCell>
                                        {/* < ConfirmationModal setShowRemovalModal={setShowRemovalModal} lead={lead} /> */}
                                    </TableCell>
                                </TableRow>)

                        })
                    }

                </Table >
            </Flexbox>

            {showModal && < LeadsModal setShowModal={setShowModal}  {...modal} />}
        </Flexbox >
    )
}

export default LeadsTable