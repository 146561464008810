import React, { useState, useEffect } from 'react'

import Flexbox from '../Flexbox'
import Dropdown from '../Dropdown'

const DotMenu = ({ fill = '#000', options }) => {
    const [showDropdown, setShowDropdown] = useState(false)
    return (
        <Flexbox justifyContent="end" height="20px" width="20px" alignItems="start">
            <Flexbox height="20px" width="20px" position="relative" onClick={() => setShowDropdown(!showDropdown)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 13" height="15px" width="15px" >
                    <g style={{ fill: fill }}>
                        <circle cx="1.5" cy="1.5" r="1.5" />
                        <circle cx="1.5" cy="6.5" r="1.5" />
                        <circle cx="1.5" cy="11.5" r="1.5" />
                    </g>
                </svg>
            </Flexbox >
            <Flexbox position="absolute">
                <Dropdown
                    width="200px"
                    // border="1px solid var(--primary)"
                    right="0"
                    valueX={[]}
                    dropdownListMarginTop="30px"
                    showHeader={false}
                    hideBgdWrapper={true}
                    menuState={showDropdown}
                    _handleChange={() => setShowDropdown(!showDropdown)}
                    handleStateChange={() => setShowDropdown(!showDropdown)}
                    options={options} />
            </Flexbox>
        </Flexbox>
    )
}

export default DotMenu