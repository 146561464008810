import React, { useState } from 'react'

import { TableRowContainer, TableRowHeaderContainer } from './TableRow.styles'

import TableCell from '../TableCell'


const TableRow = ({ cellPadding, onMouseEnter, onMouseLeave, userSelect, background, isHeader = false, isHeaderFormatted = false, content, children, className, onClick, group, fontSize, childrenProps, ...props }) => {
    const [hoverActive, setHoverActive] = useState(false)
    if (isHeaderFormatted)
        return (
            <TableRowHeaderContainer
                cellPadding={cellPadding}
                userSelect={userSelect} background={background} className={className} fontSize={fontSize} onClick={onClick}
                onMouseEnter={(e) => {
                    setHoverActive(true);
                    if (typeof (onMouseEnter) === 'function')
                        onMouseEnter();
                }}
                onMouseLeave={(e) => {
                    setHoverActive(false)
                    if (typeof (onMouseLeave) === 'function')
                        onMouseLeave();
                }}
                {...props}
            >{
                    content !== undefined &&
                    Object.keys(content).map((it) => {
                        if (!Array.isArray(content[it]))
                            return (
                                <TableCell content={{ title: it, props: { ...content[it].props, ...childrenProps } }} />)
                        else {
                            return <></>
                        }
                    })

                }{children}</TableRowHeaderContainer>
        )
    else if (isHeader)
        return (
            <TableRowHeaderContainer
                cellPadding={cellPadding} userSelect={userSelect} background={background} className={className} fontSize={fontSize} onClick={onClick} onMouseEnter={(e) => {
                    setHoverActive(true);
                    if (typeof (onMouseEnter) === 'function')
                        onMouseEnter();
                }}
                onMouseLeave={(e) => {
                    setHoverActive(false)
                    if (typeof (onMouseLeave) === 'function')
                        onMouseLeave();
                }}
                {...props}>{
                    content !== undefined &&
                    Object.keys(content).map((it) => {
                        if (!Array.isArray(content[it]))
                            return (
                                <TableCell content={{ title: content[it].title, props: { ...content[it].props, ...childrenProps } }} />)
                        else {
                            return <></>
                        }
                    })

                }{children}</TableRowHeaderContainer>
        )
    return (
        <TableRowContainer
            cellPadding={cellPadding} userSelect={userSelect} background={background} className={className} fontSize={fontSize} onClick={onClick} onMouseEnter={(e) => {
                setHoverActive(true);
                if (typeof (onMouseEnter) === 'function')
                    onMouseEnter();
            }}
            onMouseLeave={(e) => {
                setHoverActive(false)
                if (typeof (onMouseLeave) === 'function')
                    onMouseLeave();
            }}
            {...props}>{
                content !== undefined &&
                Object.keys(content).map((it) => {
                    if (!Array.isArray(content[it]))
                        return (
                            <TableCell content={{ title: content[it].title, props: { ...content[it].props, ...childrenProps } }} />)
                    else {
                        return <></>
                    }
                })

            }{children}</TableRowContainer>
    )
}

export default TableRow