import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams, useLocation, useParams, useNavigate } from 'react-router-dom'
import InputField from './InputField'
import PageContentContainer from './PageContentContainer'
import API from '../API'

import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
// import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/saga-green/theme.css"
import dayjs from 'dayjs'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog as PrimeDialog } from 'primereact/dialog';
import { Button as PrimeButton } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
// dayjs().format()
import 'primeicons/primeicons.css';

import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import styled from 'styled-components'
import { useAuth } from '../contexts/AuthContext'
import TextArea from './TextArea';
import classNames from 'classnames'
import RadixDropdown from './RadixDropdown'
import Cookies from 'js-cookie'
import { AddAttorneyDropdown, AddAttorneyModal } from './LeadsModal'
import TempAddGeoRegion from './TempAddGeoRegion'
import RadixDialog from './RadixDialog'
import ResetButton from './ResetButton'
import { Label as FieldLabel } from './InputField'
import { formatPrice } from '../utils'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: 'now',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1M",
        MM: "%dM",
        y: "a year",
        yy: "%d years"
    }
})
const statusBodyTemplate = (dispute, fieldKey, tagOverrideField = null) => {
    return <Tag value={dispute[fieldKey]} severity={getSeverity(dispute, fieldKey, tagOverrideField)}></Tag>;
};

const H3 = styled.h3`
color: var(--formBlue1);
`;

const AttorneyContainer = styled.div`
background-color: white;
`;

const getSeverity = (dispute, fieldKey, tagOverrideField = null) => {
    switch (typeof (dispute) === 'string' ? dispute : dispute[tagOverrideField ? tagOverrideField : fieldKey]) {
        case 'ACCEPTED':
            return 'success';

        case 'PENDING':
            return 'warning';

        case 'REJECTED':
            return 'danger';
        case 'State':
            return 'info';

        case 'County':
            return 'info';

        case 'City':
            return 'info';
        default:
            return null;
    }
};

const AcceptedDisputeDialog = ({ status, adminNotes }) => {
    return (
        <>
            <H3>Admin</H3>
            <h4>Status</h4>
            <p>{status || '-'}</p>
            <h4>Notes</h4>
            <p>{adminNotes || '-'}</p>
        </>)
}

const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
};

const GridContainer = styled.div`
display: grid;
grid-template: auto / 1fr;
gap: 5px;
background-color: aliceblue;
`;

const PendingDisputeDialog = ({ defaultDisputeResponses, statuses, _id, getData }) => {
    const [disputeUpdate, setDisputeUpdate] = useState({
        status: 'PENDING',
        adminNotes: '',
        selectedResponse: ''
    })
    const [serverRes, setServerRes] = useState({
        didSubmit: false,
        success: false,
        msg: ''
    })
    const { status, adminNotes, selectedResponse } = disputeUpdate
    const { didSubmit, success, msg } = serverRes
    // defaultDisputeResponses
    const onChange = (field, value) => setDisputeUpdate(prev => ({ ...prev, [field]: value }))
    return (
        <GridContainer gap="5px">
            <H3>Admin</H3>
            <h4>Status</h4>
            <Dropdown value={status} options={statuses}
                onChange={(e) => onChange("status", e.value)}
                itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
            {
                status === 'REJECTED' &&
                <>
                    <h4>Default Responses</h4>
                    <Dropdown value={selectedResponse} options={defaultDisputeResponses.map(it => it['name'])}
                        onChange={(e) => {
                            let note = defaultDisputeResponses.find(it => it['name'] === e.value)
                            setDisputeUpdate(prev => ({ ...prev, selectedResponse: e.value }))
                            if (note) {
                                note = note.note
                                onChange("adminNotes", note)
                            }
                        }}
                        itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
                    <h4>Admin Notes</h4>
                    <TextArea onChange={(e) => onChange("adminNotes", e.target.value)} value={adminNotes} alignText="left" /></>
            }
            <Button
                style={{ width: '100%', marginTop: '10px' }}
                disabled={status === 'PENDING'} label="Submit" onClick={async () => {
                    let res = await API.processDispute({ adminNotes, status, disputeId: _id })
                    // console.log("res",res)
                    setServerRes(prev => ({ ...prev, didSubmit: true, success: res['success'], msg: res['msg'] || 'Unknown' }))
                    if (res['success'])
                        await getData()
                }} />
            {
                didSubmit && <p>{msg}</p>
            }
        </GridContainer>)
}

const DisputeDialogBody = (dispute) => {
    const { shortId, reasonForDispute, comments, status, updatedAt, lead: { email, name, updatedAt: updatedAtLead, phoneNumber } } = dispute
    return (<>
        <H3>Dispute</H3>
        <h4>Dispute Id</h4>
        <p>{shortId}</p>
        <h4>Submitted</h4>
        <p>{getDate('createdAt')(dispute)}</p>
        <h4>Reason</h4>
        <p>{reasonForDispute['name']}</p>
        <h4>Dispute Comments</h4>
        <p>{comments}</p>
        <h4>Updated</h4>
        <p>{getDate('updatedAt')(dispute)}</p>


        {
            status !== "PENDING" && <AcceptedDisputeDialog {...dispute} />
        }
        <H3>Lead</H3>
        <h4>Name</h4>
        <p>{name}</p>
        <h4>Phone</h4>
        <p>{phoneNumber}</p>
        <h4>Email</h4>
        <p>{email}</p>
        <h4>Lead Received</h4>
        <p>{getDate('lead.updatedAt')(dispute)}</p>
        {
            status === "PENDING" && <PendingDisputeDialog {...dispute} />
        }
    </>)
}

const buttonBodyTemplate = (setDialogue) => (dispute) => {
    if (dispute.status === 'PENDING')
        return <></>
    return (<div><PrimeButton id="infoButton" icon="pi pi-info" rounded severity="help" aria-label="Info" onClick={() => setDialogue(true, <DisputeDialogBody {...dispute} />)} /></div>)
    return <PrimeButton onClick={() => setDialogue(true, dispute['adminNotes'] || '-')} className="pi pi-info" rounded aria-label="Info" />;
};

const Dialog = ({ visible, setVisible, text }) => {
    return (<PrimeDialog header="Details" visible={visible} style={{ width: '50vw' }} position="bottom" onHide={() => setVisible(false)}>
        {/* <p className="m-0"> */}
        {text}
        {/* </p> */}
    </PrimeDialog>)
}
/*
const buttonBodyTemplate = (dialogue, setDialogue) => (dispute) => {
    if (dispute.status === 'PENDING')
        return <></>
    return <RadixDialog {...dialogue} dialogTrigger={<PrimeButton onClick={() => setDialogue(true, dispute['adminNotes'] || '-')} className="pi pi-info-circle" rounded outlined aria-label="Info" />} />
    // return;
};
*/

const hasBudgetBodyTemplate = (rowData) => {
    return <i style={{ color: rowData.hasBudget ? 'green' : 'red' }} className={classNames('pi', { 'true-icon pi-check-circle': rowData.hasBudget, 'false-icon pi-times-circle': !rowData.hasBudget })}></i>;
};

const budgetRowFilterTemplate = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
};

const getColumn = (dispute) => {
    switch (dispute.type) {
        case 'ACCEPTED':
            return 'success';

        case 'PENDING':
            return 'warning';

        case 'REJECTED':
            return 'danger';

        default:
            return null;
    }
};

String.prototype.padLeft = function (length, character) {
    return new Array(length - this.length + 1).join(character || ' ') + this;
};

Date.prototype.toFormattedString = function () {
    return [String(this.getMonth() + 1).padLeft(2, '0'),
    String(this.getDate()).padLeft(2, '0'),
    String(this.getFullYear()).slice(-2)].join("/") + " " +
        [String(this.getHours()).padLeft(2, '0'),
        String(this.getMinutes()).padLeft(2, '0')].join(":");
};

const getObjDeeperLayer = (a, c) => {
    if (typeof (a) !== 'object' || a === null)
        return a
    let b = c.split('.')
    return (b.reduce((_a, _b) => {
        return (_a[_b])
    }, a))
}

const dateFormatByType = (date, dateType) => {
    switch (dateType) {
        case "FROM-NOW":
            return (dayjs(date).isValid() ? dayjs(date).fromNow() : '-')
        default:
            return dayjs(date).format('ddd, MMM DD')
    }
}

const getDate = (fieldKey, dateType = null) => (dispute) => {
    let date = getObjDeeperLayer(dispute, fieldKey)
    if (!date)
        return (<></>)
    return (dateFormatByType(date, dateType))
}

const getTag = (tagType, fieldKey, tagOverrideField = null) => (dispute) => {
    return statusBodyTemplate(dispute, fieldKey, tagOverrideField)
}

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

const H5 = styled.h5`
font-size: 10px;
margin: 0;
`;

const LeadNoteContainer = styled.div`
text-align: left;
background-color: #fff;
overflow: auto;
padding: 15px;
margin: 20px 0;
max-height: 40vh;
h4{
    margin: 1.3em 0 0.5em;
}
h5{
    font-size: 10px;
    margin: 0;
    color: #646464;
}
p{
    font-size: 14px;
    margin-top: .3em;
}
`;

const showNotes = (field, defaultNotes) => {
    return (field.databaseFieldName === 'additionalDetails' && Array.isArray(defaultNotes) && defaultNotes.length > 0)
}

const LeadData = ({ formattedLead }) => {
    if (!formattedLead || !formattedLead.caseDetails)
        return <></>

    return (
        <LeadNoteContainer >
            <h4>Contact Details</h4>
            {
                formattedLead['contactDetails'].map(it => {
                    return (<>
                        <h5>{it['header']}</h5>
                        <p>{it['value']}</p>
                    </>)
                })
            }
            <h4>Case Details</h4>
            {
                formattedLead['caseDetails'].map(it => {
                    return (<>
                        <h5>{it['header']}</h5>
                        <p>{it['value']}</p>
                    </>)
                })
            }
        </LeadNoteContainer>)
}

const EditableFields = ({ setData, fields, fieldOrder = [], data, searchParams, setAttorneyMatches, setNewAttorney, setAdminAddedMatches, defaultNotes, lead }) => {
    const updateObj = (field, v, additionalUpdateKeys) => {
        if (field.type === 'radio')
            return { value: v, selected: v }
        else {
            let returnObj = { value: typeof (v) === 'string' ? v : v['value'] }
            if (typeof (field['validate']) === 'function') {
                returnObj['isValid'] = field['validate'](typeof (v) === 'string' ? v : v['value'])
            }
            if (typeof (v) === 'object' && v !== null) {
            }
            if (Array.isArray(additionalUpdateKeys) && typeof (v) === 'object') {
                for (let index = 0; index < additionalUpdateKeys.length; index++) {
                    const { mapFrom, mapTo } = additionalUpdateKeys[index];

                    returnObj[mapTo] = v[mapFrom]
                }
            }
            return returnObj
        }

    }
    const onChange = async (fieldId, e, updateKey = null) => {
        let updateKeys = [], field = data.editableFields.fields[fieldId], result = data.result, totalRecords = data.totalRecords, v = undefined, resultAdminAdded = data.resultAdminAdded, totalRecordsAdminAdded = data.totalRecordsAdminAdded
        if (typeof (e) === 'string')
            v = e
        else if (field.additionalUpdateKeys) {
            updateKeys = field.additionalUpdateKeys
            v = e
        }
        else
            v = e?.target?.value || e?.value || ''
        if (field.databaseFieldName === 'practiceAreaId') {
            let res = await API.findMatchingAttorneys({
                practiceAreaId: v._id || null, regionIds: data?.lead['regionIds'],
                token: searchParams?.get('token'),
                leadKind: data?.lead['kind']
            })
            result = res?.result || result
            totalRecords = res?.totalCount || totalRecords
            resultAdminAdded = res?.resultAdminAdded || resultAdminAdded
            totalRecordsAdminAdded = res?.totalRecordsAdminAdded || totalRecordsAdminAdded
            setNewAttorney(prev => ({ ...prev, practiceAreaId: v._id || null }))
        } else if (['settlementEstimateHigh', 'settlementEstimateLow'].includes(field.databaseFieldName)) {
            v = formatPrice(v)
        }
        setData({
            ...data,
            result,
            totalRecords,
            resultAdminAdded,
            totalRecordsAdminAdded,
            editableFields: {
                ...data.editableFields,
                fields: {
                    ...data.editableFields.fields,
                    [fieldId]: {
                        ...field,
                        ...updateObj(field, v, updateKeys)
                    }
                }
            }
        })
        setAttorneyMatches(result)
        // need admin matches here too
        setAdminAddedMatches(resultAdminAdded)
    }

    return (
        <LeadNoteContainer>{
            fieldOrder.map(fieldKey => {
                const field = fields[fieldKey]
                let _shouldShow = false
                console.log(`field is: ${fieldKey}`, field)
                field['onChange'] = async (e) => {
                    await onChange(fieldKey, e)
                }
                if (field['type'] === 'radio') {
                    field['onClick'] = field['onChange']
                }
                if (field.type === 'dropdown') {
                    // let options = field.options['group-1'].options
                    // return (<>
                    //     {
                    //         field.label && <Label htmlFor={field['databaseFieldName']}
                    //             label={field?.label}
                    //         />
                    //     }
                    //     <Dropdown
                    //         {...field}
                    //         // options={field.options['group-1'].options.map(it => ({
                    //         //     ...it,
                    //         //     label: it.displayValue,
                    //         // }))}
                    //         options={options}
                    //         color="#000"
                    //         width="fit-content"
                    //         stroke="var(--formBlue1)"
                    //         labelFontSize=".8rem"
                    //         onValueChange={async (e) => {
                    //             console.log("e", e)
                    //             if (typeof (e) !== 'string')
                    //                 await onChange(fieldKey, e)
                    //             else {
                    //                 const options = field.options['group-1'].options
                    //                 let selectedOption = options.find(option => option.value === e)
                    //                 await onChange(fieldKey, selectedOption)
                    //             }
                    //         }}
                    //         key={fieldKey}
                    //         id={fieldKey}
                    //         name={fieldKey}
                    //     /></>)
                    return (<RadixDropdown
                        {...field}
                        color="#000"
                        width="fit-content"
                        stroke="var(--formBlue1)"
                        labelFontSize=".8rem"
                        onValueChange={async (e) => {
                            console.log("e", e)
                            if (typeof (e) !== 'string')
                                await onChange(fieldKey, e)
                            else {
                                const options = field.options['group-1'].options
                                let selectedOption = options.find(option => option.value === e)
                                await onChange(fieldKey, selectedOption)
                            }
                        }}
                        key={fieldKey}
                        id={fieldKey}
                        name={fieldKey}
                    />)
                }
                if (field.type === 'input')
                    return (
                        <InputField {...field}
                            focusBgd="#94cdff42"
                            focusBorder='none;border-bottom:1px solid #000'
                            inputPlaceHolderColor="#9d9b9b"
                            color="#000"
                            labelFontSize=".8rem"
                            background="#fff"
                            focusColor="var(--formBlue1)"
                            stroke="var(--formBlue1)"
                            border='none;border-bottom:1px solid var(--formBlue1)'
                            key={fieldKey}
                        />)
                else {
                    // if (field.databaseFieldName === 'additionalDetails')

                    return (<div style={{ background: '#94cdff42' }}>
                        <FieldLabel htmlFor={field.name} {...field} labelFontSize=".8rem"></FieldLabel>

                        <TextArea {...field}
                            focusBgd="#94cdff42"
                            focusBorder='1px solid #000'
                            inputPlaceHolderColor="#1c0e0e"
                            color="#000"
                            labelFontSize=".8rem"
                            hideLabel={true}
                            background="#fff"
                            focusColor="var(--formBlue1)"
                            stroke="var(--formBlue1)"
                            border='1px solid var(--formBlue1)'
                            key={fieldKey} />
                        <TwoCol>

                            {(showNotes(field, defaultNotes)) &&
                                <NotesDropdown
                                    {...field}
                                    lead={lead}
                                    options={defaultNotes}
                                />}

                        </TwoCol>
                    </div>)
                }
            })
        }
        </LeadNoteContainer >
    )

}



function replacePlaceholders(obj, str) {
    console.log("string is", str)
    if (typeof (str) !== 'string')
        return ''
    return str.replace(/{{(.*?)}}/g, (match, key) => {
        let result = obj
        const keys = key.trim().split('.');
        for (let key of keys) {
            if (result && typeof result === 'object' && key in result) {
                result = result[key];
            } else {
                return '';
            }
        }
        return result || '';
    });
}

const TwoCol = styled.div`
display: grid;
grid-template-columns: repeat(4,max-content);
grid-auto-flow: row;
grid-template-rows: fit-content;
justify-content: end;
margin-bottom: 1em;
gap: .75em;

@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    grid-template-columns: repeat(3,max-content);
    .DefaultNoteDropdown{
        grid-column: 1 / span 3;
    }
}
`;

const NotesDropdown = ({ options, initialValue, onChange, value, lead }) => {
    const [dropdownValue, setValue] = useState(null)
    return (
        <>
            <Dropdown
                options={options}
                value={dropdownValue}
                placeholder="Default Notes"
                optionValue='note'
                optionLabel='name'
                onChange={(e) => setValue(e?.value)}
                className='DefaultNoteDropdown'
            />
            <NoteButtons onClick={() => {
                onChange(`${replacePlaceholders(lead, dropdownValue)}\n\n${value}`)
            }}>Add to Notes</NoteButtons>
            <NoteButtons warn>Overwrite Notes</NoteButtons>
            <ResetButton onClick={() => onChange(initialValue)} />
        </>)
}

const NoteButtons = styled.div`
display: inline-flex;
width: fit-content;
align-items: center;
justify-content: center;
border-radius: 4px;
width: fit-content;
padding: 0.3em 0.5em;
font-size: 12px;
min-height: 35px;
line-height: 1;
font-weight: 500;
background: ${({ warn }) => warn ? '#fb000081' : '#d5f0d9'};
color: ${({ warn }) => warn ? '#9f0000' : '#00942c'};
border-color: ${({ warn }) => warn ? '#9f0000' : '#00942c'};
user-select: none;
cursor: pointer;
border-width: 1px;
border-style: solid;
`;

const SH3 = styled.h3`
            color: #fff;
            margin-bottom: 0;
            `;

const SendFromEmailTable = ({ _active = {} }) => {

    const [attorneyMatches, setAttorneyMatches] = useState([])
    const [adminAddedMatches, setAdminAddedMatches] = useState([])
    const [inactiveMatches, setInactiveMatches] = useState([])

    const [serverRes, setServerRes] = useState({
        didSubmit: false,
        success: false,
        msg: '',
        color: 'black',
        isSending: false,
    })
    const isAuthed = useRef(false)
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    // const {currentUser, currentUser: {user} } = useAuth()
    const initialRef = useRef(true)
    const [data, setData] = useState({
        page: 1,
        perPage: 1,
        totalRecords: 0,
        totalRecordsAdminAdded: 0,
        header: {},
        disputes: [],
        defaultDisputeResponses: [],
        editableFields: null,
        lead: null,
        formattedLead: null,
        selectedAttorney: null,
        addingMatchEnabled: false,
        dialogue: {
            visible: false,
            text: ""
        },
        contactMethods: null,
        defaultNotes: null
    })

    const [statuses] = useState(['ACCEPTED', 'PENDING', 'REJECTED',])
    const { addingMatchEnabled, lead, selectedAttorney, formattedLead, defaultDisputeResponses, totalRecords, page, perPage, header, disputes, dialogue: { text }, dialogue, editableFields, contactMethods, defaultNotes } = data
    const defaultNewAttorneyObject = {
        regionType: '',
        regionValue: '',
        regionName: '',
        attorneyId: null,
        price: '',
        practiceAreaId: lead?.practiceAreaId || null,
        isAdminAdd: true,
        firmName: ''
    }
    const [newAttorney, setNewAttorney] = useState(defaultNewAttorneyObject)
    if (editableFields)
        console.log(editableFields.fields)
    const [visible, setVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [filters, setFilters] = useState({
        "attorney.firmName": { value: null, matchMode: FilterMatchMode.EQUALS },
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        hasBudget: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getData = async (_refine = _active ? { [_active.filterAccessor]: _active.value } : {}) => {
        try {
            let leadId = searchParams?.get('leadId')
            let token = searchParams?.get('token')
            // if (token){
            //     Cookies.set('jwt', `Bearer ${token}`, {expires: 1, secure: true });
            // }
            const res = await API.getLeadDataAndMatches({ leadId, token, ..._refine })
            const { result, header, defaultDisputeResponses, formattedLead, resultAdminAdded, resultInactive } = res
            if (initialRef.current) {
                initialRef.current = false
                setData(prev => ({
                    ...prev,
                    ...res,
                    // formattedLead: {
                    //     ...res.formattedLead,
                    //     contactDetails: res.formattedLead?.contactDetails.map(it => {
                    //         if (it.header === 'Phone')
                    //             return ({
                    //                 ...it, value: it.value.match(/^(\d{3})(\d{3})(\d{4})$/).reduce((a, b, index) => {
                    //                     if (index !== 0) {
                    //                         if (index === 1)
                    //                             a = b
                    //                         else
                    //                             a = a + '-' + b
                    //                     }
                    //                     return a
                    //                 }, '')
                    //             })
                    //     })
                    // }
                    // defaultDisputeResponses,
                    // header,
                    // formattedLead
                }))
                setNewAttorney(prev => ({ ...prev, practiceAreaId: res?.lead.practiceAreaId._id || null }))
            }
            setAttorneyMatches(result)
            setAdminAddedMatches(resultAdminAdded)
            setInactiveMatches(resultInactive)
            setLoading(false)
            isAuthed.current = true
        } catch (error) {
            console.log('An error has occured while fetching resources from the server')
            console.log(error)
            if (error?.response && error.response.data === 'Unauthorized') {
                isAuthed.current = false
                navigate('/login')
            }
            else if (error?.response && error.response?.data) {
                isAuthed.current = true
                setServerRes(prev => ({
                    ...prev,
                    msg: error.response?.data.msg || 'An Error Occured'
                }))
            }
            setLoading(false)
        }
    }
    const onRowSelect = (event) => {
        setData(prev => ({
            ...prev,
            addingMatchEnabled: false,
            selectedAttorney: event.data, contactMethods: null
        }))
        setNewAttorney(defaultNewAttorneyObject)
    };

    const onRowUnselect = (event) => {
        setData(prev => ({ ...prev, selectedAttorney: null }))
    };

    useEffect(() => {

        getData()
        // DisputeService.getCustomers().then((data) => {
        //     const { result } = data
        //     console.log("data", data, result)
        //     setAttorneyMatches(result)
        // });

    }, [])

    const setDialogue = (visibility, text) => {
        setData(prev => ({
            ...prev,
            dialogue: {
                visible: visibility,
                text
            }
        }))
        setVisible(visibility)
    }

    const statusRowFilterTemplate = (_statuses) => (options) => {

        return (
            <Dropdown value={options.value} options={_statuses}
                onChange={(e) => {
                    options.filterApplyCallback(e.value)
                }}
                itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '6rem' }} />
        );
    };
    const [showAddAttModal, setShowAddAttModal] = useState(false)

    const { price, regionType, regionName, attorneyId } = newAttorney
    const setNewAttorneyField = (fieldValueObject) => {
        if (typeof (fieldValueObject) === 'object' && fieldValueObject !== null) {
            setNewAttorney(prev => ({
                ...prev,
                ...fieldValueObject
            }))
        }
    }
    const getPotentialMatches = async () => {
        // const res = await API.getPotentialMatches(leadId)
        // const { attorneys, formattedLead } = res
        // setLeadMod(prev => ({
        //     ...prev,
        //     matches: attorneys || [],
        //     initialLead: formattedLead,
        //     modifiedLead: formattedLead,
        // }))

    }
    useEffect(() => {
        async function getContactMethods() {
            try {
                if (attorneyId) {
                    let data = await API.getContactMethods(attorneyId)
                    console.log("data::::::")
                    console.log(data)
                    setData(prev => ({
                        ...prev,
                        contactMethods: data.contactMethods || null
                    }))
                }
            } catch (error) {
                console.log(`an error occurred:`)
                console.log(error)
            }
        }
        getContactMethods()
    }, [attorneyId])

    if (process.env.NODE_ENV === 'development') {
        console.log("newAttorney", newAttorney)
        console.log("selectedAttorney", selectedAttorney)
        console.log("selectedProduct", selectedProduct)
        console.log("contactMethods", contactMethods)
        console.log("serverRes", serverRes)
    }
    const addNewAttorneyRegion = async () => {
        // setNewAttorney()

        // setData(prev => ({ ...prev, selectedAttorney: event.data }))
    }

    if (!isAuthed.current)
        return <></>
    else
        return (
            <>

                <PageContentContainer
                    // margin="calc(var(--navBarHeight) * -1) 0 0"
                    overflow="auto"
                    position="relative" width="100%"
                    display="flex"
                    flexDirection="column"
                    column background="var(--formBackground)"
                    padding="50px 2.5% 0"
                    height="100%" minHeight="100vh" justifyContent="center">
                    <div className='card' style={{ height: 'auto', width: '95%', maxWidth: '900px', position: 'relative', overflow: 'auto', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                        <Button style={{ width: "fit-content" }} onClick={() => navigate('/leads/set-calc')}>Back</Button>
                        {
                            lead && <>
                                <SH3>Lead</SH3>
                                <LeadData formattedLead={formattedLead} />
                                <SH3>Fields</SH3>
                                {editableFields && <EditableFields {...editableFields} setData={setData} data={data} searchParams={searchParams} setAttorneyMatches={setAttorneyMatches} setNewAttorney={setNewAttorney} setAdminAddedMatches={setAdminAddedMatches} defaultNotes={defaultNotes} lead={lead} />}
                                <SH3>Attorneys</SH3>
                                <AttorneyContainer>
                                    <DataTable
                                        // sortField="updatedAt" sortOrder={-1}
                                        paginator rows={5}
                                        scrollable
                                        scrollHeight="400px"
                                        metaKeySelection={false}
                                        value={attorneyMatches} tableStyle={{ minWidth: '50rem', borderSpacing: '0px' }} stripedRows
                                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                        filterDisplay="row"
                                        filters={filters}
                                        // header={_header}
                                        loading={loading}
                                        // globalFilterFields={['status']}
                                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                        // paginator rows={2} rowsPerPageOptions={[5, 10, 25, 50]}
                                        selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="_id"
                                        onRowSelect={onRowSelect} onRowUnselect={onRowUnselect}>
                                        {
                                            Object.keys(header).map(headerKey => {
                                                const { sortable, title, type, tagType, tagOverrideField, filter, filters } = header[headerKey]
                                                let additionalFilter = { key: title }
                                                if (filter) {
                                                    additionalFilter.filter = true
                                                    additionalFilter.showFilterMenu = false
                                                    additionalFilter.filterMenuStyle = { width: '14rem' }
                                                    if (filters)
                                                        additionalFilter.filterElement = statusRowFilterTemplate(filters.map(it => typeof (it) === 'string' ? it : it._id))
                                                    // additionalFilter.filterElement = statusRowFilterTemplate
                                                    // if (title === 'Status')
                                                    //     additionalFilter.statusRowFilterTemplate = statusRowFilterTemplate
                                                }
                                                if (title === 'Budget')
                                                    return (<Column key={headerKey} field={headerKey} header={title} dataType="boolean" style={{ minWidth: '1rem' }} body={hasBudgetBodyTemplate} filter filterElement={budgetRowFilterTemplate} />)
                                                if (type === 'TAG' && tagType)
                                                    return <Column key={headerKey} field={headerKey} header={title} body={getTag(tagType, headerKey, tagOverrideField)} {...additionalFilter} ></Column>
                                                if (type === 'DATE') {
                                                    return <Column key={headerKey} field={headerKey} header={title} body={getDate(headerKey, header[headerKey]['dateType'])} {...additionalFilter} ></Column>
                                                }
                                                return (<Column key={headerKey} field={headerKey} header={title} {...additionalFilter} />)
                                            })
                                        }
                                    </DataTable>
                                    <SH3 style={{ color: '#000' }}>Inactive Matches</SH3>
                                    <DataTable
                                        // sortField="updatedAt" sortOrder={-1}
                                        paginator rows={5}
                                        scrollable
                                        scrollHeight="400px"
                                        metaKeySelection={false}
                                        value={inactiveMatches} tableStyle={{ minWidth: '50rem', borderSpacing: '0px' }} stripedRows
                                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                        filterDisplay="row"
                                        filters={filters}
                                        loading={loading}
                                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                        selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="_id"
                                        onRowSelect={onRowSelect} onRowUnselect={onRowUnselect}>
                                        {
                                            Object.keys(header).map(headerKey => {
                                                const { sortable, title, type, tagType, tagOverrideField, filter, filters } = header[headerKey]
                                                let additionalFilter = { key: title }
                                                if (filter) {
                                                    additionalFilter.filter = true
                                                    additionalFilter.showFilterMenu = false
                                                    additionalFilter.filterMenuStyle = { width: '14rem' }
                                                    if (filters)
                                                        additionalFilter.filterElement = statusRowFilterTemplate(filters.map(it => typeof (it) === 'string' ? it : it._id))
                                                }
                                                if (title === 'Budget')
                                                    return (<Column key={headerKey} field={headerKey} header={title} dataType="boolean" style={{ minWidth: '1rem' }} body={hasBudgetBodyTemplate} filter filterElement={budgetRowFilterTemplate} />)
                                                if (type === 'TAG' && tagType)
                                                    return <Column key={headerKey} field={headerKey} header={title} body={getTag(tagType, headerKey, tagOverrideField)} {...additionalFilter} ></Column>
                                                if (type === 'DATE') {
                                                    return <Column key={headerKey} field={headerKey} header={title} body={getDate(headerKey, header[headerKey]['dateType'])} {...additionalFilter} ></Column>
                                                }
                                                return (<Column key={headerKey} field={headerKey} header={title} {...additionalFilter} />)
                                            })
                                        }
                                    </DataTable>
                                    <SH3 style={{ color: '#000' }}>Admin Added Matches</SH3>
                                    <DataTable
                                        // sortField="updatedAt" sortOrder={-1}
                                        paginator rows={5}
                                        scrollable
                                        scrollHeight="400px"
                                        metaKeySelection={false}
                                        value={adminAddedMatches} tableStyle={{ minWidth: '50rem', borderSpacing: '0px' }} stripedRows
                                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                        filterDisplay="row"
                                        filters={filters}
                                        // header={_header}
                                        loading={loading}
                                        // globalFilterFields={['status']}
                                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                        // paginator rows={2} rowsPerPageOptions={[5, 10, 25, 50]}
                                        selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="_id"
                                        onRowSelect={onRowSelect} onRowUnselect={onRowUnselect}>
                                        {
                                            Object.keys(header).map(headerKey => {
                                                const { sortable, title, type, tagType, tagOverrideField, filter, filters } = header[headerKey]
                                                let additionalFilter = { key: title }
                                                if (filter) {
                                                    additionalFilter.filter = true
                                                    additionalFilter.showFilterMenu = false
                                                    additionalFilter.filterMenuStyle = { width: '14rem' }
                                                    if (filters)
                                                        additionalFilter.filterElement = statusRowFilterTemplate(filters.map(it => typeof (it) === 'string' ? it : it._id))
                                                    // additionalFilter.filterElement = statusRowFilterTemplate
                                                    // if (title === 'Status')
                                                    //     additionalFilter.statusRowFilterTemplate = statusRowFilterTemplate
                                                }
                                                if (title === 'Budget')
                                                    return (<Column key={headerKey} field={headerKey} header={title} dataType="boolean" style={{ minWidth: '1rem' }} body={hasBudgetBodyTemplate} filter filterElement={budgetRowFilterTemplate} />)
                                                if (type === 'TAG' && tagType)
                                                    return <Column key={headerKey} field={headerKey} header={title} body={getTag(tagType, headerKey, tagOverrideField)} {...additionalFilter} ></Column>
                                                if (type === 'DATE') {
                                                    return <Column key={headerKey} field={headerKey} header={title} body={getDate(headerKey, header[headerKey]['dateType'])} {...additionalFilter} ></Column>
                                                }
                                                return (<Column key={headerKey} field={headerKey} header={title} {...additionalFilter} />)
                                            })
                                        }
                                    </DataTable>
                                    <div>
                                        <Button
                                            severity="help"
                                            // margin="10px"
                                            label={addingMatchEnabled ? "Cancel" : "Add Matching Attorney"} onClick={() => {
                                                setData(prev => ({
                                                    ...prev,
                                                    addingMatchEnabled: !prev.addingMatchEnabled
                                                }))
                                            }} style={{ margin: '10px' }} />
                                    </div>
                                    {(formattedLead && addingMatchEnabled) &&
                                        // {(formattedLead && addingMatchEnabled) &&
                                        <div style={{
                                            backgroundColor: '#aedcea',
                                            padding: '1em',
                                            position: 'relative',
                                            width: '100%',
                                            height: 'auto',
                                            overflow: 'auto'
                                        }}>
                                            <SH3 style={{ color: '#000' }}>Add Match</SH3>
                                            <div style={{
                                                // background: '#fff',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                position: 'relative',
                                                height: 'auto'
                                            }}>
                                                <TempAddGeoRegion
                                                    {...newAttorney}
                                                    onRowUnselect={onRowUnselect}
                                                    setShowAddAttModal={setShowAddAttModal}
                                                    getPotentialMatches={getPotentialMatches}
                                                    newAttorney={newAttorney}
                                                    setNewAttorney={setNewAttorney}
                                                    setNewAttorneyField={setNewAttorneyField}
                                                    lead={
                                                        {
                                                            ...lead,
                                                            city: lead['city']?.name,
                                                            cityId: lead['city']?._id,
                                                            state: lead['state'].name,
                                                            stateId: lead['state']._id,
                                                        }}
                                                    price={price} />
                                                {
                                                    contactMethods &&
                                                    <>
                                                        <div style={{
                                                            // background: '#fff',
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'start'
                                                        }}>
                                                            <h4 style={{ textAlign: 'left' }}>Email List</h4>
                                                            <div style={{ background: '#f0f0f0', width: '100%', padding: '10px' }}>
                                                                {contactMethods.emailList.map(it =>
                                                                    <div style={{
                                                                        backgroundColor: '#9ee6bd',
                                                                        // padding: '5px',
                                                                        border: '1px solid #29a35e',
                                                                        width: 'fit-content',
                                                                        borderRadius: '3px'
                                                                    }}>{it}</div>
                                                                )}
                                                            </div>
                                                            <h4 style={{ textAlign: 'left' }}>CRM</h4>
                                                            <div style={{ background: '#f0f0f0', width: '100%', padding: '10px' }}>
                                                                {contactMethods.crm.map(it => {
                                                                    const { name, url } = it
                                                                    return (
                                                                        <div style={{
                                                                            backgroundColor: '#9ee6bd',
                                                                            padding: '5px',
                                                                            border: '1px solid #29a35e',
                                                                            width: 'fit-content',
                                                                            borderRadius: '3px'
                                                                        }}>
                                                                            <h5 style={{ margin: '0' }}>{name}</h5>
                                                                            <p style={{ margin: '0', fontSize: '11px' }}>{url}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {/* <AddAttorneyModal
                                        {...newAttorney}
                                        setShowAddAttModal={setShowAddAttModal} getPotentialMatches={getPotentialMatches}
                                        newAttorney={newAttorney}
                                        setNewAttorney={setNewAttorney}
                                        setNewAttorneyField={setNewAttorneyField}
                                        lead={
                                            {
                                                ...lead,
                                                city: lead['city'].name,
                                                cityId: lead['city']._id,
                                                state: lead['state'].name,
                                                stateId: lead['state']._id,
                                            }}
                                        price={price}
                                    /> */}
                                            </div></div>}
                                </AttorneyContainer>

                                <div style={{
                                    background: '#fff',
                                    marginTop: '30px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <ConfirmationModal
                                        lead={lead}
                                        editableFields={editableFields?.fields}
                                        onClick={async () => {
                                            try {
                                                setServerRes(prev => ({ ...prev, isSending: true }))
                                                let res = await API.sendLead({
                                                    editableFields,
                                                    attorneyId: selectedAttorney?.attorneyId,
                                                    leadId: searchParams?.get('leadId'),
                                                    leadKind: lead.kind,
                                                    geographicRegionId: selectedAttorney?.geographicRegion._id,
                                                    budgetMatchedOn: selectedAttorney?.budgetMatchedOn,
                                                    newGeoRegion: newAttorney,
                                                    token: searchParams?.get('token')
                                                })
                                                console.log(res)
                                                if (res.success) {
                                                    setServerRes(prev => ({ ...prev, isSending: false, color: 'green', didSubmit: true, success: true, msg: res?.msg || 'Unknown' }))
                                                } else {
                                                    console.log(console.log("server res: res.msg", res.msg))
                                                    setServerRes(prev => ({ ...prev, isSending: false, color: 'red', didSubmit: false, success: false, msg: res?.msg || 'Unknown' }))
                                                }
                                            } catch (error) {
                                                console.log(error)

                                                // setServerRes(prev => ({ ...prev, didSubmit: false, success: false, msg: error?.response || 'Unknown' }))
                                            }
                                        }}
                                        attorney={newAttorney && newAttorney.attorneyId ? newAttorney : selectedAttorney}
                                    />

                                    {/* <Button
                                style={{ width: '95%', margin: '10px auto 0' }}
                                label="Submit" disabled={(selectedAttorney === null && newAttorney.attorneyId === null) || (serverRes.didSubmit && serverRes.success) || serverRes.isSending} onClick={async () => {
                                    try {
                                        console.log("editableFields", editableFields)
                                        return
                                        setServerRes(prev => ({ ...prev, isSending: true }))
                                        let res = await API.sendLead({
                                            editableFields,
                                            attorneyId: selectedAttorney?.attorneyId,
                                            leadId: searchParams?.get('leadId'),
                                            leadKind: lead.kind,
                                            geographicRegionId: selectedAttorney?.geographicRegion._id,
                                            budgetMatchedOn: selectedAttorney?.budgetMatchedOn,
                                            newGeoRegion: newAttorney,
                                            token: searchParams?.get('token')
                                        })
                                        console.log(res)
                                        if (res.success) {
                                            setServerRes(prev => ({ ...prev, isSending: false, color: 'green', didSubmit: true, success: true, msg: res?.msg || 'Unknown' }))
                                        } else {
                                            console.log(console.log("server res: res.msg", res.msg))
                                            setServerRes(prev => ({ ...prev, isSending: false, color: 'red', didSubmit: false, success: false, msg: res?.msg || 'Unknown' }))
                                        }
                                    } catch (error) {
                                        console.log(error)
                                    }
                                }} /> */}

                                </div>
                            </>
                        }
                        <div style={{ width: '100%', padding: '1em', background: 'white' }}>
                            {
                                (serverRes && (!serverRes.success || serverRes.success)) && <p style={{ textAlign: 'center', color: serverRes?.color || 'red' }}>{serverRes?.msg}</p>
                            }
                        </div>

                    </div>

                </PageContentContainer>

            </>
        )
}

const LeadContactDetails = styled.div`
            display: flex;
            gap: 0.5em;
            font-size: 12px;
>div{
                margin: 0.5em 0;
            user-select: all;
            background-color: aquamarine;
            color: #0a3627;
            font-weight: bold;
            border: 1px solid #0a3627;
            width: fit-content;
            padding: 0.5em;
            border-radius: 0.5em;
}`;

const ConfirmationModal = ({ onClick, attorney, editableFields, lead }) => {
    const [data, setData] = useState({})

    useEffect(() => {
        setData(() => {
            if (editableFields) {
                return Object.fromEntries(Object.entries(editableFields).filter((field) => field[1].initialValue !== field[1].value))
            }
            else
                return {}
        })
    }, [editableFields])
    if (!attorney) {
        return <></>
    }
    return (
        <>
            <RadixDialog
                onClick={onClick}
                maxWidth={"900px"}
                triggerProps={{ color: '#fff', bgd: 'green' }}
                triggerButtonText='Review & Send'
                title={"Confirm & Send"}
                buttonText={`Yes, Send Lead.`}
            // buttonText={`Yes, Send Lead to ${attorney?.firmName}`}
            >
                <LeadContactDetails onClick={(e) => {
                    if (e.target?.innerText && navigator && navigator.clipboard)
                        navigator.clipboard.writeText(e.target?.innerText)
                }}>{(lead?.user) && <>
                    <div>{lead.user.name}</div>
                    <div>{lead.user.email}</div>
                    <div>{lead.phoneNumberFull}</div>
                </>}</LeadContactDetails>
                <TwoColumn >{
                    Object.keys(data).map(dataKey => {
                        let d = data[dataKey]
                        let { value, initialValue } = d
                        if (d.type === 'dropdown') {
                            if (typeof (d.options === 'object')) {
                                let options = d.options['group-1']?.options
                                if (options) {
                                    value = (options.find(option => option.value === value)).label
                                    initialValue = (options.find(option => option.value === initialValue)).label
                                }
                            }
                        }
                        return (
                            <>
                                <Text was>
                                    <Label was>{d.label}</Label>
                                    {initialValue}</Text>
                                <Text>

                                    <Label >{d.label}</Label>
                                    {value}</Text></>)
                    })}
                </TwoColumn>
                {
                    attorney?.internalNotes &&
                    <>
                        <h4 style={{ marginBottom: '0.5em', marginTop: '1.5em' }}>Internal Notes About Client</h4>
                        <p style={{ marginTop: '0' }}>{attorney?.internalNotes}</p>
                    </>
                }
                <p style={{ textAlign: 'right' }}>Send lead to <Span>{attorney?.firmName}</Span> for <Span>{
                    attorney.price ? ['0', '-', '$0'].includes(attorney.price) ? 'FREE' : attorney.price : ''}</Span>?</p>
            </RadixDialog>
        </>)
}

export const Label = styled.h5`
            text-decoration: none !important;
            position: absolute;
            font-size: 10px;
            top: 0.5em;
            left: ${({ was }) => was ? '0.5em' : ''};
            margin: 0;
            `;

export const TwoColumn = styled.div`
            display: grid;
            grid-template-columns: 1fr 1fr;

            position: relative;
            div{
                padding: .95em .75em .75em;
            position: relative;
            border: 1px solid #002900;
/* border-offset: -1px; */
}
            div:nth-of-type(2n + 1){
                background-color: #ff000033;
            border: 1px solid #470101;
}
            div:nth-of-type(2n ){
                background-color: #00ff004f;
            border: 1px solid #470101;
}
            `;

export const Span = styled.span`
            font-weight: bold;
            color: green;
            `;

export const Text = styled.div`
            color: ${({ was }) => was ? 'red' : 'green'};
            text-decoration: ${({ was }) => was ? 'line-through' : 'none'};
            `;

export default SendFromEmailTable