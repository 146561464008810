import React, { useEffect, useState, useRef } from 'react'
import InputField from './InputField'
import PageContentContainer from './PageContentContainer'
import API from '../API'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
// import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/saga-green/theme.css"
import dayjs from 'dayjs'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog as PrimeDialog } from 'primereact/dialog';
// dayjs().format()
import 'primeicons/primeicons.css';
import styled from 'styled-components'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../contexts/AuthContext'
import TextArea from './TextArea';
import Toggle from './Toggle';
import { Checkbox } from 'primereact/checkbox';
import axios from 'axios';
import { Chip } from 'primereact/chip';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDialog from './AlertDialog';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: 'now',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1M",
        MM: "%dM",
        y: "a year",
        yy: "%d years"
    }
})

export const ChipBag = styled.div`
>div{
    margin: 10px;
}
`;

export const TimeWindowAdditionWrapper = styled.div`
background-color: #f0fafc;
display: flex;
flex-direction: column;
align-items: center;
border: 1px solid #c3c7cb;
padding-bottom: 20px;
`;

export const DaysWrapper = styled.div`
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-auto-flow: row;
    width: 100%;
    position: relative;
    row-gap: 5px;
    label{
        text-align: left;
    }
`
export const DayLabel = styled.label`
    display: flex;
    color: #434a4f;
    font-size: 1.2rem;
    background-color: rgba(205, 224, 235,50%);
    border: 1px solid #c3c7cb;
    padding: 15px 10px;
    width: 100%;
    max-width: 400px;
    span{
        margin-left: 5px;
    }
`;


const SectionWrapper = styled.div`
padding: 10px;
display: grid;
grid-template-columns: 1fr;
grid-auto-flow: row;
grid-auto-rows: auto;
align-items: center;
justify-items: center;
gap: 1em;
background-color: ${({ bg }) => bg};
h3, p {
    margin: 0;
}
.dropdownContainer{
    display: flex;
    align-items: center;
    gap: 10px;
}
`;

const statusBodyTemplate = (dispute, fieldKey, tagOverrideField = null) => {
    return <Tag value={dispute[fieldKey]} severity={getSeverity(dispute, fieldKey, tagOverrideField)}></Tag>;
};

const H3 = styled.h3`
color: var(--formBlue1);
`;

const getSeverity = (dispute, fieldKey, tagOverrideField = null) => {
    switch (typeof (dispute) === 'string' ? dispute : dispute[tagOverrideField ? tagOverrideField : fieldKey]) {
        case 'ACCEPTED':
            return 'success';

        case 'PENDING':
            return 'warning';

        case 'REJECTED':
            return 'danger';
        case 'State':
            return 'info';

        case 'County':
            return 'info';

        case 'City':
            return 'info';
        default:
            return null;
    }
};

const AcceptedDisputeDialog = ({ status, adminNotes }) => {
    return (
        <>
            <H3>Admin</H3>
            <h4>Status</h4>
            <p>{status || '-'}</p>
            <h4>Notes</h4>
            <p>{adminNotes || '-'}</p>
        </>)
}

const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
};

const GridContainer = styled.div`
display: grid;
grid-template: auto / 1fr;
gap: 5px;
background-color: aliceblue;
`;

const TimeWindowInputContainer = styled.div`
display: grid;
grid-template-columns: auto auto;
grid-auto-flow: row;
grid-template-rows: min-content;
column-gap: 3px;
row-gap: 10px;
/* justify-items: center; */
align-items: center;
    label{
        text-align: left;
        /* margin-right: 5px; */
    }
`;


const DisputeDialogBody = (dispute) => {
    const { shortId, reasonForDispute, comments, status, updatedAt, lead: { email, name, updatedAt: updatedAtLead, phoneNumber } } = dispute
    return (<>
        <H3>Dispute</H3>
        <h4>Dispute Id</h4>
        <p>{shortId}</p>
        <h4>Submitted</h4>
        <p>{getDate('createdAt')(dispute)}</p>
        <h4>Reason</h4>
        <p>{reasonForDispute['name']}</p>
        <h4>Dispute Comments</h4>
        <p>{comments}</p>
        <h4>Updated</h4>
        <p>{getDate('updatedAt')(dispute)}</p>


        {
            status !== "PENDING" && <AcceptedDisputeDialog {...dispute} />
        }
        <H3>Lead</H3>
        <h4>Name</h4>
        <p>{name}</p>
        <h4>Phone</h4>
        <p>{phoneNumber}</p>
        <h4>Email</h4>
        <p>{email}</p>
        <h4>Lead Received</h4>
        <p>{getDate('lead.updatedAt')(dispute)}</p>
    </>)
}

const Dialog = ({ visible, setVisible, text }) => {
    return (<PrimeDialog header="Details" visible={visible} style={{ width: '50vw' }} position="bottom" onHide={() => setVisible(false)}>
        {/* <p className="m-0"> */}
        {text}
        {/* </p> */}
    </PrimeDialog>)
}


String.prototype.padLeft = function (length, character) {
    return new Array(length - this.length + 1).join(character || ' ') + this;
};

Date.prototype.toFormattedString = function () {
    return [String(this.getMonth() + 1).padLeft(2, '0'),
    String(this.getDate()).padLeft(2, '0'),
    String(this.getFullYear()).slice(-2)].join("/") + " " +
        [String(this.getHours()).padLeft(2, '0'),
        String(this.getMinutes()).padLeft(2, '0')].join(":");
};

const getObjDeeperLayer = (a, c) => {
    if (typeof (a) !== 'object' || a === null)
        return a
    let b = c.split('.')
    return (b.reduce((_a, _b) => {
        return (_a[_b])
    }, a))
}

const dateFormatByType = (date, dateType) => {
    switch (dateType) {
        case "FROM-NOW":
            return dayjs(date).fromNow()
        default:
            return dayjs(date).format('ddd, MMM DD')
    }
}

const getDate = (fieldKey, dateType = null) => (dispute) => {
    let date = getObjDeeperLayer(dispute, fieldKey)
    if (!date)
        return (<></>)
    return (dateFormatByType(date, dateType))
}

const getTag = (tagType, fieldKey, tagOverrideField = null) => (dispute) => {
    return statusBodyTemplate(dispute, fieldKey, tagOverrideField)
}

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

const LeadDelivery = ({ _active }) => {
    const { id } = useParams();
    const navigate = useNavigate()
    const toast = useRef(null);
    const { currentUser, currentUser: { user } } = useAuth()
    const initialRef = useRef(true)
    const [data, setData] = useState({
        isEnabled: true,
        dialogue: {
            visible: false,
            text: ""
        }
    })

    const { dialogue: { text }, dialogue } = data
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(true);

    const [config, setConfig] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);
    const [overrideTimeWindows, setOverrideTimeWindows] = useState(false);
    const [globalOverride, setGlobalOverride] = useState(false);
    const [timeWindows, setTimeWindows] = useState([]);
    const [newWindow, setNewWindow] = useState({ start: '', end: '', days: [] });
    const [newPracticeArea, setNewPracticeArea] = useState(null)
    const [newGeographicArea, setNewGeographicArea] = useState(null)
    const [newGeographicAreaExclusion, setNewGeographicAreaExclusion] = useState(null)
    const [newPracticeAreaExclusion, setNewPracticeAreaExclusion] = useState({ _id: null, name: null })
    const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


    const [excludedPracticeAreaIds, setExcludedPracticeAreaIds] = useState([]);
    const [practiceAreaIds, setPracticeAreaIds] = useState([]);
    const [regionIds, setRegionIds] = useState([]);
    const [excludedRegionIds, setExcludedRegionIds] = useState([]);
    const [allGeographicRegions, setAllGeographicRegions] = useState([]);
    const [allPracticeAreas, setAllPracticeAreas] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedRegionType, setSelectedRegionType] = useState(null);

    const [regionIdOptions, setRegionIdOptions] = useState([])

    const regionTypes = ['City', 'County', 'State'];

    const getData = async () => {
        try {
            const data = await API.getAutoSendConfig(id)
            console.log("data", data)
            const { regionIds, isEnabled, timeWindows, overrideTimeWindows, excludedRegionIds, practiceAreaIds, excludedPracticeAreaIds } = data.config;
            setConfig(data.config);
            setIsEnabled(isEnabled);
            setOverrideTimeWindows(overrideTimeWindows)
            setTimeWindows(timeWindows);
            setLoading(false)
            setExcludedRegionIds(excludedRegionIds || []);
            setExcludedPracticeAreaIds(excludedPracticeAreaIds || []);
            setRegionIds(regionIds || []);
            setPracticeAreaIds(practiceAreaIds || []);
            setAllGeographicRegions(data.allGeographicRegions || [])
            setAllPracticeAreas(data.allPracticeAreas)
            setRegionIdOptions(data.regionIdOptions || [])
        } catch (error) {

        }
    }

    const handleToggleEnabled = () => {
        const updatedConfig = { ...config, isEnabled: !isEnabled };
        updateConfig(updatedConfig);
    };

    const handleToggleOverrideTimeWindows = () => {
        const updatedConfig = { ...config, overrideTimeWindows: !overrideTimeWindows };
        updateConfig(updatedConfig);
    };

    const updateConfig = async (updatedConfig) => {
        const res = await API.updateAutoSendConfig(updatedConfig)
        setConfig(res);
        setIsEnabled(res.isEnabled);
        setGlobalOverride(res.globalOverride);
        setOverrideTimeWindows(res.overrideTimeWindows)
        setTimeWindows(res.timeWindows);
        setExcludedRegionIds(res.excludedRegionIds || []);
        setRegionIds(res.regionIds || [])
        setPracticeAreaIds(res.practiceAreaIds || []);
    };

    const handleAddPracticeArea = (practiceArea) => {
        const updatedPracticeAreaIds = [...practiceAreaIds, practiceArea];
        const updatedConfig = { ...config, practiceAreaIds: updatedPracticeAreaIds };
        updateConfig(updatedConfig);
        setNewPracticeArea(null)
    };

    const handleAddGeoRegion = (geographicArea) => {
        const updatedRegionIds = [...regionIds, geographicArea];
        const updatedConfig = { ...config, regionIds: updatedRegionIds };
        updateConfig(updatedConfig);
        setNewGeographicArea(null)
    };

    const handleRemoveGeographicRegion = (index) => {
        const updatedRegionIds = regionIds.filter((_, i) => i !== index);
        // setRegionIds(updatedRegionIds);
        updateConfig({ ...config, regionIds: updatedRegionIds });
    };

    const handleAddExcludedRegion = (region) => {
        const updatedExcludedRegionIds = [...excludedRegionIds, region];
        // setExcludedRegionIds(updatedExcludedRegionIds);
        updateConfig({ ...config, excludedRegionIds: updatedExcludedRegionIds });
    };

    const handleRemoveExcludedRegion = (index) => {
        const updatedExcludedRegionIds = excludedRegionIds.filter((_, i) => i !== index);
        console.log("updatedExcludedRegionIds", updatedExcludedRegionIds, excludedRegionIds)
        // setExcludedRegionIds(updatedExcludedRegionIds);
        updateConfig({ ...config, excludedRegionIds: updatedExcludedRegionIds });
    };

    const handleRemovePracticeArea = (practiceAreaIndex) => {

        const updatedTimeWindows = practiceAreaIds.filter((_, i) => i !== practiceAreaIndex);
        const updatedConfig = { ...config, practiceAreaIds: updatedTimeWindows };
        updateConfig(updatedConfig);
    };

    const handleAddExcludedPracticeArea = (practiceArea) => {
        const updatedPracticeAreaIds = [...excludedPracticeAreaIds, practiceArea];
        const updatedConfig = { ...config, excludedPracticeAreaIds: updatedPracticeAreaIds };
        updateConfig(updatedConfig);
    };

    const handleRemoveExcludedPracticeArea = (practiceAreaIndex) => {

        const updatedTimeWindows = excludedPracticeAreaIds.filter((_, i) => i !== practiceAreaIndex);
        const updatedConfig = { ...config, excludedPracticeAreaIds: updatedTimeWindows };
        updateConfig(updatedConfig);
    };

    const handleAddTimeWindow = () => {
        const updatedTimeWindows = [...timeWindows, newWindow];
        const updatedConfig = { ...config, timeWindows: updatedTimeWindows };
        updateConfig(updatedConfig);
        setNewWindow({ start: '', end: '', days: [] });
    };

    const handleRemoveTimeWindow = (index) => {
        const updatedTimeWindows = timeWindows.filter((_, i) => i !== index);
        const updatedConfig = { ...config, timeWindows: updatedTimeWindows };
        updateConfig(updatedConfig);
    };

    const handleSearch = async () => {
        if (!searchQuery || !selectedRegionType) return;
        let data = await API.searchRegionsForAutoSendConfig(searchQuery, selectedRegionType)
        setSearchResults(data);
    };


    const renderDaysTemplate = (rowData) => {

        return rowData.days.map(day => daysMap[day]).join(', ');
    };

    const [customers, setCustomers] = useState([])
    useEffect(() => {

        getData()

    }, [])

    const setDialogue = (visibility, text) => {
        setData(prev => ({
            ...prev,
            dialogue: {
                visible: visibility,
                text
            }
        }))
        setVisible(visibility)
    }
    console.log("newPracticeArea", newPracticeArea)
    return (
        <>
            <PageContentContainer margin="0" column padding="50px 0 0" maxHeight="100%" height="100vh" justifyContent="start">
                <Button onClick={() => navigate('/lead-delivery/')}>Back</Button>
                <Toast ref={toast} />
                <div className='card'
                    style={{ width: '95%', position: 'relative', display: 'grid', gridAutoRows: 'auto', gridAutoFlow: 'row', gridTemplateColumns: '1fr', gap: '10px' }}>
                    <h1 style={{ textAlign: 'left' }}>Lead Delivery Settings</h1>
                    <div>
                        <Toggle
                            label="Enable Auto-Send"
                            showLabelText={true}
                            _checked={isEnabled}
                            _setChecked={handleToggleEnabled}
                        /></div>
                    <div style={{ background: '#ebf3f6', padding: '10px' }}>
                        <p style={{ textAlign: 'left' }}>If Auto-Send is enabled and time windows exist, this allows you to run "Auto-Send" at all times, regardless of whatever values are in timeWindows (without having to remove them)</p>
                        <Toggle
                            label="Override Time Windows"
                            showLabelText={true}
                            _checked={overrideTimeWindows}
                            _setChecked={handleToggleOverrideTimeWindows}
                            uncheckedBgd="#6d6d6d"
                        />
                    </div>
                    <h3>Time Windows</h3>
                    <DataTable value={timeWindows}>
                        <Column field="start" header="Start Time" />
                        <Column field="end" header="End Time" />
                        <Column field="days" header="Days" body={renderDaysTemplate} />
                        <Column
                            body={(rowData, { rowIndex }) => (
                                <Button label="Remove" onClick={() => handleRemoveTimeWindow(rowIndex)} />
                            )}
                        />
                    </DataTable>
                    <TimeWindowAdditionWrapper>
                        <h3>Add New Time Window</h3>
                        <div className="p-grid">
                            <TimeWindowInputContainer>
                                <label htmlFor="start">Start Time (HH:mm)</label>
                                <InputText id="start" value={newWindow.start} onChange={(e) => setNewWindow({ ...newWindow, start: e.target.value })} />

                                <label htmlFor="end">End Time (HH:mm)</label>
                                <InputText id="end" value={newWindow.end} onChange={(e) => setNewWindow({ ...newWindow, end: e.target.value })} />
                            </TimeWindowInputContainer>

                            <DaysWrapper >
                                {
                                    daysMap.map((day, index) => {
                                        console.log(day)
                                        return (<>
                                            <DayLabel htmlFor={day.toLocaleLowerCase()} key={day}>
                                                <Checkbox inputId={day.toLocaleLowerCase()} value={index} checked={newWindow.days.includes(index)} onChange={(e) => {
                                                    const selectedDays = [...newWindow.days];
                                                    if (e.checked) selectedDays.push(index);
                                                    else selectedDays.splice(selectedDays.indexOf(index), 1);
                                                    setNewWindow({ ...newWindow, days: selectedDays });
                                                }} key={day} />
                                                <span key={day}>{day}</span>
                                            </DayLabel>
                                        </>)
                                    })
                                }
                                {/* <span className="p-float-label">
                                    <Checkbox inputId="sunday" value={0} checked={newWindow.days.includes(0)} onChange={(e) => {
                                        const selectedDays = [...newWindow.days];
                                        if (e.checked) selectedDays.push(0);
                                        else selectedDays.splice(selectedDays.indexOf(0), 1);
                                        setNewWindow({ ...newWindow, days: selectedDays });
                                    }} />
                                    <label htmlFor="sunday">Sunday</label>
                                    <Checkbox inputId="monday" value={1} checked={newWindow.days.includes(1)} onChange={(e) => {
                                        const selectedDays = [...newWindow.days];
                                        if (e.checked) selectedDays.push(1);
                                        else selectedDays.splice(selectedDays.indexOf(1), 1);
                                        setNewWindow({ ...newWindow, days: selectedDays });
                                    }} />
                                    <label htmlFor="monday">Monday</label>
                                </span> */}
                            </DaysWrapper>
                        </div>
                        <Button label="Add Time Window" onClick={handleAddTimeWindow} style={{ marginTop: '20px' }} />
                    </TimeWindowAdditionWrapper>

                    <SectionWrapper bg="#e5f7de">
                        <h3>Practice Areas</h3>
                        <p>Only leads from within these practice areas will be auto-matched</p>
                        <div className="dropdownContainer">
                            <Dropdown value={newPracticeArea} options={allPracticeAreas} onChange={(e) => setNewPracticeArea(e.value)} optionLabel="name" placeholder="Select a Practice Area" />
                            <Button label="Add Practice Area" onClick={() => { handleAddPracticeArea(newPracticeArea) }} />
                        </div>
                        <ChipBag>
                            {config?.practiceAreaIds.map((practiceArea, practiceAreaIndex) => (
                                <Chip key={practiceArea.name} label={practiceArea.name} removable onRemove={() => handleRemovePracticeArea(practiceAreaIndex)} />
                            ))}

                        </ChipBag>
                    </SectionWrapper>
                    <SectionWrapper bg="#f7e4e4">
                        <h3>Excluded Practice Areas</h3>
                        <p>Leads matching these practice areas <strong>will not</strong> be automatched</p>
                        <div className="dropdownContainer">
                            <Dropdown value={newPracticeAreaExclusion} options={allPracticeAreas} onChange={(e) => setNewPracticeAreaExclusion(e.value)} optionLabel="name" placeholder="Select a Practice Area" />
                            <Button label="Exclude Practice Area" onClick={() => { handleAddExcludedPracticeArea(newPracticeAreaExclusion) }} />
                        </div>
                        <ChipBag>
                            {config?.excludedPracticeAreaIds.map((practiceArea, practiceAreaIndex) => (
                                <Chip key={practiceArea.name} label={practiceArea.name} removable onRemove={() => handleRemoveExcludedPracticeArea(practiceAreaIndex)} />
                            ))}

                        </ChipBag>
                    </SectionWrapper>
                    <SectionWrapper bg="#e5f7de">
                        <h3>Geo Regions</h3>
                        <p>Only leads from within these geo regions will be auto-matched</p>
                        <div className="dropdownContainer">
                            <Dropdown value={newGeographicArea} options={allGeographicRegions} onChange={(e) => setNewGeographicArea(e.value)} optionLabel="displayName" placeholder="Select a Geo Area" />
                            <Button label="Add Geo Area" onClick={() => { handleAddGeoRegion(newGeographicArea) }} />
                        </div>
                        <ChipBag>
                            {config?.regionIds.map((geographicRegion, geographicRegionIndex) => {
                                let label = `${geographicRegion.regionName}${geographicRegion.regionType === 'County' ? ' County' : ''}${geographicRegion.regionType !== 'State' ? `, ${geographicRegion.regionId?.ste_name}` : ''}`
                                return (
                                    <Chip key={label} label={label} removable onRemove={() => handleRemoveGeographicRegion(geographicRegionIndex)} />
                                )
                            })}

                        </ChipBag>
                    </SectionWrapper>

                    <SectionWrapper bg="#f7e4e4">
                        <h3 >Excluded Regions</h3>
                        <p >Leads coming in from the following areas will *not* be auto matched. For example, if "Florida" set under "Geo Regions"; only leads from "Florida" will be auto-sent. However, if "Excluded Regions" includes "Miami-Dade County", <strong>only Florida leads outside of Miami-Dade County will be auto-sent</strong> </p>
                        <p>Note that this means that if any records exist under "Geo Regions", "Excluded Regions" should only ever be a subset of these regions (i.e. a county or city within a state)</p>
                        <div className="dropdownContainer">

                            <Dropdown value={newGeographicAreaExclusion} options={allGeographicRegions} onChange={(e) => setNewGeographicAreaExclusion(e.value)} optionLabel="displayName" placeholder="Select a Geo Area" />
                            <Button label="Exclude Geo Area" onClick={() => { handleAddExcludedRegion(newGeographicAreaExclusion) }} />
                        </div>
                        <ChipBag >
                            {config?.excludedRegionIds.map((geographicRegion, geographicRegionIndex) => {
                                let label = `${geographicRegion.regionName}${geographicRegion.regionType === 'County' ? ' County' : ''}${geographicRegion.regionType !== 'State' ? `, ${geographicRegion.regionId?.ste_name}` : ''}`
                                return (
                                    <Chip key={label} label={label} removable onRemove={() => handleRemoveExcludedRegion(geographicRegionIndex)} />
                                )
                            })}</ChipBag>
                    </SectionWrapper>
                    <AlertDialog buttonText={"Delete AutoConfig"} description={"Deleting this auto-send configuration is permanent. Consider just disabling via isEnabled"} itemName={"Auto-Send Config"} onClick={async () => {
                        let r = await API.deleteConfig(id)
                        if (r)
                            navigate(`/lead-delivery/`)
                    }} />
                    {/* <div className="p-field p-grid">
                        <div className="p-col-6">
                            <Dropdown value={selectedRegionType} options={regionTypes} onChange={(e) => setSelectedRegionType(e.value)} placeholder="Select Region Type" />
                        </div>
                        <div className="p-col-6">
                            <InputText value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Regions" />
                            <Button label="Search" icon="pi pi-search" onClick={handleSearch} />
                        </div>
                    </div>
                    <div className="search-results">
                        {searchResults.map((result, index) => (
                            <div key={index} className="search-result-item">
                                <span>{result.name}</span>
                                <Button label="Add" icon="pi pi-plus" onClick={() => handleAddExcludedRegion(result)} />
                            </div>
                        ))}
                    </div> */}

                    {/* <Button >Update</Button> */}

                </div>
                {/* <Dialog {...dialogue} setVisible={setVisible} visible={visible} getData={getData} /> */}
            </PageContentContainer>

        </>
    )
}

export default LeadDelivery