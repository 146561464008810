import React, { useState, useEffect, useRef } from 'react'
import GridItem from '../GridItem'
import InputField from '../InputField'
import Flexbox from '../Flexbox'
import Icon from '../Icon'
import API from '../../API'

import cancel from '../../images/close.svg'
import accept from '../../images/acceptIcon.svg'

import { formatPrice, formatPriceInputField } from '../../utils'

const TableCellWithInputField = ({ minWidth, onChange, padding = "0 26px", inputFieldName = 'price', formatInputFieldValue, formatCellValue, formatCellValueDisplay, defaultValue, regionType, regionId, onPriceUpdate, onSubmit }) => {
    console.log("defaultValue", defaultValue)
    const [value, setValue] = useState(formatCellValue(defaultValue))
    const [show, setShow] = useState(false)

    const triggerFocus = useRef(false)

    const onKeyDown = async (e) => {
        const { key } = e;
        if (key === 'Enter') { //fast type problem, ensure delay
            await _onSubmit()
        }
    }
    const _onSubmit = async () => {
        if (typeof (onSubmit) === 'function')
            await onSubmit(value)
        setShow(false)
    }

    useEffect(() => {
        if (show)
            triggerFocus.current = true
    }, [show])



    return (
        <>

            <GridItem justify="start" gap="5px">
                {
                    show && <Flexbox zIndex="1" position="fixed" background="none" width="100vw" height="100vh" top="0" right="0" onClick={() => setShow(!show)}></Flexbox>}
                <Flexbox flexGrow="1" width="100%" >
                    {!show &&
                        <Flexbox
                            padding={padding}
                            onClick={() => {
                                setShow(!show)
                            }}>
                            {!show && formatCellValueDisplay(value)}
                        </Flexbox>}
                    <Flexbox
                        position="relative" zIndex="2" display={show ? 'flex' : 'none'} gap="5px">
                        <InputField
                            height="2.5em"
                            fontSize="12px"
                            margin="0"
                            onKeyDown={onKeyDown}
                            triggerFocus={show}
                            border="none"
                            padding={padding}
                            name={inputFieldName}
                            minWidth={minWidth}
                            value={value}
                            onChange={(e) => {
                                setValue(formatInputFieldValue(e.target.value))
                                if (typeof (onChange) === 'function')
                                    onChange(formatCellValue(e.target.value))
                            }}

                        />

                    </Flexbox>
                </Flexbox>
                {
                    value !== formatCellValue(defaultValue) &&
                    <>
                        <Icon zIndex="2" src={accept} onClick={async () => await _onSubmit()} />
                        <Icon zIndex="2" src={cancel} onClick={() => {
                            setValue(formatCellValue(defaultValue))
                            if (typeof (onChange) === 'function')
                                onChange(formatCellValue(defaultValue))
                        }} />
                    </>
                }
            </GridItem >
        </>
    )
}

export default TableCellWithInputField