import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-weight: ${props => props.labelWeight ? props.labelWeight : ''};
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;


export const InputStyled = styled.input`
    font-size: ${({ fontSize = "16px" }) => fontSize};
    height: ${({ height = '49px' }) => height};
    margin: ${({ margin = '2px' }) => margin};
    padding: ${({ padding }) => padding || '0 10px'};
    width: ${({ width = "100%" }) => width};
    color: var(--fontColor);
    background: ${({ background }) => background || "var(--inputBackground)"};
    border: ${({ border }) => border || 'var(--inputBorder)'};
    border-bottom: ${({ borderBottom }) => borderBottom};
    outline: ${({ outline }) => outline || 'var(--inputOutline)'};
    position: relative;
    transition: ${({ transition }) => transition};
    min-width:${({ minWidth }) => minWidth};
    ::placeholder{
        color: var(--inputPlaceHolderColor);
    }

    :focus{
        outline: ${({ focusOutline }) => focusOutline || 'var(--inputFocusOutline)'};
        border: ${({ focusBorder }) => focusBorder || 'var(--inputFocusBorder)'};
    }
    :disabled{
        outline: 1px solid #737373;
        border: 1px solid #737373;
        background: #c7c7c7;
        cursor: not-allowed;
        color: #737373;
    }

`;

export const Wrapper = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : ''};
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.marginTop ? props.marginTop : '0'};
  width: ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: ${({ dollarSignPos = "1rem" }) => dollarSignPos};
z-index: 1;
`;

export const AlertIconWrapper = styled.div`
position: absolute;
right: 5px;
`;

export const SearchIcon = styled.img`
height: 15px;
width: 15px;
cursor: pointer;
opacity: ${props => props.opacity ? props.opacity : '0.25'};
position: absolute;
right: 1rem;
user-select: none;
`;