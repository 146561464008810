import axios from "axios";
import Cookies from "js-cookie";

import { enforceArray } from './utils'

const headers = {
    'Content-Type': 'application/json',
    withCredentials: true,
    Authorization: JSON.parse(localStorage.getItem('token'))
}

const api = {
    submitForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/form/submit/createClient`,
            headers: {
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: JSON.parse(localStorage.getItem('token'))
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    login: async (data) => {
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/clients/login`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: { ...data, username: data['email'] }
        });
        return res['data']
    },
    updateAttorney: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/users/updateAttorney`,
            headers: {
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: JSON.parse(localStorage.getItem('token'))
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    updateClient: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/users/updateClient`,
            headers: {
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: JSON.parse(localStorage.getItem('token'))
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    modifyLeadToSend: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });

        try {

            let res = await axios({
                method: 'post', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/modifyLeadToSend`,
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                },
                data: data
            });

            return res.data
        } catch (error) {
            const { response: { status, data: { msg } } } = error
            return { status, msg }
        }
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    addGeoRegion: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });

        try {

            let res = await axios({
                method: 'post', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/geographicRegions/addGeographicRegion`,
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                },
                data: data
            });

            return res.data
        } catch (error) {
            const { response: { status, data: { msg } } } = error
            return { status, msg }
        }
    },
    updatePlace: async (type, id, price, returnPlacesArray = false) => {
        try {

            const params = { type, id, price, returnPlacesArray }
            let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/geographicRegions/updatePlace`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: JSON.parse(localStorage.getItem('token'))
                    },
                    params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
                })

            return res.data
        } catch (error) {
            const { response: { status, data: { msg } } } = error
            return { status, msg }
        }

    },
    getPricing: async (data, userId = null) => {
        try {
            let params = {
                place: [],
                county: [],
                state: [],
                userId
            }
            let _data = enforceArray(data)
            _data.forEach(d => {
                console.log("d", d)
                params[d.type].push(d.key)
            })
            let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/geographicRegions/getPricing`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: JSON.parse(localStorage.getItem('token'))
                    },
                    params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
                })

            return res.data
        } catch (error) {
            console.log("error oc", error)
            // const { response: { status, data: { msg } } } = error
            // return { status, msg }
            return error
        }
    },
    getFAQ: async () => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/faq/`,
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                },
            });

            return res.data.faq
        } catch (error) {
            console.log(error)
        }

    },
    getContactFormSubmissions: async () => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/getContactFormSubmissions`,
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                },
            });

            return res.data
        } catch (error) {
            console.log(error)
        }

    },
    getPlaces: async (refine = { id: 'x', value: null }) => {
        try {
            const params = { [refine.id]: refine['value'], }

            let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/geographicRegions/getPlaces`,
                { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) } })

            return res.data
        } catch (error) {
            console.log(error)
        }

    },
    getAttorneys: async (currentUser = null, id = null, refine = { id: 'x', value: null }, secondary = []) => {
        console.log("secondary", secondary)
        const params = {
            // email: currentUser.email,
            // access_token: currentUser.accessToken,
            id,
            [refine.id]: refine['value'],
            ...Object.fromEntries(secondary.map(it => Object.entries(it).flat()))
            // [`refine.${refine.id}`]: refine['value'],

            // [{ type, value}, {artist, artist_id},{collection, collection_id}]
        }

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/admin/getAttorneys`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    'Authorization': Cookies.get('jwt')
                }, params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
    },
    getContactMethods: async (attorneyId) => {
        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/admin/getContactMethods?attorneyId=${attorneyId}`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    'Authorization': Cookies.get('jwt')
                }
            })

        return res.data
    },
    getDisputesOld: async (currentUser = null, id = null, refine = { id: 'x', value: null }, secondary = []) => {
        console.log("secondary", secondary)
        const params = {
            // email: currentUser.email,
            // access_token: currentUser.accessToken,
            id,
            // [refine.id]: refine['value'],
            ...Object.fromEntries(secondary.map(it => Object.entries(it).flat()))
            // [`refine.${refine.id}`]: refine['value'],

            // [{ type, value}, {artist, artist_id},{collection, collection_id}]
        }

        if (refine.value !== undefined && refine.value !== null) {
            params[refine.id] = refine['value']
        }
        console.log("[a]refine", refine)
        console.log("[a]params", params)
        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/disputes/getDisputes`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                }, params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
    },
    getGeographicRegion: async (id) => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/geographic-regions/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    getGeographicRegions: async () => {
        return await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/geographic-regions`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Cookies.get('jwt')
            },
        })
    },
    getAllAutoSendConfigs: async () => {
        return await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/auto-send-config/configs`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Cookies.get('jwt')
            },
        })
    },
    updateGeographicRegion: async (budgetUpdate) => {
        return await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_DOMAIN}/geographic-regions/budget-updates`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Cookies.get('jwt')
            },
            data: budgetUpdate
        })
    },
    searchRegionsForAutoSendConfig: async (searchQuery, selectedRegionType) => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/auto-send-config/search-regions`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
                params: { query: searchQuery, type: selectedRegionType }
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    geoRegion__handleSave: async (region, id) => {
        return await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_DOMAIN}/geographic-regions/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Cookies.get('jwt')
            },
            data: region
        })
    },
    getAutoSendConfig: async (id) => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/auto-send-config/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    addNewConfig: async () => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/auto-send-config/addNewConfig`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    deleteConfig: async (id) => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/auto-send-config/delete-config/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    updateAutoSendConfig: async (updateConfig) => {
        try {
            let res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_DOMAIN}/auto-send-config`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
                data: updateConfig
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    getAdminNotes: async (noteCategory) => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/admin-notes`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt'),

                },
                params: { 'noteCategory': noteCategory }
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    updateAdminNotes: async (updateConfig) => {
        try {
            let res = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_DOMAIN}/admin-notes`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
                data: updateConfig
            })
            return res.data
        } catch (error) {
            return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    getDisputes: async (obj) => {
        console.log("obj", obj)
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/admin/getDisputes`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
                params: obj
            });
            return res.data
        } catch (error) {
            console.log(error)
            if (error.repsonse && error.response.data)
                return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    processDispute: async (data) => {
        try {

            let res = await axios({
                method: 'post', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/admin/processDispute`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
                data
            });
            return ({ success: true, msg: res.data['msg'] })
        } catch (error) {
            console.log(error)
            if (error.response && error.response.data)
                return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    sendLead: async (data) => {
        try {
            let headers = {
                'Content-Type': 'application/json',
            }
            if (Cookies.get('jwt')) {
                headers['Authorization'] = Cookies.get('jwt')
            }
            let res = await axios({
                method: 'post', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/admin/sendLead`,
                headers,
                params: { token: data['token'] },
                data,
            });
            return ({ success: true, msg: res.data['msg'] })
        } catch (error) {
            console.log(error)
            if (error.response && error.response.data)
                return ({ success: false, msg: error.response.data['msg'] })
        }
    },
    getLeads: async (currentUser = null, id = null, refine = { id: 'x', value: null }, secondary = []) => {
        console.log("secondary", secondary)
        const params = {
            // email: currentUser.email,
            // access_token: currentUser.accessToken,
            id,
            [refine.id]: refine['value'],
            ...Object.fromEntries(secondary.map(it => Object.entries(it).flat()))
            // [`refine.${refine.id}`]: refine['value'],

            // [{ type, value}, {artist, artist_id},{collection, collection_id}]
        }

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/admin/getLeads`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    'Authorization': Cookies.get('jwt')
                    // Authorization: JSON.parse(localStorage.getItem('token'))
                }, params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
        // return res.data['leads']
    },
    getPotentialMatches: async (leadId) => {
        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/getPotentialMatches?leadId=${leadId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                }
                // , params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
        // return res.data['leads']
    },
    findMatchingAttorneys: async (body) => {
        console.log("token", body['token'])
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/admin/findMatchingAttorneys`,
            headers: {
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: Cookies.get('jwt')
                // Authorization: JSON.parse(localStorage.getItem('token'))
            },
            params: { token: body['token'] },
            data: body
        });
        return res.data
    },
    getLeadDataAndMatches: async (params) => {
        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/admin/getLeadDataAndMatches`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: Cookies.get('jwt')
                },
                params
                // , params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
        // return res.data['leads']
    },
    findEndpoint: async (data) => {
        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/endpoints/findEndpoint`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                },
                params: data
                // , params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
        // return res.data['leads']
    },
    getAttorneyDetailed: async (attorneyId) => {
        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/admin/getAttorneyDetailed?attorneyId=${attorneyId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: Cookies.get('jwt')
                }
                // , params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data
        // return res.data['leads']
    },
    matchLeadToAttorney: async (currentUser = null, leadId) => {
        const params = {
            // email: currentUser.email,
            // access_token: currentUser.accessToken,
            leadId,
            // [`refine.${refine.id}`]: refine['value'],

            // [{ type, value}, {artist, artist_id},{collection, collection_id}]
        }

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/leads/matchLeadToAttorney2`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: JSON.parse(localStorage.getItem('token'))
                }, params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) }
            })

        return res.data['leads']
    },
    searchCounties: async (searchQuery = '', stateArr = []) => {
        try {
            let stateArrString = ''
            // api can only handle 2 states
            if (stateArr.length <= 2)
                stateArrString = stateArr.reduce((a, b, index) => {
                    let str = '&refine.ste_name[]=' + b.replace(' ', '+')
                    a = a + str
                    return a
                }, '')
            let _query = searchQuery.split(',')[0].replaceAll(' ', '+')
            if (searchQuery.split(',').length > 1) {
                let _q = searchQuery.split(',')[1].trim().replaceAll(' ', '+')
                if (_q.length > 0)
                    stateArrString = '&refine.ste_name=' + stateArr.find(el => el.includes(_q))
            }

            let { data: { records } } = await axios({
                method: 'get', // & controllers
                // url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-county&q=${searchQuery}&rows=200&sort=coty_name&facet=year&facet=ste_name`,
                url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-county&q=${_query}&rows=200&sort=coty_name&facet=year&facet=ste_name${stateArrString}`,
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                    // withCredentials: true,
                    // Authorization: JSON.parse(localStorage.getItem('token'))
                },
            });

            return records
        } catch (error) {
            console.log(error)
        }

    },
    searchPlaces: async (searchQuery = '', stateArr = []) => {
        try {
            let stateArrString = ''
            if (stateArr.length <= 2)
                stateArrString = Array.isArray(stateArr) ? stateArr.reduce((a, b, index) => {
                    let str = '&refine.ste_name[]=' + b.replace(' ', '+')
                    a = a + str
                    return a
                }, '') : '&refine.ste_name[]=' + stateArr.replace(' ', '+')

            let _query = searchQuery.split(',')[0].replaceAll(' ', '+')
            if (searchQuery.split(',').length > 1) {
                let _q = searchQuery.split(',')[1].trim().replaceAll(' ', '+')
                if (_q.length > 0)
                    stateArrString = '&refine.ste_name=' + stateArr.find(el => el.includes(_q))
            }


            let { data: { records } } = await axios({
                method: 'get', // & controllers
                url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=${_query}&rows=200&sort=coty_name&exclude.pla_type=CDP&facet=year&facet=ste_name&facet=ste_code&facet=coty_code&facet=coty_name&facet=cousub_code&facet=cousub_name&facet=pla_code&facet=pla_name&facet=pla_type${stateArrString}`,
                // url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=${searchQuery.replaceAll(' ','+')}&rows=200&sort=coty_name&exclude.pla_type=CDP&facet=year&facet=ste_name&facet=ste_code&facet=coty_code&facet=coty_name&facet=cousub_code&facet=cousub_name&facet=pla_code&facet=pla_name&facet=pla_type`,
                headers: {
                    // 'Content-Type': 'application/json',
                    // withCredentials: true,
                    // Authorization: JSON.parse(localStorage.getItem('token'))
                },
            });

            return records
        } catch (error) {
            console.log(error)
        }

    },
    getDefaultAdminNotes: async (data) => {
        try {
            let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/adminNotes/getDefaultAdminNotes`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: JSON.parse(localStorage.getItem('token'))
                    },
                    params: { ...Object.fromEntries(Object.entries(data).filter(it => it[1] !== undefined && it[1] !== null)) }
                })

            return res.data
        } catch (error) {
            console.log("error oc", error)
            return error
        }
    },
    account: async (token) => {
        try {
            let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/disputes/processDisputeFromEmail`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: JSON.parse(localStorage.getItem('token'))
                    },
                    params: token
                })
            console.log(res.data)
            return res.data
        } catch (error) {
            console.log("error oc", error)
            return error
        }
    }
    // searchPlaces: async (searchQuery = null, stateArr = []) => {
    //     try {
    //         const stateArrString = Array.isArray(stateArr) ? stateArr.reduce((a, b, index) => {
    //             let str = '&refine.ste_name=' + b.replace(' ', '+')
    //             a = a + str
    //             return a
    //         }, '') : '&refine.ste_name=' + stateArr.replace(' ', '+')
    //         console.log("state arr string", stateArrString)
    //         const params = {
    //             q: searchQuery.replaceAll(' ', '+'),
    //             sort: 'coty_name',
    //             facet: 'ste_name',
    //             'facet': 'coty_code',
    //             'refine.ste_name': JSON.stringify(stateArr),
    //             // 'refine.ste_name': 'California',
    //         }
    //         let { data: { records } } = await axios({
    //             method: 'get', // & controllers
    //             url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=${searchQuery.replaceAll(' ', '+')}&rows=200&sort=coty_name&exclude.pla_type=CDP&facet=year&facet=ste_name${stateArrString}`,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         return records
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }
}

export default api;