import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Flexbox from '../Flexbox'
import dayjs from 'dayjs'
const ContactFormSubmissions = () => {
    const [data, setData] = useState([])
    const fetchData = async () => {
        const res = await API.getContactFormSubmissions();
        // const { cities: _cities, states: _states, counties: _counties } = res
        if (res.contactformsubmissions)
            setData(res.contactformsubmissions)
        // setRegions(prev => ({
        //     ...prev,
        //     ...res,
        //     content: _states.concat(_cities.concat(_counties)).sort((a, b) => {
        //         if (b.highestPayingAttorney.length > 0) {
        //             let val = a.defaultLeadPrice
        //             if (a.highestPayingAttorney.length > 0)
        //                 val = a.highestPayingAttorney[0].price > val ? a.highestPayingAttorney[0].price : val

        //             return (b.highestPayingAttorney[0].price - val)
        //         }
        //         return (b.defaultLeadPrice - a.defaultLeadPrice)
        //     })
        // }))
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Flexbox justifyContent="start" width="100%" flexDirection="column" height="100vh" overflow="auto">
            <h2>Contact Form Submissions</h2>
            <GridContainer
                maxWidth="90%"
                fontSize="12px"
                align="center"
                alignContent="start"
                justify="center"
                justifyItems="center"
                border="1px solid #8f8f8f"
                // padding="50px"
                itemsPerRow="4"
                className="isTable"
                gridTemp={{ columns: 'min-content min-content minmax(auto, 500px) min-content' }}
                itemPadding="20px 10px"
                nthHighlight=""
            >
                <GridItem flexDirection="column" align="start">
                    <Flexbox userSelect="all" >Email</Flexbox>
                    <Flexbox>Name</Flexbox>
                </GridItem>
                <GridItem justify="start">Domain</GridItem>
                <GridItem textAlign="start" justify="start">Message</GridItem>
                <GridItem whiteSpace="nowrap">Date Submitted</GridItem>
                {
                    data.map(c => {
                        return (
                            <>
                                <GridItem flexDirection="column" align="start">
                                    <Flexbox userSelect="all" justify="start" onClick={() => {
                                        navigator.clipboard.writeText(c.email)
                                    }}>
                                        {c.email}
                                    </Flexbox>
                                    <Flexbox>
                                        {c.name}
                                    </Flexbox>
                                </GridItem>
                                <GridItem>{c.origin}</GridItem>
                                <GridItem textAlign="start" justify="start">{c.message}</GridItem>
                                <GridItem whiteSpace="nowrap">{dayjs(c.createdAt).format('MMM D h:mm A')}</GridItem>
                            </>
                        )
                    })
                }
            </GridContainer>
        </Flexbox>
    )
}

export default ContactFormSubmissions