import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import API from '../../API'
import Table from '../Table'
import TableCell from '../TableCell'
import TableRow from '../TableRow'
import Flexbox from '../Flexbox'
import LoadingWheel from '../LoadingWheel'
import DotMenu from '../DotMenu'
import AttorneysModal from '../AttorneysModal'
import Icon from '../Icon'
import { formatPrice, formatPhoneNumber } from '../../utils'

import TrueIcon from '../../images/acceptIcon.svg'
import FalseIcon from '../../images/close.svg'
import Toggle from '../Toggle'

const Tag = styled.div`
padding: 3px;
border: 1px solid #545454;
background: ${({ background = '#fff' }) => background};
border-radius: 5px;
font-size: ${({ fontSize = '10px' }) => fontSize};
white-space: ${({ whiteSpace }) => whiteSpace};

`;

const AttorneysTable = ({ _active }) => {

    const [modal, setModal] = useState({
        showModal: false,
        type: 'edit-attorney',
        attorney: null
    })
    const setShowModal = () => {
        setModal(prev => ({ ...prev, showModal: !prev.showModal }))
    }
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        attorneys: [],
        header: {},
        refine: {
            options: {
                'isActive': {
                    id: 'isActive', value: false, name: 'New',
                    enum: [
                        {
                            value: false, name: 'New',
                            // accessors: [
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            // ]
                        },
                        {
                            value: true, name: 'Active',
                            // accessors: [
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            //     { id: '', columnName: '' },
                            // ]
                        },
                    ]
                }
            },
            active: {
                'isActive': { id: 'isActive', value: false, name: 'New' }
            },
        }
    })
    const { refine, refine: { active }, attorneys } = data
    const onUpdateActiveStatus = (attorneyId) => {
        const objIndex = attorneys.findIndex(obj => obj.attorneyId === attorneyId);
        if (objIndex === -1)
            return
        const updatedObject = { ...attorneys[objIndex], isActive: !attorneys[objIndex].isActive }
        const updatedArr = [
            ...attorneys.slice(0, objIndex),
            updatedObject,
            ...attorneys.slice(objIndex + 1),
        ];
        setData((prev) => {
            return ({
                ...prev,
                attorneys: updatedArr
            })
        })
    }
    const getAttorneys = async (_refine = { id: _active.filterAccessor, value: _active.value }, secondary = _active['secondaryAccessors'] ? _active['secondaryAccessors'] : []) => {
        setLoading(true)
        const res = await API.getAttorneys(null, null, { id: _refine['id'], value: _refine['value'] }, secondary)
        const { attorneys, header } = res
        console.log("res", res, "_active", _active)
        const accessors = ['name', 'email', 'phoneNumber']
        const { geographicRegions } = res
        // let statesActive = [], countiesActive = [], citiesActive = []
        // if (geographicRegions) {
        //     geographicRegions.forEach(region => {
        //         let newFormat = { ...region, ...region.regionId }
        //         if (newFormat.regionType === 'County')
        //             countiesActive.push(newFormat)
        //         if (newFormat.regionType === 'City')
        //             citiesActive.push(newFormat)
        //         if (newFormat.regionType === 'State')
        //             citiesActive.push(newFormat)
        //     })
        // }
        setData(prev => ({
            ...prev, attorneys,
            header: header ? header : {},
        }))
        setLoading(false)
    }


    const getDotMenu = (attorney) => {
        const options = []
        options.push({
            innerText: 'Remove',
            value: 'remove'
        })
        if (_active.value === false)
            options.push({
                innerText: 'Make Active',
                value: 'activate',
                onClick: function () {
                    let modalData;
                    setModal(prev => ({
                        ...prev,
                        showModal: true,
                        type: 'move-to-active',
                        title: 'Make Active',
                        attorney
                    }))
                }
            })
        if (_active.value === true)
            options.push({
                innerText: 'Edit',
                value: 'activate',
                onClick: function () {
                    let modalData;
                    setModal(prev => ({
                        ...prev,
                        showModal: true,
                        type: 'edit-attorney-details',
                        title: 'Edit',
                        attorney
                    }))
                }
            })
        options.push({
            innerText: 'View Details',
            value: 'view-details',
            onClick: function () {
                let modalData;
                setModal(prev => ({
                    ...prev,
                    showModal: true,
                    type: 'view-attorney-details',
                    title: 'Attorney Details',
                    attorney
                }))
            }
        })
        return options
    }



    useEffect(() => {
        getAttorneys()
    }, [_active])
    const { header } = data
    console.log("header", header)
    const shouldUseInitialFormSubmissionValues = () => ((_active.filterAccessor === 'isActive' && _active.value === false) || (_active.filterAccessor === 'isNewRecord' && _active.value === true))
    const { showModal } = modal
    return (
        <Flexbox column alignItems="start" width="100%" height={showModal ? "100vh" : '100%'} minHeight={'100vh'} overflow={showModal ? "hidden" : 'auto'}>
            <Flexbox column alignItems="start" height="100%" width="100%" padding="50px 50px" margin="0 0 20px">
                <Table outline="1px solid var(--formBlue1)" oddHighlight={true} width="100%" >
                    <TableRow className="tableHeader" isHeader={true}>
                        {
                            Object.keys(header).map(it => {
                                return (
                                    <TableCell userSelect="all">
                                        {typeof (header[it]) === 'string' && (header[it] !== undefined ? header[it] : '-')}
                                        {typeof (header[it]) === 'object' && (
                                            <>
                                                {Object.keys(header[it]).map((it2, index) => {
                                                    console.log("it2 is: ", it2)
                                                    if (index === 0)
                                                        return (<>
                                                            <Flexbox gap="2px" userSelect="all">
                                                                {header[it][it2]}
                                                            </Flexbox>
                                                        </>)
                                                    else
                                                        return (<>
                                                            <Flexbox gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                                                {header[it][it2]}
                                                            </Flexbox>
                                                        </>)
                                                })}
                                            </>)}
                                    </TableCell>
                                )
                            })
                        }
                        {/* {
                            _active.value === true && <TableCell paddingBottom="0">Budget</TableCell>
                        }
                        {
                            _active.value === true && <TableCell paddingBottom="0">Total Spent</TableCell>
                        }
                        {
                            _active.value === true && <TableCell paddingBottom="0" textAlign="center"># Leads</TableCell>
                        } */}
                    </TableRow>
                    {/* <TableRow className="tableHeader" isHeader={true}>
                        <TableCell paddingTop="0" color="#7e7d7d">
                            Firm Name
                        </TableCell>
                        <TableCell paddingTop="0" color="#7e7d7d">
                            Phone Number

                        </TableCell>
                        <TableCell paddingTop="0" color="#7e7d7d">
                            Additional Practice Areas
                        </TableCell>
                    </TableRow> */}
                    {
                        loading && <TableRow background="none">
                            <TableCell colSpan="8" textAlign="center"><LoadingWheel /></TableCell>
                        </TableRow>
                    }
                    {
                        !loading &&
                        attorneys.map(attorney => {

                            return (
                                <TableRow key={attorney.id} color={!attorney.city || !attorney.city.isValid ? '#b30000' : undefined}>
                                    {
                                        Object.keys(header).map(it => {
                                            return (
                                                <TableCell userSelect="all">
                                                    {
                                                        <MultiField headerKey={it} header={header} attorney={attorney} onUpdateActiveStatus={onUpdateActiveStatus} />
                                                    }
                                                    {/* {typeof (header[it]) === 'string' && (
                                                        attorney[it] !== undefined ? attorney[it] : '-'
                                                    )}
                                                    {typeof (header[it]) === 'object' && (
                                                        <>
                                                            {Object.keys(header[it]).map((it2, index) => {
                                                                if (index === 0)
                                                                    return (<>
                                                                        <Flexbox gap="2px" userSelect="all">
                                                                            {attorney[it2]}
                                                                        </Flexbox>
                                                                    </>)
                                                                else
                                                                    return (<>
                                                                        <Flexbox gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                                                            {attorney[it2]}
                                                                        </Flexbox>
                                                                    </>)
                                                            })}
                                                        </>)} */}
                                                </TableCell>
                                            )
                                        })
                                    }
                                    <TableCell userSelect="none"><DotMenu options={getDotMenu(attorney)} /></TableCell>
                                </TableRow>)
                        })
                    }
                    {/* {
                        !loading &&
                        attorneys.map((attorney, index) => {
                            console.log(`attorney # ${index}`, attorney)
                            return (
                                <TableRow key={attorney.id}>
                                    <TableCell userSelect="unset" width="10%">
                                        <Flexbox userSelect="all">{attorney.name}</Flexbox >
                                        <Flexbox userSelect="all" color="#7e7d7d">{attorney.firmName}</Flexbox >
                                    </TableCell>
                                    <TableCell userSelect="unset">
                                        <Flexbox gap="2px" userSelect="all">
                                            {attorney.email}
                                        </Flexbox>
                                        <Flexbox gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                            {formatPhoneNumber(attorney.phoneNumber)}
                                        </Flexbox>
                                    </TableCell>
                                    <TableCell>
                                        <Flexbox gap="2px" userSelect="all">
                                            {attorney.practiceArea.map(area => {
                                                return (
                                                    <Tag background="var(--tagBgdCounty)">{area['name']}</Tag>
                                                )
                                            })}
                                        </Flexbox>
                                        <Flexbox gap="2px" whiteSpace="nowrap" color="#7e7d7d">
                                            {attorney.additionalPracticeAreas}
                                        </Flexbox>
                                    </TableCell>
                                    <TableCell>
                                        <Flexbox gap="5px" userSelect="none">
                                            {attorney[shouldUseInitialFormSubmissionValues() ? 'statesInitial' : 'statesActive'].map(region => {
                                                const state = region['regionId'] ? region['regionId'] : region
                                                return (
                                                    <Tag background="var(--tagBgdState)">{state['name']}</Tag>
                                                )
                                            })
                                            }
                                        </Flexbox>
                                    </TableCell>
                                    <TableCell>
                                        <Flexbox gap="5px">
                                            {attorney[shouldUseInitialFormSubmissionValues() ? 'countiesInitial' : 'countiesActive'].map(region => {
                                                const coty = region['regionId'] ? region['regionId'] : region
                                                return (
                                                    <Tag background="var(--tagBgdCounty)">{coty['name'] + ', ' + coty['state']['abbreviation']}</Tag>
                                                )
                                            })
                                            }
                                        </Flexbox>
                                    </TableCell>
                                    <TableCell userSelect="none" width="20%">

                                        <Flexbox gap="5px">
                                            {
                                                attorney[shouldUseInitialFormSubmissionValues() ? 'citiesInitial' : 'citiesActive'].slice(0, 2).map(region => {
                                                    const coty = region['regionId'] ? region['regionId'] : region
                                                    return (
                                                        <Tag background="var(--tagBgdCity)">{coty['name'] + ', ' + coty['state']['abbreviation']}</Tag>
                                                    )
                                                })
                                            }
                                            {
                                                attorney[shouldUseInitialFormSubmissionValues() ? 'citiesInitial' : 'citiesActive'].slice(0, 2).length < attorney[shouldUseInitialFormSubmissionValues() ? 'citiesInitial' : 'citiesActive'].length
                                                && <Tag whiteSpace="nowrap" fontSize="10px" onClick={() => {
                                                    setModal(prev => ({
                                                        ...prev,
                                                        showModal: true,
                                                        type: 'view-attorney-details',
                                                        title: 'Attorney Coverage',
                                                        attorney
                                                    }))
                                                }}>View More</Tag>
                                            }
                                        </Flexbox>

                                    </TableCell>
                                    <TableCell userSelect="none" textAlign="center" width="min-content">
                                        <Icon src={attorney['isNationwide'] ? TrueIcon : FalseIcon} />
                                    </TableCell>
                                    {
                                        _active.value === true && <TableCell width="min-content">{formatPrice(attorney.budget)}</TableCell>
                                    }
                                    {
                                        _active.value === true && <TableCell width="min-content">{formatPrice(attorney.totalSpent)}</TableCell>
                                    }
                                    {
                                        _active.value === true && <TableCell width="min-content" textAlign="center">{attorney.nbLeads}</TableCell>
                                    }
                                    <TableCell userSelect="none"><DotMenu options={getDotMenu(attorney)} /></TableCell>
                                </TableRow>
                            )
                        })
                    } */}
                </Table >
            </Flexbox>

            {showModal && < AttorneysModal setShowModal={setShowModal}  {...modal} />}
        </Flexbox >
    )
}

const MultiField = ({ headerKey, header, attorney, ...props }) => {

    if (typeof (header[headerKey]) === 'string')
        return (<Field obj={attorney} fieldKey={headerKey} wasString={true} {...props} />)
    if (typeof (header[headerKey]) === 'object') {
        return (
            <>{Object.keys(header[headerKey]).map((it2, index) => {
                return (<Field obj={attorney} fieldKey={it2} index={index} {...props} />)

            })}</>
        )
    }
}

const Field = ({ wasString = false, fieldKey, obj, index = 0, onUpdateActiveStatus }) => {

    if (fieldKey === 'isActive') {
        console.log("fieldKey", fieldKey, obj)
        return <Toggle showLabelText={false} hideLabel={true} _checked={obj[fieldKey]} onChange={() => { onUpdateActiveStatus(obj['attorneyId']) }} />
    }
    else if (wasString) {
        return (<>{obj[fieldKey] !== undefined ? obj[fieldKey] : '-'}</>)
    }
    else return (
        <Flexbox gap="2px" userSelect="all" whiteSpace="nowrap" color={index !== 0 ? "#7e7d7d" : undefined}>
            {obj[fieldKey]}
        </Flexbox>)
}

export default AttorneysTable