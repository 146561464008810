import React, { useEffect, useRef, useState, forwardRef } from 'react'

import { SearchIcon, AlertIconWrapper, Wrapper, InputStyled, LabelStyled, InnerWrapper, DollarSign } from './InputField.styles'


export const Label = ({ label, name, marginBottom, ...props }) => (


    <LabelStyled
        htmlFor={name}
        {...props}
    >
        <h3 style={{ textAlign: 'start', margin: '0' }}>
            {label}
        </h3>
    </LabelStyled>
)

const InputField = forwardRef(({
    _inputRef,
    onKeyUp,
    triggerFocus,
    childComponent,
    onKeyDown,
    dollarSignPos,
    onBlur,
    disabled,
    activeSearch, clearAll, clearListOnSearch, onSearch,
    isSearch = false, focusOnMount, marginTop, textAlign, uppercaseOff,
    type, image, onChange = () => { }, placeholder, value, label, name, onKeyPress, priceOnBlur, ...props }, ref) => {

    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);

    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);
        }
    }, [value])

    useEffect(() => {
        if (focusOnMount && inputRef.current !== null)
            inputRef.current.focus()
    }, [name])
    useEffect(() => {
        if (triggerFocus) {
            // triggerFocus.current = false
            inputRef.current.focus()
        }

    }, [triggerFocus])


    if (label !== undefined) {

        return (
            <>
                <Label htmlFor={name}
                    label={label}
                    {...props}
                ></Label>
                <InnerWrapper >
                    {name === 'price' && <DollarSign dollarSignPos={dollarSignPos}>$</DollarSign>}
                    <InputStyled
                        isSearch={isSearch}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        ref={inputRef}
                        onBlur={onBlur ? onBlur : priceOnBlur !== undefined ? priceOnBlur : undefined}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        {...props}
                    />
                    {childComponent && childComponent}
                </InnerWrapper>
            </ >

        )
    }
    else {
        return (
            <InnerWrapper {...props}>
                {name === 'price' && <DollarSign dollarSignPos={dollarSignPos}>$</DollarSign>}
                <InputStyled
                    isSearch={isSearch}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled ? true : false}
                    onChange={handleCursor}
                    name={name}
                    onKeyPress={onKeyPress}
                    ref={inputRef}
                    onBlur={onBlur ? onBlur : priceOnBlur !== undefined ? priceOnBlur : undefined}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    {...props}
                />
                
                {childComponent && childComponent}
            </InnerWrapper>
        )
    }



})

export default InputField
