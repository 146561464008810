import React from 'react'

import { TableContainer, TableContainerOdd } from './Table.styles'
const Table = ({ borderCollapse, borderSpacing, border, margin, cellpadding, children, background, oddHighlight = false, ...props }) => {
    if (oddHighlight) {
        return (
            <TableContainerOdd margin={margin} cellpadding={cellpadding} background={background} border={border} borderSpacing={borderSpacing} borderCollapse={borderCollapse} {...props}><tbody>{children}</tbody></TableContainerOdd>
        )
    }
    return (
        <TableContainer margin={margin} cellpadding={cellpadding} background={background} border={border} borderSpacing={borderSpacing} borderCollapse={borderCollapse} {...props}><tbody>{children}</tbody></TableContainer>
    )
}

export default Table