import React, { useEffect } from 'react'
import { AddAttorneyDropdown, RegionDropdown, RegionPrice } from './LeadsModal'
import Toggle from './Toggle'
import GridItem from './GridItem'
import styled from 'styled-components'
import RadixDropdown from './RadixDropdown'
import API from '../API'
import { useState } from 'react'

import { Dropdown } from 'primereact/dropdown';
import { formatPrice } from '../utils'

const Container = styled.div`
position: relative;
display: grid;
grid-template-rows: repeat(2,min-content);
grid-auto-flow: column;
grid-template-columns: 3fr 1fr 2fr;
gap: 1em;
/* padding: 10px; */
width: 100%;
height: auto;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    grid-template-rows: repeat(4,auto);
grid-auto-flow: column;
grid-template-columns: 1fr;
display: flex;
flex-direction: column;
height: 100%;
z-index: 1;
text-align: left;
background-color: #fff;
overflow: auto;
padding: 15px;
margin: 20px 0;
max-height: 40vh;
}
`;


const GridC = styled.div`
display: flex;
flex-direction: column;
position: relative;
align-items: start;
border: 1px solid #000;
padding: 1em;
grid-column: 1 / span 3;
grid-row: 2;
position: relative;
height: auto;
width: 100%;
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
grid-row: -1;
grid-column: 1 / span 1;
}
`;

const TempAddGeoRegion = ({ onRowUnselect, isAdminAdd = true, hideLabel = false, lead, price, setShowAddAttModal, getPotentialMatches, newAttorney, setNewAttorneyField, setData }) => {
    const [fieldObj, setFieldObj] = useState({ value: 'Law Firm - Budget', options: [], showDropdown: false })
    const { showDropdown, options } = fieldObj
    const setShowDropdown = () => {
        setFieldObj(prev => ({
            ...prev,
            showDropdown: !prev.showDropdown
        }))
    }
    useEffect(() => {
        const getAttorneys = async () => {
            const res = await API.getAttorneys(null, null, {
                id: 'isActive', value: true
            }, [
                { 'sort': '-budget' },
                // { budget: { $gt: 0 } }
            ])
            const { attorneys } = res
            if (attorneys) {
                console.log("attorneys are: ", attorneys)
                setFieldObj(prev => ({
                    ...prev,
                    options: attorneys.map(it => {
                        const { firmName, budget } = it
                        const str = `${firmName}\n${formatPrice(budget)}`
                        return ({
                            innerText: str,
                            value: it['attorneyId'],
                            label: str,
                            id: it['attorneyId'],
                            _id: it['attorneyId'],
                            firmName
                        })
                    })
                    // options: {
                    //     'group-1-att': {
                    //         options: attorneys.map(it => {
                    //             const { firmName, budget } = it
                    //             const str = `${firmName}\n${budget}`
                    //             return ({
                    //                 innerText: str,
                    //                 value: it['attorneyId'],
                    //                 label: str,
                    //                 id: it['attorneyId'],
                    //                 _id: it['attorneyId']
                    //             })
                    //         })
                    //     }
                    // }
                }))
            }
        }
        getAttorneys()
    }, [])
    return (
        <Container>
            <Dropdown
                options={options}
                optionLabel='innerText'
                placeholder="Law Firm - Budget"
                value={fieldObj.value}
                onChange={(e) => {
                    console.log("value is:", e.value)
                    let selected = options.find( option => option.id === e.value)
                    setFieldObj(prev => ({
                        ...prev,
                        value: e['value']
                    }))
                    setNewAttorneyField({
                        // regionType: optionObj['regionType'],
                        // regionId: optionObj['id'],
                        // regionValue: optionObj['value'],
                        firmName: selected.firmName,
                        attorneyId: e.value,
                    })
                    if (typeof (onRowUnselect) === 'function')
                        onRowUnselect()
                }}
            />
            {/* <AddAttorneyDropdown setNewAttorneyField={setNewAttorneyField} /> */}
            <RegionPrice price={price} setNewAttorneyField={setNewAttorneyField} />
            <RegionDropdown lead={lead} setNewAttorneyField={setNewAttorneyField} />
            {/* <GridC >
                <p style={{ margin: '0' }}>Temporarily Add Geo Region</p>
                <p style={{ fontSize: '13px', margin: '0' }}>This geo region will remain in the database, but it'll be marked as an admin addition to help differentiate it (you can find it in the db by filtering isAdminAdd: true). In addition, it'll be immediately marked as inactive (isActive: false), to prevent auto sending.<br /><br />If you want a new record that will continue to match automatically, the toggle should be off and the price should be greater than zero.</p>
                <Toggle
                    defaultValue={isAdminAdd}
                    _checked={isAdminAdd}
                    _setChecked={(v) => {
                        setNewAttorneyField({ isAdminAdd: v })
                    }}
                    options={['Temp Add']}
                    label='Temp Add' /></GridC> */}
        </Container>
    )
}

export default TempAddGeoRegion