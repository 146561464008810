import React, { useState } from 'react'

import { Wrapper } from './Toggle.styles'

const Toggle = ({ side = "right", defaultValue = true, _checked, _setChecked, onChange = () => { }, label = '', showLabelText = false, hideLabel = false, fontSize, options = [], containerProps = {}, splitPosition = false, ...props }) => {
    const [checked, setChecked] = useState(defaultValue)

    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', ...containerProps }} onClick={(e) => {
            onChange(e);
            if (typeof (_setChecked) === 'function')
                _setChecked(!_checked)
            else if (_checked === undefined)
                setChecked(!checked)
        }}>
            {(splitPosition && !hideLabel) &&
                <label for="checkbox" style={{ marginLeft: '5px', fontSize: fontSize ? fontSize : '1rem', whiteSpace: 'nowrap' }}>
                    {options.length ? options[0] : 'Yes'}
                </label>
            }
            {(!splitPosition && !hideLabel && side === "left") && <label for="checkbox" style={{ marginRight: '5px', fontSize: fontSize ? fontSize : '1rem' }}>
                {showLabelText ? label : (_checked !== undefined && _checked) || checked ? options.length ? options[0] : 'Yes' : options.length ? options[1] : 'No'}
            </label>}
            <Wrapper {...props}>
                <input type="checkbox" checked={_checked !== undefined ? _checked : checked} name="checkbox" onChange={onChange} />
                <span className="slider"></span>
            </Wrapper>
            {(!splitPosition && !hideLabel && side === "right") && <label for="checkbox" style={{ marginLeft: '5px', fontSize: fontSize ? fontSize : '1rem' }}>
                {showLabelText ? label : (_checked !== undefined && _checked) || checked ? options.length ? options[0] : 'Yes' : options.length ? options[1] : 'No'}
            </label>}
            {(splitPosition && !hideLabel) && <label for="checkbox" style={{ marginLeft: '5px', fontSize: fontSize ? fontSize : '1rem', whiteSpace: 'nowrap' }}>
                {showLabelText ? label : options.length ? options[1] : 'No'}
            </label>}
        </div>
    )
}

export default Toggle