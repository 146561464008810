import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import InputField from './InputField'
import TextArea from './TextArea'
import Toggle from './Toggle'
import Button from './Button'
import Dropdown from './Dropdown2'
import API from '../API'
import RadixDropdown from './RadixDropdown'
import { TrashIcon, PlusIcon } from '@radix-ui/react-icons'
import { formatPrice } from '../utils'

const GridContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: repeat(3,min-content);
margin-top: 50px;
background: #b5fdda;
`;

const ManuallyFormatLead = () => {
    const { state: locationState } = useLocation()
    const [data, setData] = useState({
        lead: {},
        contactDetailsNoCRM: {}, // contactDetailsNoCRM is used when there is no endpoint. otherwise, crmFields handles the contact details for both the crm and the email endpoint
        globalToggle: true,
        crmFields: {},
        newFormattedLead: {},
        attorney: {},
        matches: [],
        defaultNotes: {},
        selectedNote: 'Default Notes',
    })
    const [showDropdown, setShowDropdown] = useState(false)
    const [submit, setSubmit] = useState({ serverRes: '', isLoading: false })
    const { isLoading, serverRes } = submit
    const { leadId } = useParams()

    useEffect(() => {
        const initialize = async () => {

            let defaultNotes = {}, selectedNote = {}
            const { attorney, attorney: { contactMethods: { crm } }, lead, formattedLead, matches } = locationState
            if (lead.kind && attorney._id) {

                defaultNotes = await getNotes(lead.kind, attorney._id)
                selectedNote = 'Default Notes'
            }
            setData((prev) => {
                let fieldsObj = {}
                if (Array.isArray(crm) && crm.length > 0) {
                    let expectedEndpointFields = (crm[0])['fields']
                    console.log("formattedLead", formattedLead)

                    console.log("expectedEndpointFields", expectedEndpointFields)
                    expectedEndpointFields.forEach(field => {
                        if (typeof (field) === 'string') {
                            let _field;
                            if (field === 'First')
                                _field = formattedLead['name'].split(' ')[0]
                            else if (field === 'Summary')
                                _field = formattedLead['additionalDetails']
                            else if (field === 'Last')
                                _field = formattedLead['name'].split(' ').length > 0 ? formattedLead['name'].split(' ')[1] : ''
                            else if (field.toLowerCase() === 'phone' && formattedLead['phoneNumber'])
                                _field = formattedLead['phoneNumber']
                            else if (formattedLead[field.toLowerCase()])
                                _field = formattedLead[field.toLowerCase()]
                            else
                                _field = ''
                            fieldsObj = { ...fieldsObj, [field]: { initialValue: _field, value: _field, type: field === 'Summary' ? 'textArea' : 'input' } }
                        }
                    })
                }
                //contactDetailsNoCRM
                const returnObject = {
                    ...prev,
                    lead,
                    attorney,
                    matches,
                    defaultNotes,
                    selectedNote,
                    newFormattedLead: {
                        ...formattedLead,
                        caseDetailsObj: {
                            ...Object.fromEntries(formattedLead.caseDetails.map((it, index) => {
                                const key = `field-${index + 1}`
                                const defaultInclude = it['header'] === 'Has Lawyer Already' ? false : true
                                return ([key, { ...it, defaultValue: it['value'], include: defaultInclude, defaultInclude }])
                            })),
                            [`field-${formattedLead.caseDetails.length + 1}`]: {
                                name: 'adminNotes',
                                header: 'Admin Notes',
                                type: 'textArea',
                                initialValue: '',
                                value: '',
                                defaultValue: '',
                                defaultInclude: false,
                                include: false,
                            }
                        }
                    },
                    crmFields: {
                        ...fieldsObj
                    }
                }
                if (Object.keys(fieldsObj).length === 0) {
                    let defaultInclude = true
                    returnObject['contactDetailsNoCRM'] = {
                        'name': { header: "Name", value: formattedLead['name'], include: defaultInclude, defaultInclude },
                        'email': { header: "Email", value: formattedLead['email'], include: defaultInclude, defaultInclude },
                        'phoneNumber': { header: "Phone", value: formattedLead['phoneNumber'], include: defaultInclude, defaultInclude },
                        // 'city': { header: "City", value: formattedLead['city'], include: defaultInclude, defaultInclude },
                        // 'state': { header: "State", value: formattedLead['ste_name'], include: defaultInclude, defaultInclude },
                    }
                }
                return ({
                    ...returnObject
                })


            })


        }
        const getNotes = async (kind, attId) => {
            let { defaultNotes } = await API.getDefaultAdminNotes({ leadType: kind, attorneyId: attId })
            return defaultNotes
        }
        if (locationState) {
            initialize()
        }

    }, [])
    const { selectedNote, defaultNotes, contactDetailsNoCRM, lead, globalToggle, matches, attorney, crmFields, newFormattedLead, newFormattedLead: { caseDetailsObj } } = data

    console.log("defaultNotes", defaultNotes)
    console.log("caseDetailsObj", caseDetailsObj)

    const formRef = useRef()

    const formatToSendEmailFields = () => {
        let emailFields = {}, contactDetails = []
        const caseDetails = Object.keys(newFormattedLead.caseDetailsObj).reduce((a, b, index) => {
            const { value, header, include } = newFormattedLead.caseDetailsObj[b]
            if (header === 'Location' && objectIsEmpty(crmFields))
                emailFields["city"] = value
            if (include)
                a.push({ header, value })
            return a
        }, [])

        if (!objectIsEmpty(crmFields)) {
            contactDetails = Object.keys(crmFields).reduce((arr, field) => {
                if (field !== 'Summary')
                    arr.push({ header: field, value: crmFields[field].value })
                return arr
            }, [])
            emailFields["leadName"] = crmFields['First'].value || crmFields['Name']
            emailFields["city"] = crmFields['City'].value
        } else if (!objectIsEmpty(contactDetailsNoCRM)) {
            contactDetails = Object.keys(contactDetailsNoCRM).map((field) => {
                const { header, value } = contactDetailsNoCRM[field]
                return ({ header, value })
            }, [])
            emailFields["leadName"] = contactDetailsNoCRM['name'].value
        }
        /*
                
        const caseDetails = Object.keys(newFormattedLead.caseDetailsObj).reduce((a, b, index) => {
            const { value, header, include } = newFormattedLead.caseDetailsObj[b]
            if (include)
                a.push({ header, value })
            return a
        }, [])
        const contactDetails = {}

        */
        emailFields = {
            ...emailFields,
            lead_type: lead.leadType,
            lead: { contactDetails, caseDetails }
        }
        console.log("emailFields", emailFields)
        return emailFields
    }

    const objectIsEmpty = (ob) => Object.keys(ob).length === 0

    const formatToCRMFields = () => {

        const _crmFields = Object.fromEntries(Object.entries(crmFields).map(field => {
            let value = "";
            if (typeof (field[1]) === 'object')
                value = field[1].value
            return ([field[0], value])
        }))
        console.log("crmFields", _crmFields)
        return _crmFields
    }
    console.log("data", data)

    const toggleFieldInclude = (fieldKey, include) => {
        setData(prev => {

            return ({
                ...prev,
                newFormattedLead: {
                    ...prev.newFormattedLead,
                    caseDetailsObj: {
                        ...prev.newFormattedLead.caseDetailsObj,
                        [fieldKey]: {
                            ...prev.newFormattedLead.caseDetailsObj[fieldKey],
                            include: !include
                        }
                    }
                }
            })
        })
    }
    const fieldOnChange = (fieldKey, subfieldKey = "value") => (e) => {
        console.log("EEEEEEEEEE", e.target['value'], fieldKey)
        if (e.target && e.target['value'])
            setData(prev => {

                return ({
                    ...prev,
                    newFormattedLead: {
                        ...prev.newFormattedLead,
                        caseDetailsObj: {
                            ...prev.newFormattedLead.caseDetailsObj,
                            [fieldKey]: {
                                ...prev.newFormattedLead.caseDetailsObj[fieldKey],
                                [subfieldKey]: e.target.value
                            }
                        }
                    }
                })
            })
    }
    const findFieldByHeader = (header) => {
        if (typeof (caseDetailsObj) !== 'object')
            return ''
        return Object.keys(caseDetailsObj).find(key => caseDetailsObj[key].header === header)
    }

    const updateNotes = (note) => {
        let adminNoteFieldId = findFieldByHeader("Admin Notes")
        if (adminNoteFieldId)
            fieldOnChange(adminNoteFieldId, note)
    }


    console.log("defaultNotes", defaultNotes)
    // const [globalToggle, setGlobalToggle] = useState(true)
    return (
        <div style={{ margin: '5px' }}>

            {/* {attorney['firmName'] && <><Dropdown
                dropdownRef={null}
                listContainerRef={null}
                options={[]} valueX={attorney['firmName']} headerHeight="49px"
                itemHeight="49px"
                // maxHeight={4 * 49 + 'px'}
                dropdownListMarginTop={"10px"}
                // {...fieldObj}
                showHeader={true}
                showArrow={true}
                name={'Suggested Tags'}
                marginBottom={'1rem'}
                border="none"
                headerBgd="#dbe7f2"
                handleMenuStateInternally={true}
                // menuState={showDropdown}
                // setMenuState={() => setShowDropdown(!showDropdown)}
                sendEvent={false}
                handleStateChange={(val) => {

                }}
                boxShadow={" 0 0 4px rgba(var(--formBlue2Raw),0.5)"}
                id={'suggested-tags'}
                setValidContinue={() => { }}
                justifyContent="start"
                textAlign="left"
            /></>} */}
            <h3><span>{attorney['firmName']}</span><br /><span style={{ fontSize: '10px' }}>Lead Recipient</span></h3>
            <h3><span>{formatPrice(attorney['price'])}</span><br /><span style={{ fontSize: '10px' }}>Lead Price</span></h3>
            <div style={{ background: '#b5fdda' }}>
                {Object.keys(crmFields).length > 0 && <h3>CRM Endpoint Fields </h3>}
                {/* {Object.keys(crmFields).length > 0 && <p>CRM Endpoint fields in orange</p>} */}
                {/* <form ref={formRef}> */}

                {
                    Object.keys(crmFields).map(it => {
                        const { type, value } = crmFields[it]
                        return (<div>
                            <div style={{ display: 'flex', textAlign: 'left', marginTop: '15px' }}>{it}</div>
                            <div>
                                {type === 'input' && <InputField defaultValue={value} value={value} onChange={(e) => {
                                    if (e.target && e.target.value)
                                        setData(prev => {
                                            return ({
                                                ...prev,
                                                crmFields: {
                                                    ...prev.crmFields,
                                                    [it]: {
                                                        ...prev.crmFields[it],
                                                        value: e.target.value
                                                    }
                                                }
                                            })
                                        })
                                }} />}
                                {type === 'textArea' &&
                                    <TextArea
                                        defaultValue={value}
                                        onChange={(e) => {
                                            if (e.target && e.target.value)
                                                setData(prev => {
                                                    return ({
                                                        ...prev,
                                                        crmFields: {
                                                            ...prev.crmFields,
                                                            [it]: {
                                                                ...prev.crmFields[it],
                                                                value: e.target.value
                                                            }
                                                        }
                                                    })
                                                })
                                        }}
                                        value={value}
                                        rows={10} />}
                            </div>
                        </div>)
                    })
                }
            </div>
            <GridContainer>
                <h2 style={{ gridColumn: '1 / span 2', textAlign: 'left', marginBottom: '0' }}>Email Fields</h2>
                {(Array.isArray(attorney.contactMethods?.emailList) && attorney.contactMethods['emailList'].length > 0) &&
                    <div style={{ gridColumn: '1 / span 2', textAlign: 'left', margin: '10px 0' }}>
                        <h3>Email Recipients:</h3>
                        {
                            [...attorney.contactMethods['emailList'], ...attorney.contactMethods['emailList'], ...attorney.contactMethods['emailList'], ...attorney.contactMethods['emailList']].map(it => <div style={{ display: 'inline-block', padding: '5px', background: '#99fff3', width: 'fit-content', margin: '3px', borderRadius: '3px', color: '#005780', border: '1px solid #005780' }}>{it}</div>)
                        }
                    </div>}
                <p style={{ gridColumn: '1 / span 2', textAlign: 'left' }}>
                    You can include or suppress email fields with their respective toggles.<br /><br />Clicking "Add to Summary" will replace the "Summary" field for the CRM endpoint by with all enabled fields.</p>

                {!objectIsEmpty(crmFields) && <Button text="Add to Summary" height="50px" width="100%" padding="20px" borderRadius="3px" background="#00ff84"
                    // margin="0 auto"
                    onClick={() => {
                        const v = Object.keys(newFormattedLead.caseDetailsObj).reduce((a, b, index) => {
                            const { value, header, include } = newFormattedLead.caseDetailsObj[b]
                            if (include)
                                a = a + `${header}: ${value}; \n`
                            return a
                        }, ``)
                        console.log("changing summary to: ", v)
                        // }, `Additional Details: ${newFormattedLead.additionalDetails}; \n`)
                        setData(prev => ({
                            ...prev,
                            crmFields: {
                                ...prev.crmFields,
                                Summary: {
                                    ...prev.crmFields.Summary,
                                    value: v.trim()
                                }
                            }
                        }))
                    }} />}
                <Toggle containerProps={{ right: '5px', position: 'relative', gridColumn: '2', justifySelf: 'end', width: 'min-content', whiteSpace: 'nowrap' }} label={globalToggle ? "Toggle All" : "Toggle All"} side="left" showLabelText={true} onChange={() => {
                    setData(prev => {
                        return ({
                            ...prev,
                            globalToggle: !prev.globalToggle,
                            newFormattedLead: {
                                ...prev.newFormattedLead,
                                caseDetailsObj: Object.fromEntries(Object.entries(prev.newFormattedLead.caseDetailsObj).map(it => {
                                    console.log("it:", it)
                                    return ([it[0], { ...it[1], include: !prev.globalToggle }])
                                }))
                            }
                        })
                    })
                }} />
            </GridContainer>
            {
                Object.keys(crmFields).length === 0 &&
                <>
                    <h3 style={{ textAlign: 'left' }}>Contact Details</h3>
                    {Object.keys(contactDetailsNoCRM).map((it) => {
                        const { include, value, header } = contactDetailsNoCRM[it]
                        console.log("it", it)
                        return (
                            <>
                                <div style={{ marginTop: '15px' }}>
                                    <div style={{ display: 'flex', textAlign: 'left' }}>{header}</div>
                                    <InputField
                                        disabled={!include}
                                        padding="3px 70px 3px 10px"
                                        value={value}
                                        defaultValue={value} onChange={(e) => {
                                            if (e.target && e.target.value)
                                                setData(prev => {
                                                    return ({
                                                        ...prev,
                                                        contactDetailsNoCRM: {
                                                            ...prev.contactDetailsNoCRM,
                                                            [it]: {
                                                                ...prev.contactDetailsNoCRM[it],
                                                                value: e.target.value
                                                            }
                                                        }
                                                    })
                                                })
                                        }}
                                        childComponent={<div style={{ position: 'absolute', right: '7px' }}><Toggle
                                            showLabelText={false}
                                            hideLabel={true}
                                            _checked={include} onChange={() => {
                                                setData(prev => {
                                                    return ({
                                                        ...prev,
                                                        contactDetailsNoCRM: {
                                                            ...prev.contactDetailsNoCRM,
                                                            [it]: {
                                                                ...prev.contactDetailsNoCRM[it],
                                                                include: !include
                                                            }
                                                        }
                                                    })
                                                })
                                            }} /></div>}
                                    />
                                </div>
                            </>)
                    })}
                </>
            }
            <h3 style={{ textAlign: 'left' }}>Case Details</h3>
            {
                newFormattedLead.caseDetailsObj &&
                Object.keys(newFormattedLead.caseDetailsObj).map((it, index) => {
                    const { value, header, include, type, isCustom, placeholder } = newFormattedLead.caseDetailsObj[it]
                    return (
                        <div style={{ marginTop: '15px' }}>
                            {(!isCustom && header !== 'Admin Notes') &&
                                <div style={{ display: 'flex', textAlign: 'left' }}>{header}</div>
                            }
                            {(!isCustom && header === 'Admin Notes') &&
                                <div style={{ display: 'grid', textAlign: 'left', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'min-content' }}><span>{header} </span>
                                    <div >

                                        <RadixDropdown
                                            options={defaultNotes}
                                            onValueChange={(val) => {
                                                let adminNoteFieldId = findFieldByHeader("Admin Notes")
                                                if (adminNoteFieldId)
                                                    setData(prev => {
                                                        let valueObject = prev.newFormattedLead.caseDetailsObj[adminNoteFieldId]
                                                        return ({
                                                            ...prev,
                                                            selectedNote: val.value,
                                                            newFormattedLead: {
                                                                ...prev.newFormattedLead,
                                                                caseDetailsObj: {
                                                                    ...prev.newFormattedLead.caseDetailsObj,
                                                                    [adminNoteFieldId]: {
                                                                        ...valueObject,
                                                                        value: val.note === 'Default Notes' ? valueObject.value : val.note
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    })
                                            }} />

                                    </div></div>
                            }
                            {isCustom && <div style={{ display: 'flex', textAlign: 'left', color: 'red' }}><InputField
                                disabled={!include}
                                padding="3px 70px 3px 10px"
                                // value={header}
                                placeholder={placeholder}
                                childComponent={<div style={{ position: 'absolute', right: '7px' }}
                                    onClick={() => {
                                        setData(prev => {
                                            const _caseDetailsObj = prev.newFormattedLead.caseDetailsObj
                                            delete _caseDetailsObj[it]
                                            return ({
                                                ...prev,
                                                newFormattedLead: {
                                                    ...prev.newFormattedLead,
                                                    caseDetailsObj: _caseDetailsObj
                                                }
                                            })
                                        })
                                    }}
                                ><TrashIcon /></div>}
                                background="rgba(0,0,0,0.1)"
                                border="none;border-left: 2px solid #000"
                                defaultValue={header} onChange={fieldOnChange(it, "header")}
                            /></div>}
                            <div>
                                {(header === 'Additional Details' || type === 'textArea') &&
                                    <TextArea
                                        disabled={!include}
                                        padding="10px 10px 40px"
                                        childComponent={<div style={{ position: 'absolute', right: '7px', bottom: '7px' }}><Toggle
                                            showLabelText={false}
                                            hideLabel={true}
                                            _checked={include} onChange={() => { toggleFieldInclude(it, include) }} /></div>}
                                        defaultValue={value}
                                        onChange={fieldOnChange(it)}
                                        value={value}
                                        rows={10} />}
                                {(header !== 'Additional Details' && type !== 'textArea') && <InputField
                                    disabled={!include}
                                    padding="3px 70px 3px 10px"
                                    defaultValue={value} onChange={fieldOnChange(it)}
                                    childComponent={<div style={{ position: 'absolute', right: '7px' }}><Toggle
                                        showLabelText={false}
                                        hideLabel={true}
                                        _checked={include}
                                        onChange={() => { toggleFieldInclude(it, include) }} /></div>}
                                />}
                            </div>
                        </div>)
                })
            }
            <div style={{ height: '50px', width: '50px', background: '#bbf9aa', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1b7a01', margin: '10px 0', borderRadius: '5px', border: '1px solid #1b7a01' }}
                title="Add Field" onClick={() => {
                    setData(prev => ({
                        ...prev,
                        newFormattedLead: {
                            ...prev.newFormattedLead,
                            caseDetailsObj: {
                                ...prev.newFormattedLead.caseDetailsObj,
                                [`field-${Object.keys(newFormattedLead.caseDetailsObj).length + 1}`]: {
                                    header: 'Field Name',
                                    type: 'input',
                                    initialValue: 'Field Value',
                                    value: 'Field Value',
                                    defaultValue: 'Field Value',
                                    defaultInclude: true,
                                    include: true,
                                    isCustom: true
                                }
                            }
                        }
                    }))
                }}><PlusIcon /></div>
            <Button width="100%" text="Submit" padding="20px" margin="70px 2px 2px" onClick={async () => {
                let data = {
                    leadId,
                    attorney
                }
                console.log("attorney.contactMethods", attorney.contactMethods)
                if (Object.keys(crmFields).length > 0)
                    data['crmFields'] = formatToCRMFields()
                if (attorney.contactMethods['emailList'] && attorney.contactMethods['emailList'].length > 0)
                    data['emailFields'] = formatToSendEmailFields()
                console.log("data", data)
                if (!isLoading) {

                    setSubmit(prev => ({ ...prev, isLoading: true }))
                    let res = await API.modifyLeadToSend(data)
                    if (res && res.msg)
                        setSubmit({ isLoading: false, serverRes: res.msg })
                }
            }} />
            {serverRes}
            {/* </form> */}
        </div>
    )
}

export default ManuallyFormatLead