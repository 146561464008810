import logo from './logo.svg';
import './App.css';
import { GlobalStyle } from './GlobalStyle';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// CSS
import Home from './components/Home'
import Login from './components/Login'
import PrivateRoute from './components/PrivateRoute'
import LoadingWheel from './components/LoadingWheel'
import Regions from './components/Regions'
import ProcessDispute from './components/ProcessDispute';
import SendFromEmailTable from './components/SendFromEmailTable'

// Contexts
import { AuthProvider } from './contexts/AuthContext'
import React from 'react';
import ManuallyFormatLead from './components/ManuallyFormatLead';
import Disputes from './components/Disputes';
import DisputesTable from './components/DisputesTable';
import GeographicRegionDetail from './components/GeographicRegion';
import GeographicRegionsTable from './components/GeographicRegionTable';
import LeadDelivery from './components/LeadDelivery';
// 8/18
// WHAT HAPPENS WHEN WE INCREASE THE DEFAULT PRICE OF A LEAD?
// what about the date that the dwi ocurred? is that important?
// say attorney A is paying the default price for a region, $22.
// the default has been increased to $50.
// obviously we don't want to auto increase the price without notifying the user, so should everyone who was paying $22 be moved to "inactive" to prevent them being charged without
// approving the price increase?



// should we prevent adding active lead region in "make active" for regions covered by other attorneys - NO, but we can do this temporarily to make it so we can launch faster, if that will help.
// should budget be split down further - NO. Maybe yes in the near future.
// for columns w/ large number of counties/cities etc., max out at x number of counties for now with a little "+50 more" link that brings up 
// popup showing remaining counties/cities etc.
// ability to assign leads to a particular attorney / set an order (ie first lead from LA County goes to Attorney 1, second lead to Att. 2, etc.)
// be able to temp assign all incoming leads for (region X) to a particular person
// ***Remaining budget should deplete every time they get a lead***.  But also a static listing for their monthly budget, i.e. Budget, and "remaining budget"
// Dspute stats/info, "lawyer disputed x% of their leads"  Maybe some kind of field where I can manually enter how many leads they disputed this month/year.  In the future, clients can use portal to dispute leads and we can track automatically.  In the meantime, they will email to dispute and I can manually enter dispute info in database. * MAYBE CONFUSING, DONT WORRY ABOUT THIS FOR NOW.  
// Track # of leads given per day/week, month, year, all-time, etc.  As well as average price per lead.
// Pricing - Set price per lead for each customer, and set/edit price for various regions.  Can make more/less expensive per city, county, state.
// Will also need "statewide" option for leads
// Does API return "parish" same as county? YES
// lowercase city name won't return values from api call -> need to convert both to lowercase

// 8/12 toesy talk
//  top bidder gets the leads until their budget is exhausted

//  8/12
//  should there be an 'override' history?
//  see notebook notes about priority questions
//  is the priority a reoccuring event? ie does it need to 
//  retain the order and then cycle back through when it reaches the bottom?
//  Priority #19 Jake gets a lead (lets call it lead #40)
//  Priority #20 Bob would get the next lead, BUT
//  New attorney Sam starts paying for priority #1
//  Presumably, Sam gets the next lead (lead #41)
//  Who gets lead #42? Priority #20 Bob (technically he is now actually priority #21)? 
//  What if this happens recursively? Ie before Bob gets his lead, new attorney Jane
//  starts paying for priority #7
//  Presumably, the next lead goes to #7
//  The leads history for this would essentially be:
//  L#39    -   P#18
//  L#40    -   P#19
//  L#41    -   P#1
//  L#42    -   P#7
//  L#43    -   P#20 (technically now #22)
//
//  Lead[41][priority] = 1
//  Lead[42][priority] = 7
//  since 1 !== 7 - 1, we know a data reordering has occured
//  
//  if (Lead[index - 1][priority] !== (Lead[index][priority] - 1))
//      data  
//
//  priority functions a lot like top 100. if a new attorney comes in at priority #4, all data after #4 must 
//  be shifted += 1
function App() {
  const options = {
    'category-1': {
      id: 'category-1',
      innerText: 'Attorneys',
      path: 'attorneys',
      options: [
        {
          id: 'option-1',
          innerText: 'New',
          path: 'new-attorneys',
        },
        {
          id: 'option-2',
          innerText: 'Active',
          path: 'active-attorneys',
        },
        {
          id: 'option-3',
          innerText: 'Inactive',
          path: 'inactive-attorneys',
        },
      ],
    },
    'category-2': {
      id: 'category-2',
      innerText: 'Regions',
      path: 'regions',
      options: [
        {
          id: 'option-1',
          innerText: 'States',
          path: 'states',
        },
        {
          id: 'option-2',
          innerText: 'Counties',
          path: 'counties',
        },
        {
          id: 'option-3',
          innerText: 'Counties',
          path: 'counties',
        },
      ],
    },
    'category-3': {
      id: 'category-3',
      innerText: 'Leads',
      path: 'leads',
      options: [
        {
          id: 'option-1',
          innerText: 'New',
          path: 'new-leads',
        },
        {
          id: 'option-2',
          innerText: 'Past',
          path: 'completed-leads',
        },
        {
          id: 'option-3',
          innerText: 'Set Calc Leads',
          path: 'set-calc',
        },
        {
          id: 'option-4',
          innerText: 'Accident Report',
          path: 'accident-report',
        },
      ],
    },
    'category-4': {
      id: 'category-4',
      innerText: 'Contact Form',
      path: 'contacts',
      options: [
        {
          id: 'option-1',
          innerText: 'Contact Form',
          path: 'new-contacts',
        },
      ],
      'option-order': ['option-1']
    },
    'category-5': {
      id: 'category-5',
      innerText: 'Disputes',
      path: 'disputes',
      options: [
        {
          id: 'option-1',
          innerText: 'Pending',
          path: 'pending-disputes',
        },
        {
          id: 'option-2',
          innerText: 'Processed',
          path: 'completed-disputes',
        },
      ],
    },
    'category-6': {
      id: 'category-6',
      innerText: 'Lead Delivery',
      path: 'lead-delivery',
      options: [
        {
          id: 'option-1',
          innerText: 'Lead Delivery',
          path: '',
        },
      ],
    },
    'category-7': {
      id: 'category-7',
      innerText: 'Geographic Regions',
      path: 'geographic-regions',
      options: [
        {
          id: 'option-1',
          innerText: 'Geographic Regions',
          path: '',
        },
      ],
    },
    'category-order': ['category-1', 'category-2', 'category-3', 'category-4', 'category-5', 'category-6','category-7']
  }

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <GlobalStyle />
          <Routes>

            {
              options['category-order'].map(category => {
                return (
                  <React.Fragment key={category}>
                    {
                      options[category].options.map(option => {
                        return (
                          <Route key={`/${options[category].path}${option.path === '' ? '' : '/'}${option.path}`} path={`/${options[category].path}${option.path === '' ? '' : '/'}${option.path}`} element={< PrivateRoute />} >
                            <Route path={`/${options[category].path}${option.path === '' ? '' : '/'}${option.path}`} element={< Home />} />
                          </Route>
                        )
                      })
                    }
                  </React.Fragment>
                )
              })
            }
            <Route path="/" element={< PrivateRoute />} >
              <Route path="/" element={< Home />} />
            </Route>
            <Route path="/" element={< PrivateRoute />} >
              <Route path="/sendLead" exact element={< SendFromEmailTable />} />
            </Route>
            
            <Route path="/lead-delivery/:id" element={< PrivateRoute />} >
              <Route path="/lead-delivery/:id" exact element={< LeadDelivery />} />
            </Route>
            <Route path="/geographic-region/:id" element={< PrivateRoute />} >
              <Route path="/geographic-region/:id" exact element={< GeographicRegionDetail />} />
            </Route>

            {/* <Route path="/sendLead" element={< PrivateRoute />} >
              <Route path="/sendLead" exact element={< SendFromEmailTable />} />
            </Route> */}
            <Route path="/login" exact element={< Login />} />
            {/* <Route path="/disputes" exact element={< DisputesTable />} /> */}
            {/* <Route path="/regions" exact element={< Regions />} /> */}
            <Route path="/process-dispute" exact element={< ProcessDispute />} />
            {/* <Route path="/reformat/:leadId" exact element={< ManuallyFormatLead />} /> */}
            <Route path="/loading" exact element={< LoadingWheel />} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
