import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.img`
height: ${({ height = '15px' }) => height};
width: ${({ width = '15px' }) => width};
z-index: ${({ zIndex }) => zIndex};
position: relative;
`;

const Icon = ({ src, ...props }) => {
    return (
        <IconWrapper src={src} {...props}></IconWrapper>
    )
}

export default Icon